import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import Images from "components/Avatar/Images";
import { NavLink } from "react-router-dom";

const FriendsGameId = (props) => {
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [Message, setMessage] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState({
    name: "",
    phoneNumber: "",
  });
  const SearchText = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };
  const pageSize = 10;

  const fetchData = async () => {
    setProduct(props.countrys);
    setPageCount(Math.ceil(props.countrys.length / pageSize));
    setPagination(_(props.countrys).slice(0).take(pageSize).value());
  };
  useEffect(() => {
    if (props.countrys.length > 0) {
      fetchData();
    }
  }, [props]);
  useEffect(() => {
    const products = product;
    if (search.name !== "") {
      const products = product.filter((item) => {
        const nameRegex = new RegExp(`.*${search.name}.*`, "i");
        return nameRegex.test(item.country);
      });
      setPageCount(products ? Math.ceil(products.length / pageSize) : 0);

      setPagination(_(products).slice(0).take(pageSize).value());
    } else {
      setPageCount(products ? Math.ceil(products.length / pageSize) : 0);
      setPagination(_(products).slice(0).take(pageSize).value());
    }
    setCurrentPage(1);
  }, [search, product]);
  setTimeout(function () {
    setMessage(false);
  }, 200);

  const Pagechane = (event, value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(product).slice(startIndex).take(pageSize).value();
    setPagination(pagination);
    setCurrentPage(value);
  };
  return (
    <div>
      <NavLink
        to="/app/friends-game-id/add"
        style={{
          marginBottom: "20px",
          color: "inherit",
          textDecoration: "none",
          float: "right",
          "&.active > div": {
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        <Button variant="contained" color="primary">
          ADD Friends Game id
        </Button>
      </NavLink>
      <Grid
        container
        style={{ width: "100%", height: "auto", margin: "40px auto" }}
      >
        <Paper
          variant="outlined"
          style={{
            flexGrow: 1,
            padding: "20px",
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutlinedInput
                fullWidth
                onChange={SearchText}
                placeholder="Search by name"
                name="name"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {Message && (
        <div>
          <CircularProgress />
        </div>
      )}

      {pagination.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{ width: { md: "100%", xs: "88vw" } }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Game</TableCell>
                <TableCell>Created date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagination.map((row) => {
                const nullCreate = row.createdAt.split("T");
                const dateCreate = nullCreate[0].split("-");
                const dateCreateAt =
                  dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
                return (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: `${row.status === "full" ? "red" : ""}` }}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell>
                      <Images nameImage={row?.gameFind?.image || ""} />
                    </TableCell>
                    <TableCell>{dateCreateAt}</TableCell>
                    <TableCell size="small">
                      <NavLink to={`/app/friends-game-id/edit/${row.id}`}>
                        <IconButton color="primary">
                          <EditIcon />
                        </IconButton>
                      </NavLink>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {product.length > 10 && (
            <Grid
              container
              spacing={0}
              style={{
                justifyContent: "center",
                color: "#000",
                display: "flex",
              }}
            >
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={Pagechane}
                size="small"
                color="primary"
              />
            </Grid>
          )}
        </TableContainer>
      )}
    </div>
  );
};

export default FriendsGameId;
