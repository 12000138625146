import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import axiosusers from "utils/api/axiosusers";
import { useNavigate, useParams } from "react-router-dom";
import { Tabtitle } from "components/title/Title";
const EditApiTelegrams = () => {
  const { id } = useParams();

  Tabtitle("Update api telegrams");
  const navigate = useNavigate();
  const [input, setInput] = useState({
    fullName: " ",
    token: " ",
    notes: " ",
    idToken: " ",
  });

  useEffect(() => {
    Promise.all([axiosusers.get(`/agencys/apitelegrams/${id}`)]).then(
      async (results) => {
        const [clienId] = results.map((res) => res.data);
        setInput(clienId);
      }
    );
  }, [id]);
  const [state, setState] = React.useState({
    allowToken: false,
  });
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setState({ ...state, [e.target.name]: e.target.checked });
  };

  const onSubmit = async () => {
    const dataBudgets = {
      id,
      fullName: input.fullName,
      token: input.token,
      allowToken: state.allowToken ? 1 : 0,
      idToken: input.idToken,
      notes: input.notes,
    };

    try {
      const resbudgets = await axiosusers.patch(
        `/agencys/update-apitelegrams`,
        dataBudgets
      );
      console.log(resbudgets);
      navigate("/app/order-agencys");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Update api telegrams
        </Typography>

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.fullName}
          name="fullName"
          error={Boolean(input.fullName === "")}
          helperText={input.fullName === "" ? "Name Group is required" : ""}
          label="Name Group"
          size="small"
          variant="outlined"
          fullWidth
        />

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.token}
          name="token"
          label="Token"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.idToken}
          name="idToken"
          label="id telegram"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          multiline
          minRows="4"
          type="text"
          value={input.notes}
          name="notes"
          label="Notes"
          size="small"
          variant="outlined"
          fullWidth
        />
        <Grid container spacing={0}>
          <Grid item xs={6} sx={{ marginTop: `0.5rem` }}>
            <label>Alow Api:</label>
            <Switch
              checked={
                input.allowToken == 1
                  ? (state.allowToken = true)
                  : state.allowToken
              }
              name="allowToken"
              color="primary"
              onChange={handleinput}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default EditApiTelegrams;
