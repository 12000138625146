import React from "react";

import Profiles from "layouts/Profiles";
import MenuItems from "layouts/MenuItems";
import { Box, Drawer, Toolbar } from "@mui/material";
export default function Sidebar() {
  const drawerWidth = 256;
  return (
    <div>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          display: { md: "block", sx: "none", xs: "none" },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            overflow: "auto",
          }}
        >
          <Profiles />
          <MenuItems />
        </Box>
      </Drawer>
    </div>
  );
}
