import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import useUserId from "hooks/useUserId";
import { useNavigate } from "react-router-dom";

const AddUsers = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);

  const [input, setInput] = React.useState({
    role: "1",
  });
  const { userId } = useUserId();

  const [age, setAge] = React.useState("");

  const [state, setState] = React.useState({
    allowZalo: "",
    allowSms: "",
  });
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setAge(e.target.value);
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  const handleCheckBox = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  const initialValues = {
    name: "",
    email: "",
    password: "",
    salary: "0",
    bonus: "0",
    bankName: "",
    bankNumber: "",
    bankAccount: "",
    zaloId: "",
    phoneNumber: "",
    maxAbsent: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(0, "It's too short").required("Name is required"),
    email: Yup.string().required("Email is required"),
    // email: Yup.string().email( "Must be a valid email").required("Email is required"),
    password: Yup.string()
      .min(1, "password must be at least 6 characters")
      .required("Password is required"),
  });

  const onSubmit = async (values) => {
    setDung(true);
    if (userId.role === "Admin") {
      if (input.role === "1") {
        input.role = "";
      }
      if (input.role === "") {
        setDung(false);
        return seterrorMessage("The role not found.");
      }
      const data = {
        name: values.name,
        email: values.email,
        code: age,
        password: values.password,
        salary: values.salary,
        bonus: values.bonus,
        bankName: values.bankName,
        bankAccount: values.bankAccount,
        zaloId: values.zaloId,
        phoneNumber: values.phoneNumber,
        maxAbsent: values.maxAbsent,
        allowSms: state.allowSms,
        allowZalo: state.allowZalo,
        telegramId: values.telegramId,
      };
      try {
        await axiosusers.post(`/usersLamlainao/register-users`, data);
        navigate("/app/users/");
      } catch (error) {
        setDung(false);
        console.log(error?.response);
        seterrorMessage(error.response.data.message);
      }
    }
  };

  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new users
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="name"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.name && props.touched.name}
                helperText={<ErrorMessage name="name" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="email"
                label="Email Address"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.email && props.touched.email}
                helperText={<ErrorMessage name="email" />}
              />
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                sx={{ marginTop: `0.5rem` }}
                error={Boolean(input.role == "")}
              >
                <InputLabel>Select Role</InputLabel>
                <Select
                  value={age}
                  onChange={handleChange}
                  label="Select Role"
                  name="role"
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Staff">Staff</MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                </Select>
                {errorMessage.length > 0 && input.role == "" && (
                  <FormHelperText>Role is required</FormHelperText>
                )}
              </FormControl>
              <Field
                sx={{ marginTop: `0.5rem` }}
                type="password"
                as={TextField}
                name="password"
                label="Password"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.password && props.touched.password}
                helperText={<ErrorMessage name="password" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="salary"
                label="Salary"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="bonus"
                label="Bonus"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="bankAccount"
                label="Bank Account Number"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="bankName"
                label="Bank Name"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="zaloId"
                label="Zalo Id"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="telegramId"
                label="Telegram Id"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="phoneNumber"
                label="Phone Number"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                type="number"
                as={TextField}
                name="maxAbsent"
                label="Max Absent"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Grid container spacing={0} sx={{ marginTop: `0.5rem` }}>
                <Grid item xs={6}>
                  <label>Alow Zalo:</label>
                  <Switch
                    name="allowZalo"
                    color="primary"
                    onChange={handleCheckBox}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <label>Alow SMS:</label>
                  <Switch
                    name="allowSms"
                    color="primary"
                    onChange={handleCheckBox}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Grid>
              </Grid>
              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddUsers;
