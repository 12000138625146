import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import useDecodedToken from "utils/token/useDecodedToken";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Agencys from "./Agencys";
import OrderWarhouses from "./Warhouses";
import ApiTelegrams from "./ApiTelegrams";
export default function BottomNavigations() {
  const [value, setValue] = React.useState(0);
  const { decodedToken } = useDecodedToken();
  return (
    <Box>
      <Box sx={{ maxWidth: 500, margin: "auto" }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Agencys" icon={<AddHomeWorkIcon />} />
          {decodedToken?.role !== "Staff" && (
            <BottomNavigationAction
              label="Order Warhouses"
              icon={<LocalGroceryStoreIcon />}
            />
          )}
          {decodedToken?.role !== "Staff" && (
            <BottomNavigationAction
              label="Api Telegrams"
              icon={<VpnKeyIcon />}
            />
          )}
        </BottomNavigation>
      </Box>
      {value == 0 && <Agencys />}
      {value == 1 && decodedToken?.role !== "Staff" && <OrderWarhouses />}
      {value == 2 && decodedToken?.role !== "Staff" && <ApiTelegrams />}
    </Box>
  );
}
