import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";

const EditClients = (props) => {
  const { id } = props;

  const navigate = useNavigate();
  const [input, setInput] = useState({
    email: " ",
    fullName: " ",
    faceBookName: " ",
    phoneNumber: " ",
    address: " ",
    instagramUser: " ",
    telegramUser: " ",
    lineUser: " ",
  });
  const [jsonResuls, setJsonResuls] = useState([]);
  const [games, setGames] = useState([]);

  const [fieldValueWallet, setFieldValueWallet] = useState([]);
  const [selectedGame, setSelectedGame] = useState({
    channelName: "",
    id: "",
  });
  useEffect(() => {
    Promise.all([
      axiosusers.get(`/clients/clients/${id}`),
      axiosusers.get(`/primarychannel/primarychannel`),
      axiosusers.get(`/games/games`),
      axiosusers.get(`/clients/clientgame/${id}`),
    ]).then(async (results) => {
      const [clienId, primarychannel, games, clientgame] = results.map(
        (res) => res.data
      );

      setInput(clienId);
      setJsonResuls(primarychannel);
      setGames(games);

      const initialSelectedOptions = clientgame.filter((item) =>
        games.some((selectedItem) => selectedItem.id === item.gameId)
      );

      const extractedIds = initialSelectedOptions.map((item) => ({
        name: item.name,
        id: item.gameId,
      }));

      setFieldValueWallet(extractedIds);
      const gameId = await axiosusers.get(
        `/primarychannel/primarychannel/${clienId.primarychannelId}`
      );

      setSelectedGame({
        channelName: gameId.data.channelName,
        id: gameId.data.id,
      });
    });
  }, [id]);

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    const data = {
      id,
      primarychannelId: selectedGame.id,
      email: input.email,
      fullName: input.fullName,
      faceBookName: input.faceBookName,
      instagramUser: input.instagramUser,
      telegramUser: input.telegramUser,
      lineUser: input.lineUser,
      phoneNumber: input.phoneNumber,
      address: input.address,
      dataClientGame: fieldValueWallet,
    };

    try {
      const resbudgets = await axiosusers.patch(
        `/clients/update-clients`,
        data
      );

      if (resbudgets.status === 200) {
        console.log(resbudgets);
        navigate("/app/clients");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setChangeWallet = async () => {};
  const filteredGame = games.filter(
    (item) =>
      !fieldValueWallet.some((selectedItem) => selectedItem.id === item.id)
  );
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
          }}
        >
          Update Clients
        </Typography>
        <Autocomplete
          sx={{ marginTop: `1.5rem` }}
          options={jsonResuls}
          fullWidth
          value={selectedGame}
          onChange={(event, value) => setSelectedGame(value)}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(jsonResuls) => jsonResuls.channelName}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(selectedGame === null)}
              helperText={selectedGame === null ? "Game is required" : ""}
              label="Primary Channel"
              variant="outlined"
            />
          )}
        />
        <Autocomplete
          sx={{ marginTop: `0.5rem` }}
          size="small"
          multiple
          limitTags={3}
          id="multiple-limit-tags"
          options={filteredGame}
          value={fieldValueWallet}
          filterSelectedOptions
          onChange={(event, values) => {
            setFieldValueWallet(values);
            setChangeWallet(values);
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(games) => games.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Games"
              placeholder="Favorites"
            />
          )}
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.fullName}
          name="fullName"
          error={Boolean(input.fullName === "")}
          helperText={input.fullName === "" ? "Name is required" : ""}
          label="Name"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.faceBookName}
          name="faceBookName"
          label="FaceBook User"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.instagramUser}
          name="instagramUser"
          label="Instagram User"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.telegramUser}
          name="telegramUser"
          label="Telegram User"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.lineUser}
          name="lineUser"
          label="Line User"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.phoneNumber}
          name="phoneNumber"
          label="Whatsapp Number"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.email}
          name="email"
          label="Email"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.address}
          name="address"
          label="Address"
          size="small"
          variant="outlined"
          fullWidth
        />
        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default EditClients;
