import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useDecodedToken from "utils/token/useDecodedToken";
import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axiosusers from "utils/api/axiosusers";
import _ from "lodash";
import LinkIcon from "@mui/icons-material/Link";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { NavLink } from "react-router-dom";

const Groups = () => {
  const { decodedToken } = useDecodedToken();
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const idProductRef = useRef();
  const idProductRefpage = useRef();
  const idProductRef_name = useRef();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [Message, setMessage] = useState(false);
  const [DonePage, setDonePage] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchCode, setSearchCode] = useState("");
  const [clients, setClients] = useState([]);
  const [facebooks, setFacebooks] = useState([]);

  const pageSize = 10;
  const [pageCount, setPageCount] = useState(0);
  const fetchData = async () => {
    Promise.all([
      axiosusers.get(`/facebook-manage/managefacebook`),
      axiosusers.get(`/facebook-manage/administrators`),
      axiosusers.get(`/facebook-manage/administratorfacebook`),
    ])
      .then((results) => {
        const [transactions, clients, facebooks] = results.map(
          (res) => res.data
        );

        setPageCount(Math.ceil(transactions.length / pageSize));
        setDonePage(transactions);
        setProduct(transactions);
        setPagination(_(transactions).slice(0).take(pageSize).value());
        setClients(clients);

        setFacebooks(facebooks);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    setCurrentPage(1);
    fetchData();
  }, [pageSize]);
  useEffect(() => {
    let results = product;
    setCurrentPage(1);
    if (searchCode !== "") {
      const numberRegex = new RegExp(`.*${searchCode}.*`, "i");
      results = results.filter((item) => numberRegex.test(item.name));
    }
    setPageCount(Math.ceil(results.length / pageSize));
    setDonePage(results);
    setPagination(_(results).slice(0).take(pageSize).value());
  }, [searchCode, product]);
  setTimeout(function () {
    setMessage(false);
  }, 200);
  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes-item1/delete-managefacebook`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        setProduct(
          product.filter((item) => {
            return item.id !== idProductRef.current;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }

    setMessage(true);
    setOpen(false);
  };
  const handleClickOpen = (id, page, name) => {
    setOpen(true);
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Pagechane = (event, value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(DonePage).slice(startIndex).take(pageSize).value();
    setPagination(pagination);
    setCurrentPage(value);
    setMessage(true);
  };
  const chanShow = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  return (
    <div>
      {decodedToken?.role == "Admin" && (
        <NavLink to="/app/facebook-manage/groups/add" className={styles.link}>
          <Button variant="contained" color="primary">
            ADD groups
          </Button>
        </NavLink>
      )}
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <OutlinedInput
                fullWidth
                onChange={(e) => setSearchCode(e.target.value)}
                placeholder="Search by groups name"
                name="code"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {Message && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {loading && (
        <div className={styles.center}>
          <CircularProgress />
        </div>
      )}

      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>.No</TableCell>
              {show && <TableCell>Administrators</TableCell>}
              <TableCell>Name</TableCell>
              <TableCell>Number of memes</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Created date</TableCell>
              <TableCell align="center">Template</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {pagination.map((row) => {
              var nullCreate = row.createdAt.split("T");
              var dateCreate = nullCreate[0].split("-");
              var dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              return (
                <TableRow key={row.id}>
                  <TableCell>{row.no}</TableCell>
                  {show && (
                    <TableCell>
                      {facebooks.map((item) => {
                        if (
                          row.id == item.facebookId &&
                          item.status == "Groups"
                        ) {
                          const clientNames = clients
                            .filter((itemC) => itemC.id == item.administratorId)
                            .map((itemC) => itemC.name + ", ");

                          return <>{clientNames}</>;
                        }
                      })}
                    </TableCell>
                  )}
                  <TableCell>
                    <a
                      target="_blank"
                      href={row.link}
                      style={{ textDecoration: "none", color: "inherit" }}
                      rel="noreferrer"
                    >
                      {row.name}
                    </a>
                  </TableCell>
                  <TableCell>{row.numberLike}</TableCell>
                  <TableCell>{row.groups}</TableCell>
                  <TableCell>{row.notes}</TableCell>
                  <TableCell>{dateCreateAt}</TableCell>
                  <TableCell align="center">
                    {decodedToken?.role == "Admin" && (
                      <IconButton color="primary" onClick={chanShow}>
                        <LinkIcon />
                      </IconButton>
                    )}

                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigator.clipboard.writeText(`${row.link}`)
                      }
                    >
                      <CopyrightIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <NavLink to={`/app/facebook-manage/groups/edit/${row.id}`}>
                      <IconButton color="primary">
                        <EditIcon />
                      </IconButton>
                    </NavLink>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        handleClickOpen(row.id, DonePage, "Delete")
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {product.length > 10 && (
          <Grid
            container
            spacing={0}
            sx={{
              justifyContent: "center",
              color: "#000",
              display: "flex",
              padding: "10px",
            }}
          >
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={Pagechane}
              size="small"
              color="primary"
            />
          </Grid>
        )}
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to Delete this Groups?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Groups;
