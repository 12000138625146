import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import useUserId from "hooks/useUserId";
import axiosusers from "utils/api/axiosusers";
import useLinks from "hooks/useLinks";
import FilledAlerts from "components/Alert/FilledAlerts";
import { useNavigate } from "react-router-dom";

const EditSalesArticleNotes = (props) => {
  const { id } = props;
  const linkImage = useLinks();
  const [image, setImage] = useState();
  const [avatar, setAvatar] = useState();
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = useState(false);
  const [jsonResuls, setJsonResuls] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [input, setInput] = useState({
    notes: " ",
  });
  useEffect(() => {
    Promise.all([
      axiosusers.get(`/facebook-manage/sales-article-notes/${id}`),
      axiosusers.get(`/facebook-manage/managefacebook`),
    ]).then(async (results) => {
      const [clienId, primarychannel] = results.map((res) => res.data);
      setInput(clienId);
      setJsonResuls(primarychannel);

      const gameId = await axiosusers.get(
        `/facebook-manage/managefacebook/${clienId.managefacebookId}`
      );
      console.log(gameId);
      setSelectedGame({ name: gameId.data.name, id: gameId.data.id });
    });
  }, [id]);

  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    setImage(file);

    file.preview = URL.createObjectURL(file);
    setAvatar(file);
  };

  const onSubmit = async () => {
    if (image?.size > 2 * 1024 * 1024) {
      return seterrorMessage("size must be less than 2MB");
    }
    const formData = new FormData();

    formData.append("id", id);
    formData.append("notes", input.notes);
    formData.append("managefacebookId", selectedGame.id);
    formData.append("file", image);

    try {
      const response = await axiosusers.post(
        `/uploads/update-sales-article-notes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        navigate("/app/facebook-manage/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Update sales article notes
        </Typography>
        <Autocomplete
          sx={{ marginTop: `0.5rem` }}
          options={jsonResuls}
          fullWidth
          value={selectedGame}
          onChange={(event, value) => setSelectedGame(value)}
          getOptionLabel={(jsonResuls) => jsonResuls.name}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(selectedGame === null)}
              helperText={selectedGame === null ? "Game is required" : ""}
              label="Primary Channel"
              variant="outlined"
            />
          )}
        />
        <TextField
          onChange={handleinput}
          multiline
          minRows={4}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.notes}
          name="notes"
          label="notes"
          size="small"
          variant="outlined"
          fullWidth
        />

        {avatar && (
          <Avatar
            src={avatar.preview}
            variant="square"
            sx={{
              width: (theme) => theme.spacing(25),
              height: (theme) => theme.spacing(25),
              margin: (theme) => theme.spacing(2, 0),
            }}
          />
        )}
        {!avatar && input.image !== "" && (
          <img
            src={`${linkImage}${input.image}`}
            crossOrigin="anonymous"
            style={{
              width: "200px",
              height: "200px",
              margin: "1rem 0 0 0",
            }}
          />
        )}
        <br />
        <input
          accept="image/*"
          style={{
            display: "none",
            color: "#3f50b5",
            margin: (theme) => theme.spacing(2, 0),
          }}
          onChange={handleFile}
          id="file"
          name="avatar"
          multiple
          type="file"
        />
        <label htmlFor="file">
          <Button component="span" color="primary" sx={{ marginTop: `1rem` }}>
            UPLOAD PHOTOS
          </Button>
        </label>

        {/* {errorMessage.length > 0 && <Alert severity="error" className={classes.errortext}>{errorMessage}</Alert>	}           */}
        {errorMessage && (
          <FilledAlerts message={errorMessage} success="error" />
        )}
        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default EditSalesArticleNotes;
