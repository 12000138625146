import React from "react";
import Fanpage from "./Fanpage";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Fanpages");
  return (
    <div>
      <Fanpage />
    </div>
  );
};

export default index;
