import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import { CircularProgress, Container } from "@mui/material";
import axiosusers from "utils/api/axiosusers";
import { Tabtitle } from "components/title/Title";
import useSWR from "swr";
import SanPham from "./SanPham";

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

export default function index(props) {
  Tabtitle("SanPham");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data, error, isLoading } = useSWR(`/sanpham/sanpham`, fetchDataSwr);
  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar></Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <Container maxWidth="xl">
        {error && <div>failed to load</div>}
        {isLoading && (
          <div>
            <CircularProgress />
          </div>
        )}
        {!isLoading && <SanPham data={data} />}
      </Container>
    </React.Fragment>
  );
}
