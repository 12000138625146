import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import useUserId from "hooks/useUserId";
import React, { useEffect, useRef, useState } from "react";

import axiosusers from "utils/api/axiosusers";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";

import _ from "lodash";

import Images from "components/Avatar/Images";
import { NavLink } from "react-router-dom";

const CountryGiftCards = (props) => {
  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const [product, setProduct] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const idProductRef = useRef();
  const idProductRefpage = useRef();
  const idProductRef_name = useRef();

  const [Message, setMessage] = useState(false);
  const [DonePage, setDonePage] = useState("0");
  const [open, setOpen] = useState(false);
  const [games, setGames] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState({
    name: "",
    phoneNumber: "",
  });
  const SearchText = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };
  const pageSize = 10;

  const fetchData = async () => {
    setGames(props.games);
    setProduct(props.countrys);
    setPageCount(Math.ceil(props.countrys.length / pageSize));
    setPagination(_(props.countrys).slice(0).take(pageSize).value());
  };
  useEffect(() => {
    if (props.countrys.length > 0) {
      fetchData();
    }
  }, [props]);
  useEffect(() => {
    const products = product;
    if (search.name !== "") {
      const products = product.filter((item) => {
        const nameRegex = new RegExp(`.*${search.name}.*`, "i");
        return nameRegex.test(item.country);
      });
      setPageCount(products ? Math.ceil(products.length / pageSize) : 0);

      setPagination(_(products).slice(0).take(pageSize).value());
    } else {
      setPageCount(products ? Math.ceil(products.length / pageSize) : 0);
      setPagination(_(products).slice(0).take(pageSize).value());
    }
    setCurrentPage(1);
  }, [search, product]);
  setTimeout(function () {
    setMessage(false);
  }, 200);
  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
    };
    setMessage(true);
    setOpen(false);
    try {
      const response = await axiosusers.patch(
        `/deletes/delete-countrygiftcard`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
    fetchData();
  };
  const handleClickOpen = (id, page, name) => {
    setOpen(true);
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Pagechane = (event, value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(product).slice(startIndex).take(pageSize).value();
    setPagination(pagination);
    setCurrentPage(value);
    setDonePage(startIndex);
  };
  return (
    <div>
      <NavLink
        to="/app/countrygiftcard/add"
        style={{
          marginBottom: "20px",
          color: "inherit",
          textDecoration: "none",
          float: "right",
          "&.active > div": {
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        <Button variant="contained" color="primary">
          ADD country giftcard
        </Button>
      </NavLink>
      <Grid
        container
        style={{ width: "100%", height: "auto", margin: "40px auto" }}
      >
        <Paper
          variant="outlined"
          style={{
            flexGrow: 1,
            padding: "20px",
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutlinedInput
                fullWidth
                onChange={SearchText}
                placeholder="Search by name"
                name="name"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {Message && (
        <div>
          <CircularProgress />
        </div>
      )}

      {pagination.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{ width: { md: "100%", xs: "88vw" } }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Country</TableCell>
                <TableCell>Game</TableCell>
                <TableCell>Created date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagination.map((row) => {
                const nullCreate = row.createdAt.split("T");
                const dateCreate = nullCreate[0].split("-");
                const dateCreateAt =
                  dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
                return (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.country}
                    </TableCell>
                    <TableCell>
                      <Images
                        nameImage={
                          games.find((itemG) => itemG.id === row.gameId)
                            ?.image || ""
                        }
                      />
                    </TableCell>
                    <TableCell>{dateCreateAt}</TableCell>
                    <TableCell size="small">
                      <NavLink to={`/app/countrygiftcard/edit/${row.id}`}>
                        <IconButton color="primary">
                          <EditIcon />
                        </IconButton>
                      </NavLink>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleClickOpen(row.id, DonePage, "Delete")
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {product.length > 10 && (
            <Grid
              container
              spacing={0}
              style={{
                justifyContent: "center",
                color: "#000",
                display: "flex",
              }}
            >
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={Pagechane}
                size="small"
                color="primary"
              />
            </Grid>
          )}
        </TableContainer>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to delete this blacklist?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CountryGiftCards;
