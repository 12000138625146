import React from "react";
import Profiles from "./Profiles";

const index = () => {
  const data = JSON.parse(localStorage.getItem("auth"));
  React.useEffect(() => {
    if (!localStorage.getItem("auth")) {
      window.location.href = "/login";
    }
  });
  return (
    <div>
      <Profiles data={data} />
    </div>
  );
};

export default index;
