import React from "react";
import AddOrders from "./AddOrders";
import { Tabtitle } from "components/title/Title";
import fetchData from "utils/data";
import useSWR from "swr";

const index = () => {
  Tabtitle("Create Orders");
  const { data: gamesApi } = useSWR("/games/games", (url) => fetchData(url));
  const { data: clientsTransactionsApi } = useSWR(
    "/clients/clients-transactions",
    (url) => fetchData(url)
  );
  const { data: blacklistsApi } = useSWR("/budgets/blacklists", (url) =>
    fetchData(url)
  );

  return (
    <div>
      <AddOrders
        clientsApi={clientsTransactionsApi}
        gamesApi={gamesApi}
        blacklistsApi={blacklistsApi}
      />
    </div>
  );
};

export default index;
