import React from "react";
import useSWR from "swr";
import axiosusers from "utils/api/axiosusers";
import Reports from "./Reports";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Reports");
  const fetchData = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const auth = JSON.parse(localStorage.getItem("auth"));
  const { data } = useSWR(`/usersLamlainao/profile/${auth?.id}`, fetchData);
  useSWR("/order/orders-new", (url) => fetchData(url));
  return <Reports data={data} />;
};

export default index;
