import GridsDetailOrders from "components/Grid/GridsDetailOrders";
import useUserId from "hooks/useUserId";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axiosusers from "utils/api/axiosusers";
import React, { useEffect, useRef } from "react";
import styles from "./style.module.css";
import GridsDetailOrdersBudgets from "components/Grid/GridsDetailOrdersBudgets";
import GridsDetailOrdersTransactions from "components/Grid/GridsDetailOrdersTransactions";
import TransactionDetailUndo from "./TransactionDetailUndo";
import DeleteIcon from "@mui/icons-material/Delete";

import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import Submitcompleteds from "./Submitcompleteds";
import FilledAlerts from "components/Alert/FilledAlerts";
import { useParams } from "react-router-dom";
import BudgetsOrders from "./BudgetsOrders";
const Details = () => {
  const { id } = useParams();
  const { userId } = useUserId();
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);
  const [budgetOrders, setBudgetOrders] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const [transactions, setTransactions] = React.useState([]);
  const [jsonTransactions, setJsonTransactions] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [rotation, setRotation] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [avatar, setAvatar] = React.useState([]);
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [budgets, setBudgets] = React.useState([]);
  const fetchData = async () => {
    Promise.all([
      axiosusers.get(`/order/orders-detail/${id}`),
      axiosusers.get(`/budgets/budgets-orders/${id}`),
      axiosusers.get(`/photos/photos-orderId/${id}`),
      axiosusers.get(`/transactions/transactions-orderId/${id}`),
      axiosusers.get(`/wallets/wallets-budgets-orders/${id}`),
    ])
      .then((results) => {
        const [orders, budgets, photos, transactions, walletsBudgetsOrders] =
          results.map((res) => res.data);
        setBudgets(walletsBudgetsOrders);
        setItems(orders.orders);
        setJsonTransactions(orders.transactionData);
        setTransactions(transactions);
        setImages(photos);
        setBudgetOrders(budgets);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    if (userId) {
      fetchData();
    }
  }, [id, userId]);

  const handleChange = (event) => {
    setItems({
      ...items,
      [event.target.name]: event.target.value,
    });
  };
  const canvasRef = useRef(null);
  useEffect(() => {
    const drawImageWithText = async () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();

      img.crossOrigin = "anonymous";
      // eslint-disable-next-line no-undef
      img.src = `${process.env.REACT_APP_NOT_SECRET_CODE}images/${items.imageGift}`;
      img.onload = () => {
        canvas.width = 300;
        canvas.height = 500;
        ctx.drawImage(img, 0, 0, 300, 500);

        ctx.font = "18px sans-serif";
        ctx.fillStyle = "#000";
        ctx.letterSpacing = "3px";
        ctx.fontWeight = 700;
        const divCode = {
          top: "60%",
          left: "50%",
          fontWeight: 700,
        };
        const text = items.codeGift;
        const textWidth = ctx.measureText(text).width;
        const textX = (canvas.width - textWidth) / 2;
        const textY = (canvas.height * parseFloat(divCode.top)) / 98.1;

        ctx.fillText(text, textX, textY);
      };
    };

    if (!loading && items.status !== "inProgress") {
      drawImageWithText();
    }
  }, [loading]);

  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  let dateCreateAt = "";
  let dateUpdateAt = "";
  let timeCreate = "";
  let timeUpdate = "";
  if (!loading) {
    const nullCreate = items.createdAt.split("T");
    const dateCreate = nullCreate[0].split("-");
    dateCreateAt = dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
    timeCreate = nullCreate[1].split(".");

    const nullUpdate = items.updatedAt.split("T");
    const dateUpdate = nullUpdate[0].split("-");
    dateUpdateAt = dateUpdate[2] + "/" + dateUpdate[1] + "/" + dateUpdate[0];
    timeUpdate = nullUpdate[1].split(".");
  }
  const DeleteImg = async (idPhotos) => {
    const dataSend = {
      id: idPhotos,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes-item1/delete-photos-id`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        fetchData();
        setMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpen = (index) => {
    console.log(index);
    setIsOpen(true);
    setCurrentIndex(index);
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
    }, 300);
  };
  const handleClose = () => {
    setIsOpen(false);
    setCurrentIndex(0);
  };
  function rotateImage() {
    setRotation(rotation + 90);
  }
  const handleNext = () => {
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
    }, 200);
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((currentIndex + images.length - 1) % images.length);
    setIsLoading(true);
    setTimeout(function () {
      setIsLoading(false);
    }, 200);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isOpen) {
        if (event.key === "ArrowLeft" && currentIndex > 0) {
          handlePrev();
        } else if (
          event.key === "ArrowRight" &&
          currentIndex < images.length - 1
        ) {
          handleNext();
        } else if (event.key === "Escape") {
          handleClose();
        } else if (event.key === "Enter") {
          const element = document.documentElement;
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.mozRequestFullScreen) {
            /* Firefox */
            element.mozRequestFullScreen();
          } else if (element.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            element.webkitRequestFullscreen();
          } else if (element.msRequestFullscreen) {
            /* IE/Edge */
            element.msRequestFullscreen();
          }
        } else if (event.ctrlKey && event.key === "q") {
          rotateImage();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    isOpen,
    currentIndex,
    handlePrev,
    handleNext,
    handleClose,
    images.length,
  ]);

  const viewAvatar = (e) => {
    const filesArray = Array.from(e.target.files).map((file) =>
      URL.createObjectURL(file)
    );
    setSelectedFiles((prevImages) => prevImages.concat(filesArray));
    Array.from(e.target.files).map(
      (file) => URL.revokeObjectURL(file) // avoid memory leak
    );

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      setAvatar((prevState) => [...prevState, file]);
    }
  };

  const renderPhotos = (source) => {
    return source.map((photo) => {
      return <img src={photo} alt="" className={styles.photoimg} key={photo} />;
    });
  };

  const handleSubmit = async (e, name) => {
    e.preventDefault();
    setMessage(true);
    let errorMessage = "";

    avatar.map((item) => {
      if (item.size > 2 * 1024 * 1024) {
        errorMessage = "size must be less than 2MB";
      }
    });
    if (errorMessage) {
      setMessage(false);
      seterrorMessage(errorMessage);
      return;
    }
    if (name == "photos") {
      avatar
        .sort((a, b) => a.size - b.size)
        .map(async (image) => {
          const formData = new FormData();
          formData.append("file", image);
          formData.append("orderId", Number(id));
          try {
            const response = await axiosusers.post(
              `/uploads/ordersphotos`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response.status === 200) {
              fetchData();
              console.log(response);

              setAvatar([]);
              setSelectedFiles([]);
              setMessage(false);
            }
          } catch (error) {
            console.log(error);
          }
        });
    }
    if (!message) {
      setTimeout(async function () {
        const data = await axiosusers.get(`/order/orders-detail/${id}`);
        setItems(data.data.orders);
      }, 200);
    }
  };

  return (
    <div>
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <>
          <Typography
            variant="h4"
            color="primary"
            sx={{
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            Detail Order
          </Typography>
          <GridsDetailOrders
            nameOne={`${items.statusGame
              .replace(/([A-Z])/g, " $1")
              .toLowerCase()
              .replace(/^./, function (str) {
                return str.toUpperCase();
              })}:`}
            nameTwo={items.nameGame}
            gridmain={false}
          />
          <GridsDetailOrders
            nameOne="Client:"
            nameTwo={items.fullName}
            gridmain={false}
          />
          <Grid container spacing={1} sx={{ margin: "4px 0 " }}>
            <Grid item lg={2} md={2} sm={2} xs={3}>
              <Typography
                variant="h5"
                color="primary"
                sx={{ color: "#3f51b5", fontWeight: 500, fontSize: "1rem" }}
              >
                Information:
              </Typography>
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={5} style={{ display: "flex" }}>
              <Typography
                variant="h6"
                sx={{ color: "#263238", fontWeight: 400, fontSize: "1rem" }}
              >
                <pre>{items.text}</pre>
              </Typography>
              {items.status !== "inProgress" && (
                <canvas ref={canvasRef} className={styles.displayNone}></canvas>
              )}
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <Button
                color="primary"
                onClick={() => navigator.clipboard.writeText(`${items.text}`)}
                style={{ width: "100px" }}
                size="small"
                variant="contained"
              >
                Copy
              </Button>
            </Grid>
            {items?.codeGift && items.status != "inProgress" && (
              <div
                style={{ position: "relative", textAlign: "center" }}
                className={styles.displayBlock}
              >
                <img
                  src={
                    // eslint-disable-next-line no-undef
                    `${process.env.REACT_APP_NOT_SECRET_CODE}images/` +
                    items?.imageGift
                  }
                  alt="Ảnh"
                  width={250}
                  height={400}
                  crossOrigin="anonymous"
                />
                <div
                  style={{
                    position: "absolute",
                    top: "59.5%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "16px",
                    color: "#000",
                    letterSpacing: "2px",
                    fontWeight: 500,
                  }}
                >
                  {items?.codeGift}
                </div>
              </div>
            )}
          </Grid>
          {budgetOrders.length === 1 && items.status === "inProgress" && (
            <BudgetsOrders
              budgets={budgets.filter(
                (item) => item.budgetId != budgetOrders[0].budgetId
              )}
              budgetOrders={budgetOrders}
              id={id}
              totalRecharge={items.totalRecharge}
            />
          )}
          <GridsDetailOrders
            nameOne="Status:"
            nameTwo={items.status
              .replace(/([A-Z])/g, " $1")
              .toLowerCase()
              .replace(/^\w/, (c) => c.toUpperCase())}
            gridmain={false}
          />
          <GridsDetailOrders
            nameOne="Payment Status:"
            nameTwo={items.paymentStatus
              .replace(/([A-Z])/g, " $1")
              .toLowerCase()
              .replace(/^\w/, (c) => c.toUpperCase())}
            gridmain={false}
          />
          {userId.role == "Admin" && (
            <GridsDetailOrders
              nameOne="Base price:"
              nameTwo={number.format(items.basePrice) + " ₫"}
              gridmain={false}
            />
          )}
          <GridsDetailOrders
            nameOne="Sale price:"
            nameTwo={number.format(items.salePrice) + " ₫"}
            gridmain={false}
          />
          <GridsDetailOrders nameOne="" nameTwo="" gridmain={false} />
          <GridsDetailOrdersBudgets
            budgetOrders={budgetOrders}
            userId={userId}
          />
          <Divider style={{ backgroundColor: "#000", margin: "5px 0 0 4px" }} />
          {transactions.length > 0 && (
            <GridsDetailOrdersTransactions
              transactions={transactions}
              userId={userId}
            />
          )}
          {items.paymentStatus === "unPaid" && (
            <TransactionDetailUndo
              jsonTransactions={jsonTransactions}
              id={id}
              items={items}
            />
          )}
          <Divider style={{ backgroundColor: "#000", margin: "5px 0 0 4px" }} />
          <GridsDetailOrders
            nameOne="Bonus Amount:"
            nameTwo={number.format(items.bonusAmount) + " ₫"}
            gridmain={false}
          />
          <GridsDetailOrders
            nameOne="Notes:"
            nameTwo={items.notes}
            gridmain={false}
          />
          <GridsDetailOrders
            nameOne="Create date:"
            nameTwo={timeCreate[0] + " " + dateCreateAt}
            gridmain={false}
          />
          <GridsDetailOrders
            nameOne="Update date:"
            nameTwo={timeUpdate[0] + " " + dateUpdateAt}
            gridmain={false}
          />
          <GridsDetailOrders
            nameOne="Created By:"
            nameTwo={items.createdBy}
            gridmain={false}
          />
          <div
            style={{ display: "flex", flexWrap: "wrap", margin: "5px auto" }}
          >
            {images.map((item, index) => {
              return (
                <div key={index} style={{ position: "relative" }}>
                  <div>
                    <img
                      crossOrigin="anonymous"
                      // eslint-disable-next-line no-undef
                      src={`${process.env.REACT_APP_NOT_SECRET_CODE}images/${item.image}`}
                      onClick={() => handleOpen(index)}
                      style={{
                        width: "150px",
                        height: "150px",
                        margin: "1px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <IconButton
                    color="primary"
                    sx={{ position: "absolute", top: 0, left: 0 }}
                    onClick={() => DeleteImg(item.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              );
            })}
          </div>
          <input
            style={{ marginTop: `0.5rem` }}
            accept="image/*"
            className={styles.input}
            onChange={viewAvatar}
            id="file"
            name="img"
            multiple
            type="file"
          />
          <label htmlFor="file" style={{ marginTop: `0.5rem` }}>
            <Button
              component="span"
              sx={{ marginTop: `0.5rem` }}
              color="primary"
            >
              ADD MORE PHOTOS
            </Button>
          </label>
          <div className="result">{renderPhotos(selectedFiles)}</div>
          {selectedFiles.length > 0 && (
            <div>
              <Button
                variant="contained"
                disabled={message}
                onClick={(e) => handleSubmit(e, "photos")}
                color="primary"
                size="small"
              >
                Upload photos
              </Button>
              <br />
              <br />
            </div>
          )}
          {errorMessage && (
            <FilledAlerts message={errorMessage} success="error" />
          )}

          {items.status === "inProgress" && (
            <TextField
              variant="outlined"
              fullWidth
              name="info"
              onChange={handleChange}
              value={items.info}
              style={{ maxWidth: "600px", marginTop: "20px" }}
              multiline
              label="Info"
            />
          )}
          <br />
          <br />
          <Submitcompleteds items={items} userId={userId} />

          <br />
          <br />
          <br />
          {isOpen && (
            <>
              <div className={styles.lightbox}>
                <div className={styles.lightboxOverlay} onClick={handleClose} />
                <div className={styles.headers}>
                  <Tooltip title="Close">
                    <div className={styles.closeButton} onClick={handleClose}>
                      <IconButton>
                        <CloseIcon className={styles.icon} />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Tooltip title="Rotabe (Ctrl+Q)">
                    <Box className={styles.refreshButton} onClick={rotateImage}>
                      <IconButton>
                        <RefreshIcon className={styles.icon} />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </div>

                {currentIndex > 0 && (
                  <Tooltip title="Prev image">
                    <Box className={styles.prevButton} onClick={handlePrev}>
                      <IconButton>
                        <KeyboardArrowLeftIcon className={styles.icon} />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
                {currentIndex < images.length - 1 && (
                  <Tooltip title="Next image">
                    <Box className={styles.nextButton} onClick={handleNext}>
                      <IconButton>
                        <KeyboardArrowRightIcon className={styles.icon} />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
                <div className={styles.lightboxContent}>
                  {!isLoading && (
                    <img
                      className={styles.lightboxImg}
                      style={{ transform: `rotate(${rotation}deg)` }}
                      crossOrigin="anonymous"
                      src={
                        // eslint-disable-next-line no-undef
                        `${process.env.REACT_APP_NOT_SECRET_CODE}images/` +
                        images[currentIndex].image
                      }
                    />
                  )}
                  {isLoading && (
                    <CircularProgress className={styles.lightboxImg} />
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Details;
