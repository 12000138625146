/* eslint-disable no-undef */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import axiosusers from "utils/api/axiosusers";
import { Tabtitle } from "components/title/Title";

const DetailGiftCode = () => {
  Tabtitle("Detail Gift Card");
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [giftcodes, setGiftcodes] = useState([]);
  const canvasRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    Promise.all([axiosusers.get(`/giftcard/giftcodes/${id}`)])
      .then((results) => {
        const [giftcodes] = results.map((res) => res.data);
        setGiftcodes(giftcodes);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [id]);
  useEffect(() => {
    const drawImageWithText = async () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = `${process.env.REACT_APP_NOT_SECRET_CODE}images/${giftcodes.image}`;

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        ctx.font = "80px Arial, sans-serif";
        ctx.crossOrigin = "Anonymous";
        if (window.innerWidth <= 600) {
          ctx.font = "40px Arial, sans-serif";
        }
        ctx.fillStyle = "#000";
        ctx.letterSpacing = "3px";
        ctx.fontWeight = 600;
        const divCode = {
          top: "59.8%",
          left: "50%",
          fontWeight: 600,
        };
        const text = giftcodes.code;
        const textWidth = ctx.measureText(text).width;
        const textX = (canvas.width - textWidth) / 2;
        const textY = (canvas.height * parseFloat(divCode.top)) / 98.1;

        ctx.fillText(text, textX, textY);
      };
    };

    if (!loading) {
      drawImageWithText();
    }
  }, [loading]);

  return (
    <div
      style={{
        maxWidth: 600,
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading && <CircularProgress />}
      <canvas
        className={classes.lightboxImg}
        crossOrigin="anonymous"
        ref={canvasRef}
      ></canvas>
    </div>
  );
};

export default DetailGiftCode;
const useStyles = makeStyles((theme) => ({
  lightboxImg: {
    color: "#fff",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      maxWidth: "90vw",
      height: 600,
      fontSize: 10,
    },
  },
}));
