import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const TableReport = (props) => {
  const { data, result } = props;
  const number = new Intl.NumberFormat("en-US");
  return (
    <TableContainer
      component={Paper}
      sx={{ width: { md: "100%", xs: "88vw" } }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Order Date</TableCell>
            <TableCell>Total Orders</TableCell>
            <TableCell>Sales</TableCell>
            {data?.role != "Staff" && <TableCell>Revenue</TableCell>}
            {data?.role != "Staff" && <TableCell>Advertise fee</TableCell>}
            <TableCell>Total Bonus</TableCell>
            {data?.role != "Staff" && <TableCell>Total Revenue</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {result.map((row, id) => {
            var dateCreate = row.date.split("-");
            var dateCreateAt =
              dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
            return (
              <TableRow key={id}>
                <TableCell>{dateCreateAt}</TableCell>
                <TableCell>{number.format(row.orderCount)} </TableCell>
                <TableCell>{number.format(row.salePrice)} ₫</TableCell>
                {data?.role != "Staff" && (
                  <TableCell>
                    {number.format(
                      Number(row.salePrice) - Number(row.basePrice)
                    )}{" "}
                    ₫
                  </TableCell>
                )}
                {data?.role != "Staff" && (
                  <TableCell>
                    {number.format(Number(row.adFreeAmount))} ₫
                  </TableCell>
                )}
                <TableCell>{number.format(row.bonusAmount)} ₫</TableCell>
                {data?.role != "Staff" && (
                  <TableCell>
                    {number.format(
                      Number(row.salePrice) -
                        Number(row.basePrice) -
                        Number(row.bonusAmount) -
                        Number(row.adFreeAmount)
                    )}{" "}
                    ₫
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableReport;
