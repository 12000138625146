import { Tabtitle } from "components/title/Title";
import React from "react";
import useSWR from "swr";
import axiosusers from "utils/api/axiosusers";
import AddTransactions from "./AddTransactions";
import { CircularProgress } from "@mui/material";

const index = () => {
  Tabtitle("Create Transactions");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const {
    data: clientsData,
    error: clientsError,
    isLoading: clientsisLoading,
  } = useSWR(`/clients/clients`, fetchDataSwr);
  const {
    data: paymentsData,
    error: paymentsError,
    isLoading: paymentsisLoading,
  } = useSWR(`/payments/payments`, fetchDataSwr);
  return (
    <div>
      {clientsError && paymentsError && <div>failed to load</div>}
      {paymentsisLoading && clientsisLoading && (
        <div>
          <CircularProgress />
        </div>
      )}
      {!paymentsisLoading && !clientsisLoading && (
        <AddTransactions clientsApi={clientsData} paymentsApi={paymentsData} />
      )}
    </div>
  );
};

export default index;
