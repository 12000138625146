import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import GifBoxIcon from "@mui/icons-material/GifBox";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import GiftHome from "./Gift";
import GiftCodes from "./GiftCode";
export default function BottomNavigations() {
  const [value, setValue] = React.useState(0);

  return (
    <Box>
      <Box sx={{ maxWidth: 500, margin: "auto" }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Gift Home" icon={<GifBoxIcon />} />

          <BottomNavigationAction
            label="Gift Code"
            icon={<DeveloperModeIcon />}
          />
        </BottomNavigation>
      </Box>
      {value == 0 && <GiftHome />}
      {value == 1 && <GiftCodes />}
    </Box>
  );
}
