import React from "react";
import Withdraws180days from "./Withdraws180days";
import { useParams } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Payments180Days Detail");
  const { id } = useParams();
  return <div>{id && <Withdraws180days id={id} />}</div>;
};

export default index;
