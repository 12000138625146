import useCountry from "hooks/useCountry";
import useUserId from "hooks/useUserId";
import axiosusers from "utils/api/axiosusers";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "./style.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Fab,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { FormAutocomplete } from "hooks/FormAutocomplete";
import useDecodedToken from "utils/token/useDecodedToken";
import { useNavigate } from "react-router-dom";

const AddWallets = () => {
  const navigate = useNavigate();
  const { countries } = useCountry();
  const [done, setDone] = React.useState(false);
  const [fieldValueGame, setFieldValueGame] = useState([]);
  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const [image, setImage] = React.useState(null);
  const [avatar, setAvatar] = React.useState(null);

  const handleFile = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setAvatar({ preview: URL.createObjectURL(file) });
  };
  const [country, setCountry] = React.useState("");
  const [input, setInput] = useState({
    type: "",
    currency: "",
    notes: "",
  });

  const [jsonResuls, setJsonResuls] = useState([]);
  const [countrygiftcard, setCountrygiftcard] = useState([]);
  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), name: "", quantity: "", price: "", orderNumber: "1" },
  ]);
  const [update, setUpdate] = useState({
    positive: false,
    notes: "",
  });
  const [errorMessage, seterrorMessage] = React.useState(false);
  const initialValues = {
    name: "",
    no: "",
    price: "",
    amount: "",
    Game: null,
    notes: "",
  };

  const validationSchema = Yup.object().shape({
    no: Yup.string().min(0, "It's too short").required("No is required"),
    name: Yup.string()
      .min(0, "It's too short")
      .required("Wallet name is required"),
    price: Yup.string().min(0, "It's too short").required("Price is required"),
    amount: Yup.string()
      .min(0, "It's too short")
      .required("Doller is required"),
    Game: Yup.object().required("Game is required"),
  });

  useEffect(() => {
    Promise.all([
      axiosusers.get(`/games/games`),
      axiosusers.get(`/countrys/country`),
    ])
      .then((results) => {
        const [transactionsResponse, countrygiftcard] = results;
        const transactions = transactionsResponse.data;
        setCountrygiftcard(countrygiftcard.data);
        let filteredData = transactions;

        setJsonResuls(filteredData); // Make sure setJsonResults is defined and updates the state correctly.
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleChange = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: {
        checked: e.target.checked,
        value: e.target.value,
      },
    });
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    inputFields.map((item, i) => {
      setInputFields([
        ...inputFields,
        { id: uuidv4(), name: "", quantity: "", price: "", orderNumber: i + 1 },
      ]);
    });
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };
  const handleChangeCountry = (event, value) => {
    setCountry(value.label);
  };
  const { decodedToken, isExpired } = useDecodedToken();

  React.useEffect(() => {
    if (isExpired) {
      if (decodedToken?.id === undefined) {
        window.location.href = "/login";
      }
    }
  }, [isExpired]);
  const onSubmit = async (values) => {
    setDone(true);
    if (userId.role === "Admin") {
      let packages = 0;
      inputFields.map((item) => {
        packages +=
          (Number(item.price) * Number(item.quantity)) / Number(values.price);
      });
      let errorMessage = "";
      inputFields.forEach((item) => {
        if (item.name === "" || item.price === "" || item.quantity === "") {
          errorMessage = "The data budgets invalid. Please check again";
        }
      });
      if (image?.size > 2 * 1024 * 1024) {
        setDone(false);
        return seterrorMessage("size must be less than 2MB");
      }
      if (errorMessage !== "") {
        setDone(false);
        return seterrorMessage(errorMessage);
      }
      const data = {
        name: values.name,
        gameId: fieldValueGame?.id,
        userId: userId?.id,
        country: country,
        packages,
        remainPackages: 0,
        price: values.price,
        notes: update.notes.value,
        orderNumber: values.no,
        positive: update.positive.checked,
        amount: values.amount,
      };
      try {
        const response = await axiosusers.post(`/wallets/create-wallets`, data);

        if (response.status === 200) {
          console.log(response);
          setTimeout(() => {
            setDone(false);
            navigate("/app/wallets");
          }, 1000);
          inputFields.map(async (item, i) => {
            const dataBudgets = {
              walletId: response.data.walletId,
              name: item.name,
              quantity: parseFloat(item.quantity),
              remainQuantity: 0,
              price: parseFloat(item.price),
              orderNumber: i,
              usedQuantity: "0",
            };

            try {
              const resbudgets = await axiosusers.post(
                `/budgets/create-budgets`,
                dataBudgets
              );

              if (resbudgets.status === 500) {
                console.log(resbudgets);
                setDone(false);
                navigate("/app/wallets");
              }
            } catch (error) {
              console.log(error);
              setDone(false);
            }
          });

          const formData = new FormData();
          formData.append("walletId", response.data.walletId);
          formData.append("file", image);
          try {
            await axiosusers.post(`/uploads/update-wallets`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
        seterrorMessage(error.response.data.message);
        setDone(false);
      }
    }
  };

  const countryData = countries.filter((item) => {
    return countrygiftcard.some((item2) => item2.country === item.label);
  });
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto`, backgroundColor: `#fafafa` }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new wallet
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={done ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                as={TextField}
                type="number"
                name="no"
                label="No"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.no && props.touched.no}
                helperText={<ErrorMessage name="no" />}
              />
              <div className={styles.fontText}></div>
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                className={styles.formControl}
                error={Boolean(errorMessage.length > 0 && input.status == "1")}
              >
                <InputLabel>Product</InputLabel>
                <Select
                  value={input.status}
                  onChange={handleChange}
                  label="Product"
                  name="status"
                >
                  <MenuItem value="game" className={styles.select}>
                    Games-Top up
                  </MenuItem>
                  <MenuItem value="giftCard" className={styles.select}>
                    Gift Card
                  </MenuItem>
                  <MenuItem value="softWare" className={styles.select}>
                    softWare
                  </MenuItem>
                  <MenuItem value="account" className={styles.select}>
                    Games Account
                  </MenuItem>
                  <MenuItem value="skinItemGeneral" className={styles.select}>
                    Shin Item General
                  </MenuItem>
                </Select>
                {errorMessage.length > 0 && input.status == "1" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>
              <FormAutocomplete
                onChangeSelect={(value) => setFieldValueGame(value)}
                className={styles.fontText}
                label={
                  input.status
                    ? input.status
                        .replace(/([A-Z])/g, " $1")
                        .toLowerCase()
                        .replace(/^./, function (str) {
                          return str.toUpperCase();
                        })
                    : "Games-Top up"
                }
                name="Game"
                options={
                  input.status
                    ? jsonResuls.filter((game) => game.status === input.status)
                    : jsonResuls
                }
              />
              {input.status == "giftCard" && (
                <Autocomplete
                  id="country-select-demo"
                  size="small"
                  className={styles.fontText}
                  options={countryData}
                  onChange={handleChangeCountry}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}

              <Field
                as={TextField}
                name="name"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.name && props.touched.name}
                helperText={<ErrorMessage name="name" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                type="number"
                name="price"
                label="Price"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.price && props.touched.price}
                helperText={<ErrorMessage name="price" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                type="number"
                name="amount"
                label="Price in Dollar"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.amount && props.touched.amount}
                helperText={<ErrorMessage name="amount" />}
              />
              <label>Positive Wallet:</label>
              <Switch
                name="positive"
                color="primary"
                onChange={handleChange}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <div className={styles.fontText}></div>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, fontSize: "14px" }}
              >
                Budgets:
              </Typography>
              {inputFields.map((inputField, id) => (
                <div
                  style={{ display: "flex", margin: "10px 0px" }}
                  key={inputField.id}
                >
                  <TextField
                    type="text"
                    name="name"
                    value={inputField.name}
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                    label="Name"
                    variant="outlined"
                    size="small"
                  />

                  <Box sx={{ margin: (theme) => theme.spacing(0, 1, 0, 1) }}>
                    <TextField
                      type="number"
                      name="quantity"
                      value={inputField.quantity}
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                      label="Quantity"
                      variant="outlined"
                      size="small"
                    />
                  </Box>
                  <TextField
                    sx={{ width: "30ch" }}
                    type="number"
                    name="price"
                    value={inputField.price}
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                    label="Price"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <div>
                          {inputFields.length === Number(id + 1) && (
                            <Fab
                              color="primary"
                              aria-label="add"
                              size="small"
                              onClick={handleAddFields}
                            >
                              <AddIcon />
                            </Fab>
                          )}
                        </div>
                      ),
                    }}
                  />
                  {inputFields.length !== 1 && (
                    <IconButton
                      color="primary"
                      onClick={() => handleRemoveFields(inputField.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              ))}
              <TextField
                label="Notes"
                multiline
                minRows="4"
                onChange={handleChange}
                name="notes"
                sx={{ margin: (theme) => theme.spacing(2, 0, 0, 0) }}
                variant="outlined"
                fullWidth
              />
              {avatar && (
                <Avatar
                  src={avatar.preview}
                  variant="square"
                  sx={{
                    width: (theme) => theme.spacing(25),
                    height: (theme) => theme.spacing(25),
                    margin: (theme) => theme.spacing(2, 0),
                  }}
                />
              )}
              <input
                accept="image/*"
                style={{
                  display: "none",
                  color: "#3f50b5",
                  margin: (theme) => theme.spacing(2, 0),
                }}
                onChange={handleFile}
                id="file"
                name="file"
                multiple
                type="file"
              />
              <label htmlFor="file">
                <Button
                  component="span"
                  sx={{ marginTop: `1rem` }}
                  color="primary"
                >
                  UPLOAD PHOTOS
                </Button>
              </label>
              {errorMessage.length > 0 && (
                <Alert severity="error" className={styles.errortext}>
                  {errorMessage}
                </Alert>
              )}

              <Button
                type="submit"
                sx={{ marginTop: `2rem` }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={done}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
      <div className={styles.goc}>
        {inputFields.reduce((acc, item) => acc + Number(item.price), 0)}
      </div>
    </div>
  );
};

export default AddWallets;
