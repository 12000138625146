import useUserId from "hooks/useUserId";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axiosusers from "utils/api/axiosusers";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import * as Yup from "yup";
import _ from "lodash";

const Withdraws = (props) => {
  const { id } = props;
  const [product, setProduct] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState([]);

  const [Message, setMessage] = useState(false);
  const [input, setInput] = useState({
    balance: 0,
    onHoldBalance: 0,
  });
  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const getProduct = async () => {
    const data = await axiosusers.get(`/payments/payments-180days/${id}`);
    setInput(data.data);
  };
  const pageSize = 5;
  const getWithdraw = async () => {
    const data = await axiosusers.get(`/payments/withdraws-180days/${id}`);
    setProduct(data.data);
    setPagination(_(data.data).slice(0).take(pageSize).value());
  };

  const pageCount = product ? Math.ceil(product.length / pageSize) : 0;

  useEffect(() => {
    getProduct();
    getWithdraw();
  }, [id]);

  const initialValues = {
    withdraw: "",
  };
  const validationSchema = Yup.object().shape({
    withdraw: Yup.number()
      .min(0.00000001, "Withdraw amount must be greater than zero")
      .max(input.balance, "Withdraw amount less than balance")
      .required("Withdraw amount is required"),
  });

  const onSubmit = async (values, props) => {
    setMessage(true);
    const data = {
      id,
      userId: userId?.id,
      amount:
        input.currency === "VND"
          ? parseInt(values.withdraw)
          : parseInt(Number(values.withdraw) * 22200),
      balance: parseInt(Number(input.balance) - Number(values.withdraw)),
    };
    try {
      const res = await axiosusers.post(
        `/payments/create-withdraws-180days`,
        data
      );
      props.resetForm();
      getProduct();
      console.log(res);
      setTimeout(function () {
        getWithdraw();
      }, 20);
      setMessage(true);
    } catch (error) {
      console.log(error);
      setMessage(false);
    }
  };
  const SubmitWindraw = async (idToDelete) => {
    const data = {
      id: idToDelete,
      status: "completed",
    };
    try {
      const response = await axiosusers.patch(
        `/payments/update-withdraws-status-180days`,
        data
      );
      if (response.status === 200) {
        console.log(response);
        getWithdraw();
        getProduct();
        setTimeout(function () {
          getWithdraw();
        }, 20);
      }
    } catch (error) {
      console.log(error);
    }
  };
  let number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const numberthem = new Intl.NumberFormat("en-NZ", {
    minimumFractionDigits: 2,
  });
  const Pagechane = (event, value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(product).slice(startIndex).take(pageSize).value();
    setPagination(pagination);
    setCurrentPage(value);
    setMessage(true);
    //  console.log(pagination)
    // getProduct(startIndex)
  };
  setTimeout(function () {
    setMessage(false);
  }, 250);
  return (
    <Box sx={{ minHeight: `80vh` }}>
      <Box
        sx={{
          maxWidth: 300,
          margin: `auto`,
          backgroundColor: `#fafafa`,
        }}
      >
        <Grid>
          <Typography variant="h5" sx={{ marginBottom: "20px" }}>
            Payment detail
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => (
              <Form autoComplete="off">
                <Grid container spacing={0}>
                  <Grid>
                    <Typography
                      color="primary"
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        margin: (theme) => theme.spacing(1, 0),
                        textAlign: "center",
                      }}
                    >
                      Balance:
                    </Typography>
                  </Grid>
                  <Grid>
                    {input.currency == "USD" && (
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          margin: (theme) => theme.spacing(1, 3),
                          textAlign: "center",
                        }}
                      >
                        {numberthem
                          .format(Math.round(input.balance * 100) / 100)
                          .replace(".", ",")}{" "}
                        $
                      </Typography>
                    )}
                    {input.currency == "VND" && (
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          margin: (theme) => theme.spacing(1, 3),
                          textAlign: "center",
                        }}
                      >
                        {number.format(input.balance)} ₫
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid>
                    <Typography
                      color="primary"
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        margin: (theme) => theme.spacing(1, 0),
                        textAlign: "center",
                      }}
                    >
                      On Hold Balance:
                    </Typography>
                  </Grid>
                  <Grid>
                    {input.currency == "USD" && (
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          margin: (theme) => theme.spacing(1, 3),
                          textAlign: "center",
                        }}
                      >
                        {numberthem
                          .format(Math.round(input.onHoldBalance * 100) / 100)
                          .replace(".", ",")}{" "}
                        $
                      </Typography>
                    )}
                    {input.currency == "VND" && (
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          margin: (theme) => theme.spacing(1, 3),
                          textAlign: "center",
                        }}
                      >
                        {number.format(input.onHoldBalance)} ₫
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid>
                    <Typography
                      color="primary"
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        margin: (theme) => theme.spacing(1, 0),
                        textAlign: "center",
                      }}
                    >
                      Total Balance:
                    </Typography>
                  </Grid>
                  <Grid>
                    {input.currency == "USD" && (
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          margin: (theme) => theme.spacing(1, 3),
                          textAlign: "center",
                        }}
                      >
                        {numberthem
                          .format(
                            Math.round(
                              (Number(input.balance) +
                                Number(input.onHoldBalance)) *
                                100
                            ) / 100
                          )
                          .replace(".", ",")}{" "}
                        $
                      </Typography>
                    )}
                    {input.currency == "VND" && (
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          margin: (theme) => theme.spacing(1, 3),
                          textAlign: "center",
                        }}
                      >
                        {number.format(
                          Number(input.balance) + Number(input.onHoldBalance)
                        )}{" "}
                        ₫
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <br />

                <Field
                  as={TextField}
                  disabled={Number(input.balance) === 0 ? true : ""}
                  name="withdraw"
                  type="number"
                  size="small"
                  label="Withdraw amount"
                  variant="outlined"
                  error={props.errors.withdraw && props.touched.withdraw}
                  helperText={<ErrorMessage name="withdraw" />}
                />
                <Button
                  sx={{ marginTop: "20px" }}
                  disabled={Number(input.balance) === 0 ? true : ""}
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Withdraw
                </Button>
              </Form>
            )}
          </Formik>
          {Message && <CircularProgress />}
        </Grid>
      </Box>
      <br />
      <br />
      {product.length > 0 && (
        <div>
          <Divider style={{ backgroundColor: "#000" }} />
          <TableContainer
            component={Paper}
            sx={{ width: { md: "100%", xs: "88vw" } }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Withdrawn By</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagination.map((item) => {
                  var nullCreate = item.createdAt.split(" ");
                  var dateCreate = nullCreate[0].split("-");
                  var dateCreateAt =
                    dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
                  return (
                    <TableRow hover key={item.id}>
                      <TableCell component="th" scope="row">
                        {dateCreateAt}
                      </TableCell>
                      <TableCell>{number.format(item.amount)} ₫</TableCell>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>In progress</TableCell>
                      <TableCell align="center" size="small">
                        <IconButton
                          color="primary"
                          onClick={() => SubmitWindraw(item.id, currentPage)}
                        >
                          <DoneIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {product.length > 5 && (
              <Grid
                container
                spacing={0}
                sx={{
                  justifyContent: "center",
                  color: "#000",
                  display: "flex",
                  padding: "10px",
                }}
              >
                <Pagination
                  count={pageCount}
                  page={currentPage}
                  onChange={Pagechane}
                  size="small"
                  color="primary"
                />
              </Grid>
            )}
          </TableContainer>
        </div>
      )}
    </Box>
  );
};

export default Withdraws;
