import Images from "components/Avatar/Images";
import {
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const GeneralReport = (props) => {
  const {
    data,
    selectUser,
    selectGame,
    fromDate,
    toDate,
    orderTotal,
    salePriceTotal,
    basePriceTotal,
    bonusAmountTotal,
    adfeeAmountTotal,
  } = props;
  const number = new Intl.NumberFormat("en-US");
  return (
    <div>
      <Paper variant="outlined">
        <Grid container spacing={0}>
          {data?.role === "Staff" && (
            <Grid item xs={3}>
              <ListItem>
                <ListItemAvatar>
                  <Images nameImage={data?.avatarUrl} />
                </ListItemAvatar>

                <ListItemText
                  primary={<Typography>{data.name}</Typography>}
                  secondary={data.role}
                />
              </ListItem>
            </Grid>
          )}
          {data?.role !== "Staff" && selectUser && (
            <Grid item xs={3}>
              <ListItem>
                <ListItemAvatar>
                  <Images nameImage={selectUser?.avatarUrl} />
                </ListItemAvatar>

                <ListItemText
                  primary={<Typography>{selectUser.name}</Typography>}
                  secondary={selectUser.role}
                />
              </ListItem>
            </Grid>
          )}
          {data?.role !== "Staff" && selectGame && (
            <Grid item xs={3}>
              <ListItem>
                <ListItemAvatar>
                  <Images nameImage={selectGame?.image} />
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography>{selectGame.name}</Typography>}
                  secondary={selectGame.description}
                />
              </ListItem>
            </Grid>
          )}
        </Grid>
        {!(selectUser || selectGame) && data?.role != "Staff" && (
          <Typography
            color="primary"
            variant="subtitle2"
            sx={{
              fontSize: "16px",
              margin: (theme) => theme.spacing(2, 0, 1, 2),
            }}
          >
            General Report for all users and all games
          </Typography>
        )}

        <Divider sx={{ margin: (theme) => theme.spacing(0, 2, 2, 2) }} />
        <TableContainer sx={{ width: { md: "100%", xs: "88vw" } }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Date Range
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Orders
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Sales
                  </Typography>
                </TableCell>
                {data?.role != "Staff" && (
                  <TableCell
                    sx={{
                      border: 0,
                      padding: (theme) => theme.spacing(1, 2),
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#546e7a",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      Revenue
                    </Typography>
                  </TableCell>
                )}
                {data?.role != "Staff" && (
                  <TableCell
                    sx={{
                      border: 0,
                      padding: (theme) => theme.spacing(1, 2),
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#546e7a",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      Advertise fee
                    </Typography>
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  <Typography
                    sx={{ color: "#546e7a", fontWeight: 500, fontSize: "16px" }}
                  >
                    Total Bonus
                  </Typography>
                </TableCell>
                {data?.role != "Staff" && (
                  <TableCell
                    sx={{
                      border: 0,
                      padding: (theme) => theme.spacing(1, 2),
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#546e7a",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      Total Revenue
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >{`${fromDate} - ${toDate}`}</TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {orderTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(salePriceTotal)} ₫
                </TableCell>
                {data?.role != "Staff" && (
                  <TableCell
                    sx={{
                      border: 0,
                      padding: (theme) => theme.spacing(1, 2),
                    }}
                  >
                    {number.format(salePriceTotal - basePriceTotal)} ₫
                  </TableCell>
                )}
                {data?.role != "Staff" && (
                  <TableCell
                    sx={{
                      border: 0,
                      padding: (theme) => theme.spacing(1, 2),
                    }}
                  >
                    {number.format(adfeeAmountTotal)} ₫
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(1, 2),
                  }}
                >
                  {number.format(bonusAmountTotal)} ₫
                </TableCell>
                {data?.role != "Staff" && (
                  <TableCell
                    sx={{
                      border: 0,
                      padding: (theme) => theme.spacing(1, 2),
                    }}
                  >
                    {number.format(
                      salePriceTotal -
                        basePriceTotal -
                        bonusAmountTotal -
                        adfeeAmountTotal
                    )}{" "}
                    ₫
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default GeneralReport;
