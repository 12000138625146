import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Fab,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import axiosusers from "utils/api/axiosusers";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FilledAlerts from "components/Alert/FilledAlerts";
import { useNavigate, useParams } from "react-router-dom";
const EditClientYoutube = () => {
  const { id } = useParams();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const navigate = useNavigate();
  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), name: "", quantity: "", price: 0, orderNumber: "1" },
  ]);
  const [input, setInput] = useState({
    name: " ",
    orderNumber: " ",
    price: 0,
    amount: 0,
    notes: " ",
    description: " ",
  });
  const getProductData = async () => {
    const data = await axiosusers.get(`/wallets/wallets-product/${id}`);
    setInput(data.data);
    const buget = await axiosusers.get(`/wallets/wallets-product-budget/${id}`);
    setInputFields(buget.data);
  };

  useEffect(() => {
    getProductData();
  }, [id]);

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    inputFields.map((item, i) => {
      setInputFields([
        ...inputFields,
        {
          id: uuidv4(),
          name: "",
          quantity: "",
          price: "",
          orderNumber: i + 1,
          status: true,
        },
      ]);
    });
  };
  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };
  const onSubmit = async () => {
    let errorMessage = "";
    inputFields.forEach((item) => {
      if (item.name === "" || item.price === "" || item.quantity === "") {
        errorMessage = "The data budgets invalid. Please check again";
      }
    });
    if (errorMessage !== "") {
      return seterrorMessage(errorMessage);
    }
    const data = {
      id,
      name: input.name,
      description: input.description,
      inputFields,
    };
    try {
      const res = await axiosusers.post(
        `/wallets/update-wallets-product`,
        data
      );
      console.log(res);
      navigate("/app/product-category/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Update product category
        </Typography>

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.name}
          name="name"
          error={Boolean(input.name === "")}
          helperText={input.name === "" ? "Name is required" : ""}
          label="Name"
          size="small"
          variant="outlined"
          fullWidth
        />

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.description}
          name="description"
          label="Description"
          size="small"
          variant="outlined"
          fullWidth
        />
        <Typography
          variant="subtitle1"
          sx={{ marginTop: `0.5rem`, fontWeight: 500 }}
        >
          Budgets:
        </Typography>
        {inputFields.map((inputField, id) => (
          <div
            style={{ display: "flex", marginBottom: "10px" }}
            key={inputField.id}
          >
            <TextField
              type="text"
              name="name"
              value={inputField.name}
              onChange={(event) => handleChangeInput(inputField.id, event)}
              label="Name"
              variant="outlined"
              size="small"
            />

            <div style={{ margin: "0px 10px" }}>
              <TextField
                type="number"
                name="quantity"
                value={inputField.quantity}
                onChange={(event) => handleChangeInput(inputField.id, event)}
                label="Quantity"
                variant="outlined"
                size="small"
              />
            </div>
            <TextField
              sx={{ width: "25ch" }}
              type="number"
              name="price"
              value={
                Number.isNaN(parseFloat(inputField.price))
                  ? ""
                  : Number.parseFloat(inputField.price)
              }
              onChange={(event) => handleChangeInput(inputField.id, event)}
              label="Price"
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <div>
                    {inputFields.length === Number(id + 1) && (
                      <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        onClick={handleAddFields}
                      >
                        <AddIcon />
                      </Fab>
                    )}
                  </div>
                ),
              }}
            />
            {inputField.status !== "true" && (
              <IconButton color="primary" disabled>
                <DeleteIcon />
              </IconButton>
            )}
            {inputField.status === "true" && (
              <IconButton
                color="primary"
                onClick={() => handleRemoveFields(inputField.id)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        ))}
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          multiline
          minRows="4"
          name="notes"
          value={input.notes}
          variant="outlined"
          fullWidth
        />
        {errorMessage && (
          <FilledAlerts message={errorMessage} success="error" />
        )}
        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default EditClientYoutube;
