import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const AddAdministrators = () => {
  Tabtitle("Create Administrators");
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);

  const initialValues = {
    no: "",
    name: "",
    link: "",
    phoneNumber: "",
    notes: "",
  };
  const validationSchema = Yup.object().shape({
    no: Yup.string().min(0, "It's too short").required(".No is required"),
    name: Yup.string().min(0, "It's too short").required("Name is required"),
  });
  const onSubmit = async (values) => {
    setDung(true);
    const data = {
      no: values.no,
      name: values.name,
      link: values.link,
      phoneNumber: values.phoneNumber,
      notes: values.notes,
    };
    try {
      await axiosusers.post(`/facebook-manage/create-administrators`, data);
      navigate("/app/facebook-manage/");
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
    }
  };
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new administrators
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="no"
                label=".No"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.no && props.touched.no}
                helperText={<ErrorMessage name="no" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="name"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.name && props.touched.name}
                helperText={<ErrorMessage name="name" />}
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="link"
                label="Link"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="phoneNumber"
                label="Phone Number"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                multiline
                minRows="4"
                name="notes"
                label="Notes"
                variant="outlined"
                size="small"
                fullWidth
              />
              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddAdministrators;
