import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import React from "react";
import styles from "./style.module.css";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import _ from "lodash";
import axiosusers from "utils/api/axiosusers";
import { NavLink } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

const Transactions = (props) => {
  const { transactions, pages } = props;
  const [done, setDone] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [searchCode, setSearchCode] = React.useState("");
  const [searchStatus, setSearchStatus] = React.useState("");
  const [SearchRemain, setSearchRemain] = React.useState("");
  const [product, setProduct] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pagination, setPagination] = React.useState([]);
  const pageSize = 10;
  const [pageCount, setPageCount] = React.useState(0);
  const idProductRef = React.useRef();
  const idProductRefpage = React.useRef();
  const idProductRef_name = React.useRef();
  const [Message, setMessage] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [DonePage, setDonePage] = React.useState([]);

  const [clients, setClients] = React.useState([]);

  React.useEffect(() => {
    setProduct(transactions);
    setDonePage(transactions);
    setPageCount(Math.ceil(pages / pageSize));
    setPagination(_(transactions).slice(0).take(pageSize).value());
  }, [transactions]);

  React.useEffect(() => {
    Promise.all([axiosusers.get(`/clients/clients`)])
      .then((results) => {
        const [clients] = results.map((res) => res.data);
        setClients(clients);
      })
      .catch((error) => console.error(error));
  }, []);

  const fetchDataAll = async () => {
    let results = product;

    const response = await axiosusers.get(
      "/transactions/transactions-all-new",
      {
        params: {
          status: searchStatus,
          clientId: selected?.id,
          code: searchCode,
          remain: SearchRemain,
          currentPage,
        },
      }
    );
    results = response.data.transactionsAll;

    setPageCount(Math.ceil(response.data.pages / pageSize));
    setPagination(results);
  };

  React.useEffect(() => {
    if (
      searchCode !== "" ||
      searchStatus !== "" ||
      selected?.id !== undefined ||
      SearchRemain !== "" ||
      currentPage !== 0
    ) {
      fetchDataAll();
    }
  }, [searchCode, searchStatus, selected, SearchRemain, currentPage]);
  const handleClickOpen = (id, page, name) => {
    setOpen(true);
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };
  const Pagechane = (event, value) => {
    setCurrentPage(value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
      status: idProductRef_name.current,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes/delete-transactions`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        fetchDataAll();
        if (idProductRef_name.current === "Delete") {
          setProduct(
            product.filter((item) => {
              return item.id !== idProductRef.current;
            })
          );
        } else {
          setProduct(
            product.map((item) => {
              if (item.id === idProductRef.current) {
                return {
                  ...item,
                  status: "completed",
                };
              }
              return item;
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
    setMessage(true);
    setTimeout(function () {
      setMessage(false);
    }, 200);
    setOpen(false);
  };

  setTimeout(function () {
    setMessage(false);
    setDone(false);
  }, 500);
  const chanDone = () => {
    setDone(true);
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });

  return (
    <div>
      <NavLink to="/app/transactions/add" className={styles.link}>
        <Button variant="contained" color="primary">
          ADD transactions
        </Button>
      </NavLink>
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Transactins status</InputLabel>
                <Select
                  value={searchStatus}
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                    setCurrentPage(1);
                  }}
                  label="Transactins status"
                  name="status"
                >
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="on_hold">On hold</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="on_hold_7day">On hold 7 day</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Autocomplete
                getOptionLabel={(jsonResuls) => jsonResuls.fullName}
                options={clients}
                fullWidth
                onChange={(event, value) => {
                  setSelected(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Client" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutlinedInput
                fullWidth
                onChange={(e) => {
                  setSearchCode(e.target.value);
                  setCurrentPage(1);
                }}
                placeholder="Search by transaction code"
                name="code"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
          </Grid>
          <br />
          <FormControlLabel
            name="remain"
            control={
              <Checkbox
                onChange={(e) => {
                  setSearchRemain(e.target.checked);
                  setCurrentPage(1);
                }}
                name="remain"
                color="primary"
              />
            }
            label="Show has remain amout only"
            color="primary"
          />
        </Paper>
      </Grid>

      {Message && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}

      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Transaction Code</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Payment</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Remain Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Update date</TableCell>
              <TableCell>Created date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagination.map((row) => {
              const nullCreate = row.createdAt.split("T");
              const dateCreate = nullCreate[0].split("-");
              const dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];

              const nullUpdate = row.updatedAt.split("T");
              const dateUpdate = nullUpdate[0].split("-");
              const dateUpdateAt =
                dateUpdate[2] + "/" + dateUpdate[1] + "/" + dateUpdate[0];
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Tooltip
                      title={done ? "Copied!" : "Copy"}
                      placement="top"
                      arrow
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(`${row.code}`);
                          chanDone();
                        }}
                      >
                        {row.code}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={done ? "Copied!" : "Copy"}
                      placement="top"
                      arrow
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(`${row.fullName}`);
                          chanDone();
                        }}
                      >
                        {row.fullName}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={done ? "Copied!" : "Copy"}
                      placement="top"
                      arrow
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(`${row.email}`);
                          chanDone();
                        }}
                      >
                        {row.email}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{row.owner}</TableCell>
                  <TableCell>
                    <Tooltip
                      title={done ? "Copied!" : "Copy"}
                      placement="top"
                      arrow
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${Math.round(row.remainAmount)}`
                          );
                          chanDone();
                        }}
                      >
                        {number.format(row.amount)}
                      </span>
                    </Tooltip>{" "}
                    ₫
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={done ? "Copied!" : "Copy"}
                      placement="top"
                      arrow
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${Math.round(row.remainAmount)}`
                          );
                          chanDone();
                        }}
                      >
                        {number.format(row.remainAmount)}
                      </span>
                    </Tooltip>{" "}
                    ₫
                  </TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{dateUpdateAt}</TableCell>
                  <TableCell>{dateCreateAt}</TableCell>

                  <TableCell size="small" align="center">
                    {row.status == "on_hold" && (
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleClickOpen(row.id, DonePage, "on_hold")
                        }
                      >
                        <DoneIcon />
                      </IconButton>
                    )}
                    <IconButton
                      color="primary"
                      onClick={() =>
                        handleClickOpen(row.id, DonePage, "Delete")
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {pageCount > 1 && (
          <Grid
            container
            spacing={0}
            sx={{
              justifyContent: "center",
              color: "#000",
              display: "flex",
              padding: "10px",
            }}
          >
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={Pagechane}
              size="small"
              color="primary"
            />
          </Grid>
        )}
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to delete this blacklist?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Transactions;
