import {
  Alert,
  Box,
  Button,
  Divider,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useState } from "react";

import axiosusers from "utils/api/axiosusers";
import * as Yup from "yup";

const Password = (props) => {
  const [users, setUsers] = useState({
    name: "",
    bankName: "",
    bankAccount: "",
    code: "",
    email: "",
    salary: "",
    bonus: "",
  });
  const [errorMessage, seterrorMessage] = useState("");
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal, open, message } = state;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };

  React.useEffect(() => {
    setUsers(props.users);
  }, [props]);
  const initialValues = {
    old: "",
    new: "",
    confirm: "",
  };
  const validationSchema = Yup.object().shape({
    old: Yup.string()
      .min(1, "Old password must be at least 6 characters")
      .required("Old password is required"),
    new: Yup.string()
      .min(1, "New password must be at least 6 characters")
      .required("New password is required"),
    confirm: Yup.string()
      .min(1, "Confirm password must be at least 6 characters")
      .required("Confirm password is required"),
  });

  const onSubmit = async (values) => {
    const newState = {
      vertical: "bottom",
      horizontal: "center",
      message: "Change password successfully!",
    };
    const data = {
      id: users.id,
      confirm: values.confirm,
      newPassword: values.new,
      password: values.old,
    };
    try {
      await axiosusers.post(`/usersLamlainao/update-passwords`, data);
      setState({ open: true, ...newState });
      seterrorMessage("");
    } catch (error) {
      console.log(error?.response);
      return seterrorMessage(error.response.data.message);
    }
  };
  return (
    <div>
      <Box sx={{ padding: "16px" }}>
        <Typography variant="h5" style={{ fontWeight: 500, fontSize: "16px" }}>
          Password
        </Typography>
        <Typography
          variant="h5"
          style={{
            fontWeight: 400,
            color: "#546e7a",
            fontSize: "16px",
            marginTop: "2px",
          }}
        >
          Update password
        </Typography>
      </Box>
      <Divider />
      <br />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <Box
              sx={{
                padding: (theme) => theme.spacing(0, 2, 0, 2),
              }}
            >
              <Field
                sx={{ margin: (theme) => theme.spacing(1, 0) }}
                as={TextField}
                type="password"
                name="old"
                label="Old Password"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.old && props.touched.old}
                helperText={<ErrorMessage name="old" />}
              />
              <Field
                sx={{ margin: (theme) => theme.spacing(1, 0) }}
                as={TextField}
                type="password"
                name="new"
                label="New Password"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.new && props.touched.new}
                helperText={<ErrorMessage name="new" />}
              />
              <Field
                sx={{ margin: (theme) => theme.spacing(1, 0) }}
                as={TextField}
                type="password"
                name="confirm"
                label="Confirm password"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.confirm && props.touched.confirm}
                helperText={<ErrorMessage name="confirm" />}
              />
            </Box>
            <br />
            <Divider />
            <div style={{ display: "flex", justifyContent: "center" }}>
              {errorMessage.length > 0 && (
                <Alert severity="error">{errorMessage}</Alert>
              )}
            </div>
            <Box
              sx={{
                textAlign: "right",
                padding: (theme) => theme.spacing(3, 2, 2, 2),
              }}
            >
              <Button type="submit" variant="contained" color="primary">
                CHANGE PASSWORD
              </Button>
            </Box>
            <Divider />
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        key={vertical + horizontal}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Password;
