import React from "react";
import SalesArticleNotes from "./SalesArticleNotes";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Sales Article Notes");
  return (
    <div>
      <SalesArticleNotes />
    </div>
  );
};

export default index;
