import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

import axiosusers from "utils/api/axiosusers";
import { format } from "date-fns";
import GeneralReport from "./GeneralReport";
import TableReport from "./TableReport";
const Reports = (props) => {
  const { data } = props;
  const [input, setInput] = useState("");
  const [selectGame, setSelectGame] = useState(null);
  const [value, setValue] = React.useState([null, null]);
  const [product, setProduct] = useState([]);
  const [games, setGames] = useState([]);
  const [users, setUsers] = useState([]);
  const [advertisementfee, setAdvertisementfee] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const fetchData = async () => {
    Promise.all([
      axiosusers.get(`/games/games`),
      axiosusers.get(`/usersLamlainao/users-role`),
      axiosusers.get(`/facebook-manage/advertisementfee`),
      axiosusers.get(`/reports/reports-orders`, {
        params: {
          from: value[0],
          to: value[1],
          userId: data.role === "Admin" ? selectUser?.id : data?.id,
          gameId: selectGame?.id,
        },
      }),
    ])
      .then((results) => {
        const [games, users, advertisementfee, orders] = results.map(
          (res) => res.data
        );

        setProduct(orders);
        setGames(games);
        setUsers(users);

        setAdvertisementfee(advertisementfee);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    if (data) {
      fetchData();
    }
  }, [selectGame, selectUser, value, data]);
  const handleChange = (e) => {
    setSelectGame(null);
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const fromDate = value[0]
    ? format(value[0], "dd/MM/yyyy")
    : format(new Date(), "dd/MM/yyyy");
  const toDate = value[1]
    ? format(value[1], "dd/MM/yyyy")
    : format(new Date(), "dd/MM/yyyy");
  const productId = product.filter((order) => {
    if (data?.role === "Staff") {
      return data?.id === order.userId;
    }
    return true;
  });
  const dates = productId.reduce((acc, cur) => {
    const date = cur.createdAt.slice(0, 10);
    if (!acc[date]) {
      acc[date] = true;
    }
    return acc;
  }, {});

  // sort the dates in descending order
  const sortedDates = Object.keys(dates).sort(
    (a, b) => new Date(b) - new Date(a)
  );
  // map over the sorted dates and reduce to get the aggregated data
  const result = sortedDates.map((date) => {
    const orderCount = productId.reduce((acc, cur) => {
      if (cur.createdAt.slice(0, 10) === date) {
        acc += 1;
      }
      return acc;
    }, 0);

    const salePrice = productId.reduce((acc, cur) => {
      if (cur.createdAt.slice(0, 10) === date) {
        acc += cur.salePrice ? parseFloat(cur.salePrice) : 0;
      }
      return acc;
    }, 0);

    const basePrice = productId.reduce((acc, cur) => {
      if (cur.createdAt.slice(0, 10) === date) {
        acc += cur.basePrice ? parseFloat(cur.basePrice) : 0;
      }
      return acc;
    }, 0);
    const bonusAmount = productId.reduce((acc, cur) => {
      if (cur.createdAt.slice(0, 10) === date) {
        acc += cur.bonusAmount ? parseFloat(cur.bonusAmount) : 0;
      }
      return acc;
    }, 0);

    const adFreeAmount = (
      selectGame
        ? advertisementfee.filter((free) => free.gameId === selectGame?.id)
        : advertisementfee
    ).reduce((acc, cur) => {
      if (cur.createdAt.slice(0, 10) === date) {
        acc += cur.amount ? parseFloat(cur.amount) : 0;
      }
      return acc;
    }, 0);

    return {
      date,
      orderCount,
      salePrice,
      basePrice,
      bonusAmount,
      adFreeAmount,
    };
  });

  const orderTotal = product.reduce((acc) => {
    return acc + 1;
  }, 0);
  const salePriceTotal = product.reduce((acc, order) => {
    return acc + parseFloat(order.salePrice);
  }, 0);
  const basePriceTotal = product.reduce((acc, order) => {
    return acc + parseFloat(order.basePrice);
  }, 0);
  const bonusAmountTotal = product.reduce((acc, order) => {
    return acc + parseFloat(order.bonusAmount);
  }, 0);
  // const adfeeAmountTotal = advertisementfee.reduce((acc, order) => {
  //   return acc + parseFloat(order.amount);
  // }, 0);
  const adfeeAmountTotal = result.reduce((acc, order) => {
    return acc + parseFloat(order.adFreeAmount);
  }, 0);
  return (
    <div>
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            {data?.role != "Staff" && (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Autocomplete
                  options={users}
                  fullWidth
                  onChange={(event, value) => setSelectUser(value)}
                  getOptionLabel={(users) => users.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select an user"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Select Product</InputLabel>
                <Select
                  value={input.status}
                  onChange={handleChange}
                  label="Select Product"
                  name="status"
                >
                  <MenuItem value="game">Games-Top up</MenuItem>
                  <MenuItem value="giftCard">Gift Card</MenuItem>
                  <MenuItem value="softWare">softWare</MenuItem>
                  <MenuItem value="account">Games Account</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Autocomplete
                value={selectGame}
                options={
                  input.status
                    ? games.filter((game) => game.status === input.status)
                    : games
                }
                fullWidth
                onChange={(event, values) => {
                  setSelectGame(values);
                }}
                getOptionLabel={(games) => games.name}
                renderInput={(params) => (
                  <TextField {...params} label="Product" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  value={value}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField
                        {...startProps}
                        helperText="dd/mm/yyyy"
                        label="from"
                        variant="outlined"
                      />
                      <Box style={{ margin: "13px" }}> to </Box>
                      <TextField
                        {...endProps}
                        helperText="dd/mm/yyyy"
                        label="to"
                        variant="outlined"
                      />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <GeneralReport
        data={data}
        selectUser={selectUser}
        selectGame={selectGame}
        fromDate={fromDate}
        toDate={toDate}
        orderTotal={orderTotal}
        salePriceTotal={salePriceTotal}
        basePriceTotal={basePriceTotal}
        bonusAmountTotal={bonusAmountTotal}
        adfeeAmountTotal={adfeeAmountTotal}
      />
      <div style={{ marginTop: "40px" }} />
      {result.length > 0 && <TableReport data={data} result={result} />}
    </div>
  );
};

export default Reports;
