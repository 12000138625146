import { CircularProgress } from "@mui/material";
import axiosusers from "utils/api/axiosusers";

import React from "react";
import useSWR from "swr";
import CountryGiftCards from "./CountryGiftCards";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Country Gift Cards");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const {
    data: countryData,
    error: countryError,
    isLoading: countryLoading,
  } = useSWR(`/countrys/country`, fetchDataSwr);

  const {
    data: gamesData,
    error: gamesrror,
    isLoading: gamesLoading,
  } = useSWR(`/games/games`, fetchDataSwr);
  return (
    <div>
      {countryError && <div>failed to load</div>}
      {gamesrror && <div>failed to load</div>}
      {countryLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {!countryLoading && !gamesLoading && (
        <CountryGiftCards countrys={countryData} games={gamesData} />
      )}
    </div>
  );
};

export default index;
