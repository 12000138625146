import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axiosusers from "utils/api/axiosusers";
import React, { useState } from "react";

const TransactionDetailUndo = (props) => {
  const { jsonTransactions, items, id } = props;
  const [fieldValueTransaction, setFieldValueTransaction] = useState([]);

  const setChangeTransaction = async (values) => {
    const sortedValues = values.sort((a, b) => a.remainAmount - b.remainAmount);

    let remainQuantity = 0;
    let updatedRows = sortedValues.map((item, index) => {
      let usedAmount = 0;
      if (index === 0) {
        // id1: usedAmount=salePrice-item.remainAmount
        usedAmount = items.salePrice - item.remainAmount;
        if (usedAmount > 0) {
          usedAmount = item.remainAmount;
          remainQuantity = items.salePrice - item.remainAmount;
        } else {
          usedAmount = items.salePrice;
          remainQuantity = 0;
        }
      } else {
        // id2 & id3: usedAmount=usedAmount(cũ)-item.remainAmount
        if (remainQuantity >= item.remainAmount) {
          usedAmount = item.remainAmount;
          remainQuantity = remainQuantity - item.remainAmount;
        } else if (remainQuantity < item.remainAmount && remainQuantity > 0) {
          usedAmount = remainQuantity;
          remainQuantity = 0;
        } else {
          usedAmount = 0;
          remainQuantity = 0;
        }
      }

      return { ...item, usedAmount };
    });

    setFieldValueTransaction(updatedRows);
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const totalUsedAmount = fieldValueTransaction.reduce(
    (total, item) => total + Number(item.usedAmount),
    0
  );
  const handleSubmit = async (e, name) => {
    e.preventDefault();
    if (name === "transaction") {
      let paymentStatus = "";
      if (totalUsedAmount === 0) {
        paymentStatus = "unPaid";
      } else if (
        items &&
        items.salePrice &&
        items.salePrice <= totalUsedAmount
      ) {
        paymentStatus = "paid";
      } else {
        paymentStatus = "partPaid";
      }
      const dataBudgets = {
        orderId: id,
        paymentStatus,
        fieldValueTransaction,
      };
      try {
        const resbudgets = await axiosusers.post(
          `/transactions/create-transactions-orders`,
          dataBudgets
        );
        if (resbudgets.status === 200) {
          console.log(resbudgets);
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div>
      <Grid container spacing={1} sx={{ margin: "4px 0 " }}>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            variant="h5"
            color="primary"
            sx={{ color: "#3f51b5", fontWeight: 500, fontSize: "1rem" }}
          >
            Transactions:
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={8}>
          <Autocomplete
            multiple
            options={jsonTransactions}
            fullWidth
            filterSelectedOptions
            onChange={(event, value) => setChangeTransaction(value)}
            getOptionLabel={(jsonTransactions) => jsonTransactions.code}
            size="medium"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Transaction"
                placeholder="Select one or multiple"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>

      {fieldValueTransaction.length > 0 && (
        <>
          <Grid container spacing={1} sx={{ margin: "4px 0 " }}>
            <Grid item lg={2} md={2} sm={2} xs={4}></Grid>
            <Grid item lg={2} md={2} sm={2} xs={3}>
              <Typography variant="subtitle1">Transactions</Typography>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={3}>
              <Typography variant="subtitle1">Remain amount</Typography>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <Typography variant="subtitle1">Used amount</Typography>
            </Grid>
          </Grid>
          {fieldValueTransaction.map((item) => {
            return (
              <Grid
                container
                spacing={0}
                sx={{ margin: "4px 10px " }}
                key={item.id}
              >
                <Grid item lg={2} md={2} sm={2} xs={4}></Grid>
                <Grid item lg={2} md={2} sm={2} xs={3}>
                  <Typography variant="subtitle1">{item.code}</Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={3}>
                  <Typography variant="subtitle1">
                    {number.format(item.remainAmount)} ₫
                  </Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Typography variant="subtitle1">
                    {number.format(item.usedAmount)} ₫
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
          <Grid container spacing={1} sx={{ margin: "4px 0px " }}>
            <Grid item lg={2} md={2} sm={2} xs={4}></Grid>
            <Grid item lg={2} md={2} sm={2} xs={8}>
              <Typography
                variant="h5"
                sx={{ fontWeight: 500, fontSize: "20px" }}
              >
                Pair Amount:{" "}
                {number.format(
                  fieldValueTransaction.reduce(
                    (total, item) => total + Number(item.usedAmount),
                    0
                  )
                )}{" "}
                ₫
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={1} sx={{ margin: "4px 0px " }}>
        <Grid item lg={2} md={2} sm={2} xs={4}></Grid>
        <Grid item lg={5} md={5} sm={5} xs={8}>
          <Button
            onClick={(e) => handleSubmit(e, "transaction")}
            variant="contained"
            name="create_game"
            color="primary"
            fullWidth
            size="large"
          >
            ADD Transaction
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default TransactionDetailUndo;
