import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Tabtitle } from "components/title/Title";
import { useNavigate, useParams } from "react-router-dom";
import useUserId from "hooks/useUserId";
import axiosusers from "utils/api/axiosusers";
import useLinks from "hooks/useLinks";
import FilledAlerts from "components/Alert/FilledAlerts";
import useCountryCode from "hooks/useCountryCode";

const index = () => {
  Tabtitle("Update giftcards");
  let navigate = useNavigate();
  const linkImage = useLinks();
  const [image, setImage] = useState();
  const [avatar, setAvatar] = useState();
  const [errorMessage, seterrorMessage] = useState(false);

  const { id } = useParams();
  const [input, setInput] = useState({
    name: " ",
    description: " ",
    status: " ",
    notesen: " ",
    noteses: " ",
    optionsCountry: " ",
  });
  const getProduct = async () => {
    const data = await axiosusers.get(`/games/games/${id}`);
    console.log(data.data);
    setInput(data.data);
  };
  useEffect(() => {
    getProduct();
  }, [id]);
  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    setImage(file);

    file.preview = URL.createObjectURL(file);
    setAvatar(file);
  };
  const { countries } = useCountryCode();
  const [countryData, setCountryData] = useState([]);
  const [fieldValueCountry, setFieldValueCountry] = useState([]);
  useEffect(() => {
    if (input.gamesLanguage) {
      const countriesNot = countries.filter((item) => {
        return input.gamesLanguage.some((item2) => item2.code === item.code);
      });
      setFieldValueCountry(countriesNot);
    }
    if (input.gamesLanguage && input.gamesLanguage.length > 0) {
      const countriesData = countries.filter((item) => {
        return input.gamesLanguage.some((item2) => item2.code !== item.code);
      });

      setCountryData(countriesData);
    } else {
      setCountryData(countries);
    }
  }, [input]);
  const onSubmit = async () => {
    if (image?.size > 2 * 1024 * 1024) {
      return seterrorMessage("size must be less than 2MB");
    }
    const formData = new FormData();
    const datatime = new Date().getTime();
    formData.append("id", id);
    formData.append("name", input.name);
    formData.append("notesen", input.notesen);
    formData.append("noteses", input.noteses);
    formData.append("description", input.description);
    formData.append("file", image);
    formData.append("datatime", datatime);
    formData.append("optionsCountry", input.optionsCountry);
    try {
      const response = await axiosusers.post(
        `/uploads/update-games`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      const dataBudgets = {
        gameId: id,
        fieldValueCountry,
      };
      try {
        const resbudgets = await axiosusers.post(
          `/games/create-games-language`,
          dataBudgets
        );

        if (resbudgets.status === 200) {
          console.log(resbudgets);
        }
      } catch (error) {
        console.log(error.response?.data.message);
      }
      if (response.status === 200) {
        navigate("/app/giftcards/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setChangeCountry = async () => {};
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
          }}
        >
          Update giftcards
        </Typography>

        <TextField
          sx={{ marginTop: `1rem` }}
          onChange={handleinput}
          type="text"
          value={input.name}
          name="name"
          error={Boolean(input.name === "")}
          helperText={input.name === "" ? "Name is required" : ""}
          label="Name"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.description}
          name="description"
          label="Description"
          size="small"
          variant="outlined"
          fullWidth
        />
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          sx={{ marginTop: `0.5rem` }}
        >
          <InputLabel>Sell For Country</InputLabel>
          <Select
            value={input.optionsCountry}
            onChange={handleinput}
            label="Sell For Country"
            name="optionsCountry"
          >
            <MenuItem value="global">Global</MenuItem>
            <MenuItem value="showOnlySomeCountries">
              Show only some countries
            </MenuItem>
            <MenuItem value="excludingSomeCountries">
              Excluding some countries
            </MenuItem>
          </Select>
        </FormControl>
        {input.optionsCountry != "global" && (
          <Autocomplete
            id="country-select-demo"
            size="small"
            sx={{ marginTop: `0.5rem` }}
            options={countryData}
            value={fieldValueCountry}
            multiple
            filterSelectedOptions
            onChange={(event, values) => {
              setFieldValueCountry(values);
              setChangeCountry(values);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Some countries"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        )}
        <TextField
          onChange={handleinput}
          multiline
          minRows={4}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.notesen}
          name="notesen"
          label="Nofication English"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          multiline
          minRows={4}
          value={input.noteses}
          name="noteses"
          label="Nofication Spanish"
          size="small"
          variant="outlined"
          fullWidth
        />

        {avatar && (
          <Avatar
            src={avatar.preview}
            variant="square"
            sx={{
              width: (theme) => theme.spacing(25),
              height: (theme) => theme.spacing(25),
              margin: (theme) => theme.spacing(2, 0),
            }}
          />
        )}
        {!avatar && input.image !== "" && (
          <img
            src={`${linkImage}${input.image}`}
            crossOrigin="anonymous"
            style={{
              width: "200px",
              height: "200px",
              margin: "1rem 0 0 0",
            }}
          />
        )}
        <br />
        <input
          accept="image/*"
          style={{
            display: "none",
            color: "#3f50b5",
            margin: (theme) => theme.spacing(2, 0),
          }}
          onChange={handleFile}
          id="file"
          name="avatar"
          multiple
          type="file"
        />
        <label htmlFor="file">
          <Button component="span" color="primary" sx={{ marginTop: `1rem` }}>
            UPLOAD PHOTOS
          </Button>
        </label>

        {/* {errorMessage.length > 0 && <Alert severity="error" className={classes.errortext}>{errorMessage}</Alert>	}           */}
        {errorMessage && (
          <FilledAlerts message={errorMessage} success="error" />
        )}
        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default index;
