import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Tabtitle } from "components/title/Title";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";

const index = () => {
  Tabtitle("Update Friends Game Id");
  let navigate = useNavigate();

  const { id } = useParams();

  const [jsonResuls, setJsonResuls] = useState([]);

  const [selectedGame, setSelectedGame] = useState({
    name: "",
    id: "",
  });

  const [input, setInput] = useState({
    name: " ",
    description: " ",
    status: " ",
    statusGame: " ",
    noteses: " ",
  });
  useEffect(() => {
    Promise.all([
      axiosusers.get(`/friends/friends/${id}`),
      axiosusers.get(`/games/games`),
    ]).then(async (results) => {
      const [clienId, games] = results.map((res) => res.data);
      setInput(clienId);

      setJsonResuls(games);

      const gameId = await axiosusers.get(`/games/games/${clienId.gameId}`);
      setSelectedGame({
        name: gameId.data.name,
        id: gameId.data.id,
      });
    });
  }, [id]);

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async () => {
    const data = {
      id,
      gameId: selectedGame.id,
      name: input.name,
      status: input.status,
      statusGame: input.statusGame,
    };
    try {
      const response = await axiosusers.post(`/friends/update-friends`, data);

      if (response.status === 200) {
        navigate("/app/friends-game-id/");
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <div>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "30px",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
          >
            Update Friends Game Id
          </Typography>
          <FormControl
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginTop: `1.5rem` }}
          >
            <InputLabel>Product</InputLabel>
            <Select
              value={input.statusGame}
              onChange={handleinput}
              label="Product"
              name="statusGame"
            >
              <MenuItem value="game">Games-Top up</MenuItem>
              <MenuItem value="giftCard">Gift Card</MenuItem>
              <MenuItem value="softWare">softWare</MenuItem>
              <MenuItem value="account">Games Account</MenuItem>
              <MenuItem value="skinItemGeneral">Shin Item General</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            sx={{ marginTop: `0.5rem` }}
            options={jsonResuls.filter(
              (item) => item.status == input.statusGame
            )}
            fullWidth
            value={selectedGame}
            onChange={(event, value) => setSelectedGame(value)}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(jsonResuls) => jsonResuls.name}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(selectedGame === null)}
                helperText={
                  selectedGame === null ? "Gift Card is required" : ""
                }
                label={input.statusGame
                  .replace(/([A-Z])/g, " $1")
                  .toLowerCase()
                  .replace(/^./, function (str) {
                    return str.toUpperCase();
                  })}
                variant="outlined"
              />
            )}
          />

          <TextField
            onChange={handleinput}
            sx={{ marginTop: `0.5rem` }}
            type="text"
            value={input.name}
            name="name"
            label="Game ID"
            size="small"
            variant="outlined"
            fullWidth
          />
          <FormControl
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginTop: `0.5rem` }}
          >
            <InputLabel>Status game id</InputLabel>
            <Select
              value={input.status}
              onChange={handleinput}
              label="Status game id"
              name="status"
            >
              <MenuItem value="full">Full ID</MenuItem>
              <MenuItem value="notFull">Not Full ID</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            color="primary"
            sx={{ marginTop: `3rem` }}
            onClick={onSubmit}
          >
            Update
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default index;
