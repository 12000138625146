import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";
import axiosusers from "utils/api/axiosusers";
import useUserId from "hooks/useUserId";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import { sendMail } from "hooks/useSendMail";
const Blocks = (props) => {
  const { clients, page } = props;
  const { userId } = useUserId();

  const [product, setProduct] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pagination, setPagination] = React.useState([]);
  const pageSize = 10;
  const [pageCount, setPageCount] = React.useState(0);
  const idProductRef = React.useRef();
  const idProductRefpage = React.useRef();
  const idProductRef_name = React.useRef();
  const [Message, setMessage] = React.useState(false);
  const [searchMail, setSearchMail] = React.useState("");
  const [searchStatus, setSearchStatus] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [DonePage, setDonePage] = React.useState([]);

  React.useEffect(() => {
    const orders = clients.filter((item) => item.primarychannelId === 4);
    setProduct(orders);
    setDonePage(orders);
    setPageCount(Math.ceil(page / pageSize));
    setPagination(_(orders).slice(0).take(pageSize).value());
  }, [clients]);

  const fetchDataAll = async () => {
    let results = product;

    const response = await axiosusers.get("/clients/clients-new", {
      params: {
        email: searchMail,
        status: searchStatus,
        currentPage,
      },
    });
    results = response.data.clientsAll;
    setPageCount(Math.ceil(response.data.page / pageSize));
    setPagination(results);
    setDonePage(results);
  };
  React.useEffect(() => {
    if (searchStatus !== "" || searchMail !== "" || currentPage !== 0) {
      fetchDataAll();
    }
  }, [searchStatus, searchMail, currentPage]);

  const handleClickOpen = (id, page, name) => {
    setOpen(true);
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };
  const Pagechane = (event, value) => {
    setCurrentPage(value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    setMessage(true);
    const dataSend = {
      id: idProductRef.current,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes/delete-clients-${idProductRef_name.current}`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        fetchDataAll();
        if (idProductRef_name.current === "Delete") {
          setProduct(
            product.filter((item) => {
              return item.id !== idProductRef.current;
            })
          );
        } else if (idProductRef_name.current === "block") {
          setProduct(
            product.map((item) => {
              if (item.id === idProductRef.current) {
                return {
                  ...item,
                  active: true,
                };
              }
              return item;
            })
          );
          let dataSend = {
            email: product.find((item) => item.id === idProductRef.current)
              .email,
            name: product.find((item) => item.id === idProductRef.current)
              .fullName,
            message:
              "Your account has been locked due to fraudulent activity detected on your account.",
            phoneNumber: product.find(
              (item) => item.id === idProductRef.current
            ).phoneNumber,
            checkedID: false,
            checkedCode: false,
            checkedName: false,
            checkedServer: false,
            checkedAcountPass: false,
            subjectGift: true,
            clientId: idProductRef.current,
            status: "notifications",
          };
          sendMail(dataSend);
        } else if (idProductRef_name.current === "noBlock") {
          setProduct(
            product.map((item) => {
              if (item.id === idProductRef.current) {
                return {
                  ...item,
                  active: false,
                };
              }
              return item;
            })
          );
          let dataSend = {
            email: product.find((item) => item.id === idProductRef.current)
              .email,
            name: product.find((item) => item.id === idProductRef.current)
              .fullName,
            message: "Your account has been open.",
            phoneNumber: product.find(
              (item) => item.id === idProductRef.current
            ).phoneNumber,
            checkedID: false,
            checkedCode: false,
            checkedName: false,
            checkedServer: false,
            checkedAcountPass: false,
            subjectGift: true,
            clientId: idProductRef.current,
            status: "notifications",
          };
          sendMail(dataSend);
        }
      }
    } catch (error) {
      console.log(error);
    }

    setOpen(false);
    setTimeout(function () {
      setMessage(false);
    }, 200);
  };

  return (
    <div>
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Block status</InputLabel>
                <Select
                  value={searchStatus}
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                    setCurrentPage(1);
                  }}
                  label="Block status"
                  name="status"
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="block">Block</MenuItem>
                  <MenuItem value="unBlock">Un Block</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutlinedInput
                fullWidth
                onChange={(e) => {
                  setSearchMail(e.target.value);
                  setCurrentPage(1);
                }}
                placeholder="Search by client Email"
                name="email"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {Message && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}

      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>

              {userId?.role == "Admin" && (
                <TableCell>Whatsapp Number</TableCell>
              )}
              <TableCell>Created date</TableCell>
              <TableCell>Block</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagination.map((row) => {
              const nullCreate = row.createdAt.split("T");
              const dateCreate = nullCreate[0].split("-");
              const timeCreate = nullCreate[1].split(".");
              const dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];

              return (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>
                    <Tooltip title="Copy" placement="top" arrow>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(`${row.fullName}`);
                        }}
                      >
                        {row.fullName}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  {userId?.role == "Admin" && (
                    <TableCell>{row.phoneNumber}</TableCell>
                  )}
                  <TableCell>
                    {dateCreateAt} - {timeCreate[0]}
                  </TableCell>
                  <TableCell>
                    {row.active ? (
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleClickOpen(row.id, DonePage, "noBlock")
                        }
                      >
                        <DoneIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleClickOpen(row.id, DonePage, "block")
                        }
                      >
                        <CancelIcon color="error" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {pageCount > 1 && (
          <Grid
            container
            spacing={0}
            sx={{
              justifyContent: "center",
              color: "#000",
              display: "flex",
              padding: "10px",
            }}
          >
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={Pagechane}
              size="small"
              color="primary"
            />
          </Grid>
        )}
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {idProductRef_name.current === "Delete"
              ? `WARNING: If you delete this Client, all transactions, orders which
            was created for this Client will be deleted too, Are you sure?`
              : "Are you sure you want to change the status of this Client?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Blocks;
