import React from "react";
import EditBlacklists from "./EditBlacklists";
import { useParams } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Update Blacklists");
  const { id } = useParams();
  return <div>{id && <EditBlacklists id={id} />}</div>;
};

export default index;
