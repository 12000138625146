import React from "react";
import { Button } from "@mui/material";

import axiosusers from "utils/api/axiosusers";

import { sendOxxo } from "hooks/useSendMailOxxo";
import { useNavigate } from "react-router-dom";

const Submitcompleteds = (props) => {
  const { items } = props;
  const { userId } = props;
  const navigate = useNavigate();
  const [message, setMessage] = React.useState(false);

  const Submitcompleted = async (name) => {
    setMessage(true);

    const dataSendinfo = {
      id: items.id,
      info: items.info,
    };
    try {
      await axiosusers.post(`/order/update-orders-info`, dataSendinfo);
    } catch (error) {
      console.log(error);
    }
    const dataSend = {
      id: items.id,
      status: name,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes/delete-orders-${name}`,
        dataSend
      );
      if (response.status === 200) {
        navigate("/app/orders/");
        console.log(response);

        setMessage(false);
        if (name == "completed" && items.primarychannelId === 4) {
          let dataSend = {
            email: items.email,
            name: items.fullName,
            message: "",
            phoneNumber: items.phoneNumber,
            checkedID: false,
            checkedCode: false,
            checkedName: false,
            checkedServer: false,
            checkedAcountPass: false,
            subjectGift: true,
          };
          sendOxxo(dataSend);
        }
      }
    } catch (error) {
      setMessage(false);
      console.log(error);
    }
  };
  return (
    <>
      {(items?.status == "inProgress" && "Completed") ||
      (items?.status == "completed" && userId.role == "Admin" && "Mark done") ||
      (items?.status == "done" && userId.role == "Admin" && "Undo") ? (
        <Button
          disabled={message}
          variant="contained"
          onClick={() => {
            if (items?.status === "inProgress") {
              Submitcompleted(message ? "" : "completed");
            } else if (items?.status === "completed") {
              Submitcompleted(message ? "" : "done");
            } else if (items?.status === "done") {
              Submitcompleted(message ? "" : "undoDone");
            } else {
              Submitcompleted(message ? "" : "delete");
            }
          }}
          color="primary"
          size="large"
        >
          {items?.status === "inProgress" && "Completed"}
          {items?.status === "completed" &&
            userId.role == "Admin" &&
            "Mark done"}
          {items?.status === "done" && userId.role == "Admin" && "Undo"}
        </Button>
      ) : null}
      {items?.status !== "done" && (
        <Button
          variant="contained"
          disabled={message}
          onClick={() => Submitcompleted("delete")}
          color="primary"
          sx={{ backgroundColor: "#d32f2f", color: "#fff", marginLeft: "20px" }}
          size="large"
        >
          Delete
        </Button>
      )}
    </>
  );
};

export default Submitcompleteds;
