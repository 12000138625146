import React from "react";
import AddPayments180Days from "./AddPayments180Days";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Create Payments180Days");
  return (
    <div>
      <AddPayments180Days />
    </div>
  );
};

export default index;
