import React from "react";
import AddAdvertiseFee from "./AddAdvertiseFee";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Create Advertise Fee");
  return (
    <div>
      <AddAdvertiseFee />
    </div>
  );
};

export default index;
