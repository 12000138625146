import React from "react";
import EditPayments180Days from "./EditPayments180Days";
import { useParams } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Update Payments180Days");
  const { id } = useParams();
  return <div>{id && <EditPayments180Days id={id} />}</div>;
};

export default index;
