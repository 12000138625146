import { Tabtitle } from "components/title/Title";
import React from "react";
import { useParams } from "react-router-dom";
import DetailWallets from "./DetailWallets";

const index = () => {
  Tabtitle("Detail Wallets");
  const { id } = useParams();
  return <div> {id && <DetailWallets id={id} />}</div>;
};

export default index;
