import { Tabtitle } from "components/title/Title";
import React from "react";
import ApiTelegrams from "./ApiTelegrams";

const index = () => {
  Tabtitle("Api Telegrams");
  return (
    <div>
      <ApiTelegrams />
    </div>
  );
};

export default index;
