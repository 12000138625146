import React from "react";
import Games from "./Games";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";
import { Tabtitle } from "components/title/Title";
import { CircularProgress } from "@mui/material";
import useUserId from "hooks/useUserId";

const index = () => {
  Tabtitle("Games");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };
  const { data, error, isLoading } = useSWR(
    `/games/skin-item-general`,
    fetchDataSwr
  );
  const { userId } = useUserId();
  return (
    <div>
      {error && <div>failed to load</div>}
      {isLoading && !userId && (
        <div>
          <CircularProgress />
        </div>
      )}
      {!isLoading && userId && <Games data={data} />}
    </div>
  );
};

export default index;
