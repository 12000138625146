import React from "react";
import DetailGames from "./DetailGames";
import { useParams } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Detail game");
  const { id } = useParams();
  return <div>{id && <DetailGames id={id} />}</div>;
};

export default index;
