import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import React from "react";
import useDecodedToken from "utils/token/useDecodedToken";

const TotalPackage = (props) => {
  const { budgets } = props;
  const { decodedToken } = useDecodedToken();

  const uniqueWallets = budgets.filter(
    (v, i, a) => a.findIndex((t) => t.walletsPrice === v.walletsPrice) === i
  );

  const totalWallet = uniqueWallets.map((item) => {
    const totalusedAmount = budgets.reduce((acc, cur) => {
      if (cur.walletsPrice === item.walletsPrice) {
        acc += cur.totalAmount ? parseFloat(cur.totalAmount) : 0;
      }
      return acc;
    }, 0);
    const totalusedQuantity = budgets.reduce((acc, cur) => {
      if (cur.walletsPrice === item.walletsPrice) {
        acc += cur.usedQuantity ? parseFloat(cur.usedQuantity) : 0;
      }
      return acc;
    }, 0);
    const totalName = budgets.reduce((acc, cur) => {
      if (cur.walletsPrice === item.walletsPrice) {
        acc = cur.name;
      }
      return acc;
    }, 0);
    return {
      name: totalName,
      budgetsPrice: item.walletsPrice,
      totalusedQuantity,
      totalusedAmount,
    };
  });

  const numberUsd = new Intl.NumberFormat("en-US");
  return (
    <div>
      <Grid container spacing={1} sx={{ margin: "4px 0 0 0px" }}>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            variant="h5"
            color="primary"
            sx={{ color: "#3f51b5", fontWeight: 500, fontSize: "1rem" }}
          >
            Total:
          </Typography>
        </Grid>
        <Grid item lg={10} md={10} sm={10} xs={9}>
          <Table aria-label="a dense table" disableRipple={true}>
            <TableHead>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(0, 1, 1, 0),
                  width: "33.33%",
                }}
              >
                <Typography>Package name</Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(0, 1, 1, 0),
                  width: "33.33%",
                }}
              >
                <Typography>Total quantity</Typography>
              </TableCell>
              {decodedToken?.role == "Admin" && (
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(0, 1, 1, 0),
                    width: "33.33%",
                  }}
                >
                  <Typography>Total Price</Typography>
                </TableCell>
              )}
            </TableHead>
            <TableBody>
              {totalWallet
                .sort((a, b) => b.totalusedQuantity - a.totalusedQuantity)
                .map((item, keyId) => {
                  if (item.totalusedQuantity !== 0) {
                    return (
                      <TableRow key={keyId}>
                        <TableCell
                          sx={{
                            border: 0,
                            padding: (theme) => theme.spacing(0, 1, 1, 0),
                            width: "33.33%",
                          }}
                        >
                          <Typography variant="subtitle1">
                            {item.name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            border: 0,
                            padding: (theme) => theme.spacing(0, 1, 1, 0),
                            width: "33.33%",
                          }}
                        >
                          <Typography variant="subtitle1">
                            {numberUsd.format(item.totalusedAmount)}
                          </Typography>
                        </TableCell>
                        {decodedToken?.role == "Admin" && (
                          <TableCell
                            sx={{
                              border: 0,
                              padding: (theme) => theme.spacing(0, 1, 1, 0),
                              width: "33.33%",
                            }}
                          >
                            <Typography variant="subtitle1">
                              {numberUsd.format(item.totalusedQuantity)} ₫
                            </Typography>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  }
                })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  );
};

export default TotalPackage;
