/* eslint-disable no-undef */
import { Avatar, Button, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import useUserId from "hooks/useUserId";
import axiosusers from "utils/api/axiosusers";
import { Tabtitle } from "components/title/Title";
const EditGame = () => {
  Tabtitle("Update Gift");
  let navigate = useNavigate();
  const classes = useStyles();
  const [image, setImage] = useState();
  const [avatar, setAvatar] = useState();

  const { userId } = useUserId();
  useEffect(() => {
    if (userId) {
      if (userId.role !== "Admin") {
        navigate("/login");
      }
    }
  }, [userId]);

  //const [errorMessage, seterrorMessage] = React.useState(false);

  const { id } = useParams();
  const [input, setInput] = useState({
    amount: " ",
    no: " ",
  });
  const getProduct = async () => {
    const data = await axiosusers.get(`/giftcard/giftcard/${id}`);
    setInput(data.data);
  };

  useEffect(() => {
    getProduct();
  }, [id]);
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    setImage(file);

    file.preview = URL.createObjectURL(file);
    setAvatar(file);
  };
  const initialValues = {
    Game: null,
  };

  const validationSchema = Yup.object().shape({});
  const onSubmit = async () => {
    let formData = new FormData();
    formData.append("amount", input.amount);
    formData.append("no", input.no);
    formData.append("id", id);
    formData.append("file", image);
    try {
      const response = await axiosusers.post(`/uploads/update-gift`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);

      if (response.status === 200) {
        navigate("/app/gift-home/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className={classes.paper} elevation={0}>
        <div>
          <Typography className={classes.name}>Update Gift</Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form autoComplete="off">
                <TextField
                  onChange={handleinput}
                  type="text"
                  value={input.no}
                  name="no"
                  error={Boolean(input.no === "")}
                  helperText={input.no === "" ? "No is required" : ""}
                  label="No"
                  size="small"
                  variant="outlined"
                  fullWidth
                />

                <TextField
                  onChange={handleinput}
                  type="text"
                  className={classes.fontText}
                  value={input.amount}
                  name="amount"
                  error={Boolean(input.amount === "")}
                  helperText={input.amount === "" ? "Price is required" : ""}
                  label="Price"
                  size="small"
                  variant="outlined"
                  fullWidth
                />

                {avatar && (
                  <Avatar
                    src={avatar.preview}
                    variant="square"
                    className={classes.large}
                  />
                )}
                {!avatar && input.image !== "" && (
                  <img
                    src={`${process.env.REACT_APP_NOT_SECRET_CODE}images/${input.image}`}
                    crossOrigin="anonymous"
                    className={classes.large}
                  />
                )}
                <br />
                <input
                  accept="image/*"
                  className={classes.input}
                  onChange={handleFile}
                  id="file"
                  name="avatar"
                  multiple
                  type="file"
                />
                <label htmlFor="file" className={classes.fontText}>
                  <Button component="span" color="primary">
                    UPLOAD PHOTOS
                  </Button>
                </label>

                {/* {errorMessage.length > 0 && <Alert severity="error" className={classes.errortext}>{errorMessage}</Alert>	}           */}

                <Button
                  type="submit"
                  className={classes.button}
                  variant="contained"
                  size="large"
                  fullWidth
                  color="primary"
                >
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditGame;

const useStyles = makeStyles((theme) => ({
  errortext: {
    //width:'50%',
    textAlign: "center",
    margin: theme.spacing(1, 0),
  },
  fontText: {
    margin: theme.spacing(1, 0),
  },
  paper: {
    maxWidth: 600,
    margin: `auto`,
    backgroundColor: `#fafafa`,
  },
  select: {
    fontWeight: 405,
    color: "rgba(0, 0, 0, 0.59)",
  },
  name: {
    flexGrow: 1,
    fontWeight: 500,
    fontSize: "30px",
    margin: theme.spacing(0, 0, 3, 0),
  },
  formControl: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)", // thay đổi màu sắc ở đây
    },
  },
  button: {
    margin: theme.spacing(5, 0),
  },
  input: {
    display: "none",
    color: "#3f50b5",
    margin: theme.spacing(2, 0),
  },
  large: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    margin: theme.spacing(2, 0),
  },
}));
