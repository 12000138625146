import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";

import { FormAutocompleteClient } from "hooks/FormAutocomplete";
import { useNavigate } from "react-router-dom";

const AddTransactions = (props) => {
  const { clientsApi, paymentsApi } = props;
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [clients, setClients] = React.useState([]);
  const [amount, setAmount] = React.useState("");
  const [payments, setPayments] = React.useState([]);
  const date = new Date();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0 nên cần cộng 1
  const year = date.getFullYear();

  const formattedDate = `${hours}:${minutes}:${seconds}-${day}/${month}/${year}`;

  const [input, setInput] = React.useState({
    type: "",
    currency: "",
    status: "",
  });
  const initialValues = {
    code: "",

    Payment: null,
    Client: null,
  };
  const getProductData = async () => {
    setClients(clientsApi);

    const filteredProducts = paymentsApi.filter(
      (product) => product.status != "limit-180-day"
    );

    setPayments(filteredProducts);
  };

  React.useEffect(() => {
    getProductData();
  }, [clientsApi, paymentsApi]);
  const validationSchema = Yup.object().shape({
    code: Yup.string().required("Code is required"),
    Payment: Yup.object().required("Payment is required"),
    Client: Yup.object().required("Client is required"),
  });
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async (values) => {
    setDung(true);
    const amounts = input.currency == "VND" ? amount : Number(amount) * 22200;
    if (amounts > 1000000000) {
      setDung(false);
      return seterrorMessage(
        `The amount is too large. The amount cannot exceed one billion. Please review it.`
      );
    }
    const dataBudgets = {
      code: values.code.replace(/\s/g, ""),
      paymentId: values.Payment.id,
      clientId: values.Client.id,
      amount: amounts,
      remainAmount: amounts,
      status: input.status,
      currency: input.currency,
    };
    try {
      await axiosusers.post(`/transactions/create-transactions`, dataBudgets);
      navigate("/app/transactions/");
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
    }
  };
  const [touchedFields, setTouchedFields] = React.useState({
    amount: false,
  });
  const [errors, setErrors] = React.useState({
    amount: "Amount is required.",
  });
  const handleFieldChange = (field, value) => {
    if (value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "Amount is required.",
      }));
    } else if (field === "amount" && parseFloat(value) <= 0.00000001) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "Withdraw amount must be greater than zero.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: value ? "" : prevErrors[field],
      }));
    }
  };

  const handleFieldBlur = (field) => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [field]: true,
    }));
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const numberthem = new Intl.NumberFormat("en-NZ", {
    minimumFractionDigits: 2,
  });
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
          }}
        >
          Create new transactions
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <FormAutocompleteClient
                style={{ marginTop: `1.5rem` }}
                label="Client"
                name="Client"
                options={clients}
              />
              <FormAutocompleteClient
                style={{ marginTop: `0.5rem` }}
                label="Payment"
                name="Payment"
                options={payments}
              />
              <Tooltip
                title={"Copy"}
                placement="top"
                onClick={() => {
                  navigator.clipboard.writeText(`${formattedDate}`);
                }}
                arrow
              >
                <Button
                  sx={{ marginTop: `0.5rem` }}
                  variant="outlined"
                  size="small"
                >
                  {formattedDate}
                </Button>
              </Tooltip>
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="code"
                label="Code"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.code && props.touched.code}
                helperText={<ErrorMessage name="code" />}
              />
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                sx={{ marginTop: `0.5rem` }}
                error={Boolean(
                  errorMessage.length > 0 && input.currency == "1"
                )}
              >
                <InputLabel>Select currency</InputLabel>
                <Select
                  value={input.currency}
                  onChange={handleChange}
                  label="Select currency"
                  name="currency"
                >
                  <MenuItem value="VND">VND</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
                {errorMessage.length > 0 && input.currency == "1" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>

              <TextField
                sx={{ marginTop: `0.5rem` }}
                size="small"
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                type="text"
                fullWidth
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                  handleFieldChange("amount", e.target.value);
                }}
                onBlur={() => handleFieldBlur("amount")}
                error={touchedFields.amount && !!errors.amount}
                helperText={touchedFields.amount && errors.amount}
              />
              {amount !== "" && (
                <Typography variant="subtitle1" sx={{ marginLeft: "10px" }}>
                  {input.currency == "VND"
                    ? number.format(amount) + ` ₫`
                    : numberthem.format(Math.round(amount * 100) / 100) + ` $`}
                </Typography>
              )}
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                sx={{ marginTop: `0.5rem` }}
                error={Boolean(errorMessage.length > 0 && input.status == "1")}
              >
                <InputLabel>Transactins status</InputLabel>
                <Select
                  value={input.status}
                  onChange={handleChange}
                  label="Transactins status"
                  name="status"
                >
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="on_hold">On hold</MenuItem>
                  <MenuItem value="on_hold_7day">On hold 7 day</MenuItem>
                </Select>
                {errorMessage.length > 0 && input.status == "1" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>

              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddTransactions;
