import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, Grid } from "@mui/material";
import useDecodedToken from "utils/token/useDecodedToken";

export default function AccordionsGames(props) {
  const { wallets, budgets } = props;
  const { decodedToken } = useDecodedToken();
  const number = new Intl.NumberFormat("en-US");

  const walletsBudgets = wallets.map((item) => {
    const budgetsWallets = budgets.filter(
      (budget) => budget.walletId === item.walletId
    );
    return {
      ...item,
      budgets: budgetsWallets,
    };
  });

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography
            variant="subtitle2"
            color="primary"
            sx={{
              fontSize: (theme) => theme.typography.pxToRem(20),
              fontWeight: 500,
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            Wallets detail
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {walletsBudgets.map((item, keyId) => {
            return (
              <div key={keyId}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <Typography
                      variant="h6"
                      color="primary"
                      sx={{
                        color: "#3f51b5",
                        fontWeight: 500,
                        fontSize: "1rem",
                      }}
                    >
                      {keyId == 0 ? "Wallets:" : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="subtitle"
                      color="primary"
                      sx={{
                        color: "#3f51b5",
                        fontWeight: 500,
                        fontSize: "1rem",
                        marginLeft: "5px",
                      }}
                    >
                      Wallet name: {item.walletsName}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">
                      Remain packages: {item.packages}
                    </Typography>
                    {decodedToken?.role == "Admin" && (
                      <Typography variant="subtitle1">
                        Price: {number.format(item.walletsPrice) + " ₫"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={2}>
                    <Typography variant="h5" color="primary"></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">Budget name</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">Remain quantity</Typography>
                  </Grid>

                  {decodedToken?.role == "Admin" && (
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">Price</Typography>
                    </Grid>
                  )}
                </Grid>

                {item.budgets.map((budget, keyBudget) => {
                  return (
                    <div key={keyBudget}>
                      <Grid container spacing={5} key={keyBudget}>
                        <Grid item xs={2}>
                          <Typography variant="h5" color="primary"></Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="subtitle1">
                            {budget.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="subtitle1">
                            {budget.budgetsQuantity}
                          </Typography>
                        </Grid>

                        {decodedToken?.role == "Admin" && (
                          <Grid item xs={3}>
                            <Typography variant="subtitle1">
                              {number.format(budget.budgetsPrice) + " ₫"}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  );
                })}
                <Grid container spacing={0}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    <Divider
                      style={{ backgroundColor: "#000", margin: "20px 0px" }}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
