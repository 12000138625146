import { useEffect, useState } from "react";
import axiosusers from "utils/api/axiosusers";
import useDecodedToken from "utils/token/useDecodedToken";

const useUserId = () => {
  const { decodedToken } = useDecodedToken();
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientIdResponse] = await Promise.all([
          axiosusers.get(`/usersLamlainao/profile/${decodedToken?.id}`),
        ]);
        const clientId = clientIdResponse.data;

        setUserId(clientId);
      } catch (error) {
        const [clientIdResponse] = await Promise.all([
          axiosusers.get(`/usersLamlainao/profile/${decodedToken?.id}`),
        ]);
        const clientId = clientIdResponse.data;

        setUserId(clientId);
      }
    };

    if (decodedToken?.id) {
      fetchData();
    }
  }, [decodedToken]);

  return { userId };
};

export default useUserId;
