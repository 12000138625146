import React, { useEffect, useRef, useState } from "react";

import axiosusers from "utils/api/axiosusers";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CardMedia } from "@material-ui/core";
import useLinks from "hooks/useLinks";

import styles from "./style.module.css";

import { NavLink } from "react-router-dom";
import CartsProducts from "./CartsProducts";

const SanPham = (props) => {
  const { data } = props;

  const idProductRef = useRef();
  const idProductRefpage = useRef();
  const idProductRef_name = useRef();
  const linkImage = useLinks();
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState([]);
  const [selectSanpham, setSelectSanpham] = useState([]);

  useEffect(() => {
    let products = data;
    if (selectSanpham?.id !== undefined) {
      products = products.filter((item) => item.id === selectSanpham.id);
    }
    setProduct(products);
  }, [data, selectSanpham]);

  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
      status: idProductRefpage.current,
    };
    try {
      const response = await axiosusers.patch(
        `/sanpham/delete-sanpham`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        setProduct(data.filter((item) => item.id !== idProductRef.current));
      }
    } catch (error) {
      console.log(error);
    }

    setOpen(false);
  };
  const handleClickOpen = (id, page, name) => {
    setOpen(true);

    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <NavLink to="/sanpham/add" className={styles.link}>
        <Button variant="contained" color="primary">
          ADD San Pham
        </Button>
      </NavLink>

      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Autocomplete
                options={data}
                fullWidth
                onChange={(event, value) => setSelectSanpham(value)}
                getOptionLabel={(users) => users.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select an san pham"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <CartsProducts />
      <Grid container spacing={3}>
        {product.map((item) => (
          <Grid item key={item.id} lg={3} md={6} sm={12} xs={12}>
            <Card>
              <CardMedia
                className={styles.img}
                component="img"
                height="194"
                image={`${linkImage + item.image}`}
                crossOrigin="anonymous"
              />
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  fontSize: (theme) => theme.typography.pxToRem(20),
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(2),
                }}
              >
                Tên: {item.name}
              </Typography>
              <Divider />
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: (theme) => theme.typography.pxToRem(16),
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(1),
                }}
              >
                Giá bán: {number.format(item.price)} đ
              </Typography>
              <Divider />
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: (theme) => theme.typography.pxToRem(16),
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(1),
                }}
              >
                Notes: {item.notes}
              </Typography>
              <Divider />
              <Box
                sx={{
                  padding: (theme) => theme.spacing(1),
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <NavLink to={`/sanpham/edit/${item.id}`}>
                  <IconButton color="primary">
                    <EditIcon />
                  </IconButton>
                </NavLink>

                <IconButton
                  color="primary"
                  onClick={() => handleClickOpen(item.id, "delete")}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {idProductRefpage.current == "delete"
              ? "WARNING: If you delete this Game, all Wallets, Products and Orders which was created from this game will be deleted too. Are you sure?"
              : "Are you sure to turn positive?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SanPham;
