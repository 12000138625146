import {
  AppBar,
  Badge,
  Dialog,
  Fab,
  IconButton,
  Slide,
  Toolbar,
} from "@mui/material";
import React from "react";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import CloseIcon from "@mui/icons-material/Close";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CartsProducts = () => {
  const onClieck = () => {
    setOpen(true);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ margin: "40px auto" }}>
      <Fab
        size="large"
        aria-label="scroll back to top"
        sx={{
          background: "#fff",
          position: "fixed",
          top: 120,
          right: { xs: 20, sm: 180 },
          margin: "120px auto",
        }}
        onClick={() => onClieck()}
      >
        <Badge badgeContent={1} color="error">
          <LocalGroceryStoreIcon />
        </Badge>
      </Fab>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        dsadsadsa
      </Dialog>
    </div>
  );
};

export default CartsProducts;
