import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import axiosusers from "utils/api/axiosusers";
import { useNavigate, useParams } from "react-router-dom";

const EditGroups = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [input, setInput] = useState({
    type: "",
    groups: "",
    status: "",
    no: "",
    name: "",
    link: "",
    notes: "",
    numberLike: "0",
  });
  const [errorMessage, seterrorMessage] = React.useState(false);

  const [Administrators, setAdministrators] = useState([]);

  const [fieldValueWallet, setFieldValueWallet] = useState([]);
  const getProductData = async () => {
    const data = await axiosusers.get(`/facebook-manage/managefacebook/${id}`);
    const administrators = await axiosusers.get(
      `/facebook-manage/administrators`
    );
    setAdministrators(administrators.data);
    const administratorfacebook = await axiosusers.get(
      `/facebook-manage/administratorfacebook`
    );
    setInput(data.data);

    const filteredData = administratorfacebook.data.filter(
      (product) =>
        product.facebookId === Number(id) && product.status === "Groups"
    );
    const initialSelectedOptions = administrators.data.filter((item) =>
      filteredData.some(
        (selectedItem) => selectedItem.administratorId === item.id
      )
    );

    const extractedIds = initialSelectedOptions.map((item) => ({
      name: item.name,
      id: item.id,
    }));
    setFieldValueWallet(extractedIds);
  };

  useEffect(() => {
    getProductData();
  }, [id]);

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    if (input.groups === "") {
      input.groups = "1";
      return seterrorMessage("The groups not found.");
    }
    const data = {
      id,
      no: input.no,
      name: input.name,
      groups: input.groups,
      numberLike: input.numberLike,
      link: input.link,
      notes: input.notes,
      fieldValueWallet,
    };
    try {
      const res = await axiosusers.post(
        `/facebook-manage/update-managefacebook`,
        data
      );
      console.log(res);
      navigate("/app/facebook-manage");
    } catch (error) {
      console.log(error);
    }
  };
  const setChangeWallet = async () => {};
  const filteredGame = Administrators.filter(
    (item) =>
      !fieldValueWallet.some((selectedItem) => selectedItem.id === item.id)
  );
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Update groups
        </Typography>
        <Autocomplete
          size="small"
          multiple
          limitTags={3}
          id="multiple-limit-tags"
          options={filteredGame}
          value={fieldValueWallet}
          filterSelectedOptions
          onChange={(event, values) => {
            setFieldValueWallet(values);
            setChangeWallet(values);
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(games) => games.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Administrator"
              placeholder="Favorites"
            />
          )}
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="number"
          value={input.no}
          name="no"
          error={Boolean(input.no === "")}
          helperText={input.no === "" ? ".No is required" : ""}
          label=".No"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.name}
          name="name"
          error={Boolean(input.name === "")}
          helperText={input.name === "" ? "Name is required" : ""}
          label="Name"
          size="small"
          variant="outlined"
          fullWidth
        />
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          sx={{ marginTop: `0.5rem` }}
          error={Boolean(errorMessage.length > 0 && input.groups == "1")}
        >
          <InputLabel>Select Group</InputLabel>
          <Select
            value={input.groups}
            onChange={handleinput}
            label="Select Group"
            name="groups"
          >
            <MenuItem value="Group">Group</MenuItem>
            <MenuItem value="Fanpage">Fanpage</MenuItem>
          </Select>
          {errorMessage.length > 0 && input.groups == "1" && (
            <FormHelperText>{errorMessage}</FormHelperText>
          )}
        </FormControl>
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.numberLike}
          name="numberLike"
          label="Number of memes"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.link}
          name="link"
          label="Link"
          size="small"
          variant="outlined"
          fullWidth
        />

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          multiline
          minRows="4"
          type="text"
          value={input.notes}
          name="notes"
          label="Notes"
          size="small"
          variant="outlined"
          fullWidth
        />

        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default EditGroups;
