import React from "react";
import AddWallets from "./AddWallets";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Create Wallets");
  return (
    <div>
      <AddWallets />
    </div>
  );
};

export default index;
