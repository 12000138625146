import React, { useEffect, useState } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";

import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";

const EditClientYoutube = (props) => {
  const { id } = props;

  const navigate = useNavigate();
  const [input, setInput] = useState({
    email: " ",
    fullName: " ",
    faceBookName: " ",
    phoneNumber: " ",
    address: " ",
    emailPaypal: " ",
  });
  const getProduct = async () => {
    const data = await axiosusers.get(`/clients/clientsyoutube/${id}`);
    setInput(data.data);
  };

  useEffect(() => {
    getProduct();
  }, [id]);

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    const data = {
      id,
      fullName: input.fullName,
      faceBookName: input.faceBookName,
      email: input.email,
      phoneNumber: input.phoneNumber,
      address: input.address,
      emailPaypal: input.emailPaypal,
    };
    try {
      const res = await axiosusers.post(
        `/clients/update-clients-youtube`,
        data
      );
      console.log(res);
      navigate("/app/client-youtube");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Update clients youtube
        </Typography>

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.fullName}
          name="fullName"
          error={Boolean(input.fullName === "")}
          helperText={input.fullName === "" ? "Name is required" : ""}
          label="Name"
          size="small"
          variant="outlined"
          fullWidth
        />

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.email}
          name="email"
          label="Email"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.faceBookName}
          name="faceBookName"
          label="FaceBook Name"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.phoneNumber}
          name="phoneNumber"
          label="Phone Number"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.address}
          name="address"
          label="Address"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.emailPaypal}
          name="emailPaypal"
          label="Email Paypal"
          size="small"
          variant="outlined"
          fullWidth
        />
        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default EditClientYoutube;
