import React from "react";
import { Tabtitle } from "components/title/Title";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";

import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";

const Index = () => {
  Tabtitle("Create san pham");

  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);

  const [dung, setDung] = React.useState(false);

  const initialValues = {
    name: "",
    price: "",
    notes: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(0, "It's too short").required("Name is required"),
    price: Yup.string().min(0, "It's too short").required("Price is required"),
  });

  const [image, setImage] = React.useState(null);
  const [avatar, setAvatar] = React.useState(null);

  const handleFile = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setAvatar({ preview: URL.createObjectURL(file) });
  };
  const onSubmit = async (values) => {
    setDung(true);
    seterrorMessage("");

    const formData = new FormData();
    const datatime = new Date().getTime();
    formData.append("name", values.name);
    formData.append("notes", values.notes);
    formData.append("price", values.price);
    formData.append("file", image);
    formData.append("datatime", datatime);

    try {
      const response = await axiosusers.post(
        `/sanpham/create-sanpham`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      setDung(false);

      if (response.status === 200) {
        navigate("/sanpham/");
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(error.response.data.message);
      setDung(false);
    }
  };

  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
          }}
        >
          Create new Sanpham
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                sx={{ marginTop: `1rem` }}
                as={TextField}
                name="name"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                // eslint-disable-next-line react/prop-types
                error={props.errors.name && props.touched.name}
                helperText={<ErrorMessage name="name" />}
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                type="number"
                name="price"
                label="Price"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.price && props.touched.price}
                helperText={<ErrorMessage name="price" />}
              />

              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="notes"
                label="notes"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                rows={4}
              />

              {avatar && (
                <Avatar
                  src={avatar.preview}
                  variant="square"
                  sx={{
                    width: (theme) => theme.spacing(25),
                    height: (theme) => theme.spacing(25),
                    margin: (theme) => theme.spacing(2, 0),
                  }}
                />
              )}
              <input
                accept="image/*"
                style={{
                  display: "none",
                  color: "#3f50b5",
                  margin: (theme) => theme.spacing(2, 0),
                }}
                onChange={handleFile}
                id="file"
                name="file"
                multiple
                type="file"
              />
              <label htmlFor="file">
                <Button
                  component="span"
                  sx={{ marginTop: `1rem` }}
                  color="primary"
                >
                  UPLOAD PHOTOS
                </Button>
              </label>

              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default Index;
