import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";

const EditPayments = (props) => {
  const { id } = props;
  const navigate = useNavigate();

  const [input, setInput] = useState({
    orderNumber: " ",
    owner: " ",
    bank: " ",
    balance: " ",
    onHoldBalance: " ",
    notes: " ",
    type: " ",
    status: " ",
    currency: " ",
    password: " ",
    phoneNumber: " ",
    vps: " ",
  });

  useEffect(() => {
    Promise.all([axiosusers.get(`/payments/payments/${id}`)]).then(
      async (results) => {
        const [clienId] = results.map((res) => res.data);
        setInput(clienId);
      }
    );
  }, [id]);

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    const data = {
      id,
      orderNumber: parseInt(input.orderNumber),
      owner: input.owner,
      bank: input.bank,
      balance: parseInt(input.balance),
      onHoldBalance: parseInt(input.onHoldBalance),
      notes: input.notes,
      type: input.type,
      status: input.status,
      currency: input.currency,
      password: input.password,
      phoneNumber: input.phoneNumber,
      vps: input.vps,
    };
    try {
      const res = await axiosusers.patch(`/payments/update-payments`, data);
      console.log(res);
      navigate("/app/payments");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Update payment
        </Typography>

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.orderNumber}
          name="orderNumber"
          error={Boolean(input.orderNumber === "")}
          helperText={input.orderNumber === "" ? "Payment .No is required" : ""}
          label="Payment .No"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          multiline
          minRows="2"
          type="text"
          value={input.owner}
          name="owner"
          label="Payment Owner"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.password}
          name="password"
          label="Password"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.bank}
          name="bank"
          label="Bank Name"
          size="small"
          variant="outlined"
          fullWidth
        />
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          sx={{ marginTop: `0.5rem` }}
        >
          <InputLabel>Select type</InputLabel>
          <Select
            value={input.type}
            onChange={handleinput}
            label="Select type"
            name="type"
          >
            <MenuItem value="Paypal">Paypal</MenuItem>
            <MenuItem value="Stripe">Stripe</MenuItem>
            <MenuItem value="Momo">Momo</MenuItem>
            <MenuItem value="Airpay">Airpay</MenuItem>
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="BTC">BTC</MenuItem>
            <MenuItem value="BankTransfer">BankTransfer</MenuItem>
            <MenuItem value="Skrill">Skrill</MenuItem>
            <MenuItem value="TransferWise">TransferWise</MenuItem>
            <MenuItem value="WebMoney">WebMoney</MenuItem>
            <MenuItem value="PayOneer">PayOneer</MenuItem>
            <MenuItem value="PingPong">PingPong</MenuItem>
            <MenuItem value="USDT">USDT</MenuItem>
            <MenuItem value="ETH">ETH</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          sx={{ marginTop: `0.5rem` }}
        >
          <InputLabel>Select status</InputLabel>
          <Select
            value={input.status}
            onChange={handleinput}
            label="Select status"
            name="status"
          >
            <MenuItem value="trustSeller">Trust seller</MenuItem>
            <MenuItem value="limit">Limit</MenuItem>
            <MenuItem value="disable">Disable</MenuItem>
            <MenuItem value="strong">Strong</MenuItem>
            <MenuItem value="weak">Weak</MenuItem>
            <MenuItem value="openCase">Open case</MenuItem>
            <MenuItem value="limit_180_day">Limit 180 day</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          sx={{ marginTop: `0.5rem` }}
        >
          <InputLabel>Select currency</InputLabel>
          <Select
            value={input.currency}
            onChange={handleinput}
            label="Select currency"
            name="currency"
          >
            <MenuItem value="VND">VND</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{ marginTop: `0.5rem` }}
          type="number"
          onChange={handleinput}
          value={input.balance}
          name="balance"
          label="Balance"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          type="number"
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          value={input.onHoldBalance}
          name="onHoldBalance"
          label="On Hold Balance"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          value={input.vps}
          name="vps"
          label="vps"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          value={input.phoneNumber}
          name="phoneNumber"
          label="Phone Nunber"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          multiline
          minRows="4"
          type="text"
          value={input.notes}
          name="notes"
          label="Notes"
          size="small"
          variant="outlined"
          fullWidth
        />

        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default EditPayments;
