/* eslint-disable react/prop-types */
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Typography } from "@material-ui/core";
import React from "react";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ShopIcon from "@mui/icons-material/Shop";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BadgeIcon from "@mui/icons-material/Badge";
import LanguageIcon from "@mui/icons-material/Language";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import BlockIcon from "@mui/icons-material/Block";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import MoneyIcon from "@mui/icons-material/Money";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import GifBoxIcon from "@mui/icons-material/GifBox";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PersonIcon from "@mui/icons-material/Person";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { NavLink } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
const MenuItems = (props) => {
  const { data } = props;
  const classes = useStyles();

  const menuItems = [
    { path: "/app/reports", icon: <AssessmentIcon />, label: "Reports" },
    { path: "/app/games", icon: <SportsEsportsIcon />, label: "Games-Top up" },
    { path: "/app/giftcards", icon: <CardGiftcardIcon />, label: "Gift Cards" },
    {
      path: "/app/soft-ware",
      icon: <ShopIcon />,
      label: "Software Account",
      data: "Admin",
    },
    {
      path: "/app/games-account",
      icon: <BadgeIcon />,
      label: "Games Account",
      data: "Admin",
    },
    {
      path: "/app/skin-item-general",
      icon: <CategoryIcon />,
      label: "Skin Item General",
      data: "Admin",
    },
    {
      path: "/app/friends-game-id",
      icon: <GroupAddIcon />,
      label: "Friends game ID",
    },
    {
      path: "/app/countrygiftcard",
      icon: <LanguageIcon />,
      label: "Country Gift Card",
      data: "Admin",
    },

    { path: "/app/wallets", icon: <CreditCardIcon />, label: "Wallets" },
    {
      path: "/app/primary-channel",
      icon: <VideoSettingsIcon />,
      label: "Primary Channel",
      data: "Admin",
    },
    {
      path: "/app/clients",
      icon: <SupervisedUserCircleIcon />,
      label: "Clients",
    },
    { path: "/app/blacklists", icon: <BlockIcon />, label: "Blacklists" },
    { path: "/app/blocks", icon: <RemoveCircleIcon />, label: "Blocks" },
    { path: "/app/payments", icon: <AccountBalanceIcon />, label: "Payments" },
    {
      path: "/app/paypal-180-days",
      icon: <CreditCardOffIcon />,
      label: "Paypal 180 Days",
      data: "Admin",
    },
    { path: "/app/transactions", icon: <MoneyIcon />, label: "Transactions" },
    { path: "/app/orders", icon: <ShoppingCartIcon />, label: "Orders" },
    {
      path: "/app/order-agencys",
      icon: <ShoppingCartCheckoutIcon />,
      label: "Order Agencys",
    },
    { path: "/app/gift-home", icon: <GifBoxIcon />, label: "Gift home" },
    {
      path: "/app/facebook-manage",
      icon: <FacebookIcon />,
      label: "Facebook Manage",
    },
    { path: "/app/users", icon: <PersonIcon />, label: "Users", data: "Admin" },

    {
      path: "/app/client-youtube",
      icon: <YouTubeIcon />,
      label: "Youtube",
      data: "Admin",
    },
    {
      path: "/app/product-category",
      icon: <AddCircleIcon />,
      label: "Product Category",
      data: "Admin",
    },
    {
      path: "/app/advertise-fee",
      icon: <LocalAtmIcon />,
      label: "Advertise Fee",
      data: "Admin",
    },
  ];

  const handleNavLinkClick = async () => {
    // if (!localStorage.getItem("data")) {
    //   window.location = "/login";
    // }
  };

  return (
    <div className={classes.roott}>
      <List>
        {menuItems.flatMap(
          (menuItem) =>
            (!menuItem.data || menuItem.data === data?.role) && (
              <NavLink
                onClick={() => handleNavLinkClick(menuItem.path)}
                key={menuItem.path}
                to={menuItem.path}
                className={classes.link}
              >
                <ListItem button className={classes.listitem}>
                  <ListItemIcon className={classes.item}>
                    {menuItem.icon}
                  </ListItemIcon>
                  <Typography className={classes.text}>
                    {menuItem.label}
                  </Typography>
                </ListItem>
              </NavLink>
            )
        )}
      </List>
    </div>
  );
};

export default MenuItems;
const useStyles = makeStyles((theme) => ({
  textname: {
    fontWeight: 500,
    color: "#546e7a",
    fontSize: "16px",
    padding: theme.spacing(0, 0, 2, 0),
  },
  textname2: {
    fontWeight: 500,
    fontSize: "20px",
  },
  avtconter: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2, 0, 1, 0),
    alignItems: "center",
    // padding: theme.spacing(2, 10),
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  textconter: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },

  imgavatar: {
    cursor: "pointer",
  },
  colorToolbar: {
    backgroundColor: "#3f50b5",
    borderRight: `1px solid #3f50b5`,
  },
  roott: {
    width: "95%",
    color: "#546e7a",
    padding: theme.spacing(0, 0, 0, 2),
    maxWidth: 360,
    backgroundColor: theme.palette.background.hover,
  },
  item: {
    color: "inherit",
    borderRadius: "20px",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    "&.active > div": {
      color: theme.palette.primary.main,
    },
  },
  text: {
    fontWeight: 500,
    marginLeft: "-25px",
    padding: "20xp",
    fontSize: "15px",
  },
  listitem: {
    padding: "10px 8px",
    borderRadius: "4px",
    fontSize: "16px",
  },
}));
