import React, { useEffect, useRef, useState } from "react";

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import axiosusers from "utils/api/axiosusers";
import { useNavigate, useParams } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const EditAgencys = () => {
  const { id } = useParams();
  Tabtitle("Update Agencys");
  const navigate = useNavigate();
  const idProductRef = useRef();
  const idProductRefW = useRef();
  const idProductRefWName = useRef();
  const idProductRefWallowBudget = useRef();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [games, setGames] = useState([]);
  const [fieldValueWallet, setFieldValueWallet] = useState([]);
  const [fieldValueWallet2, setFieldValueWallet2] = useState([]);
  const [jsonWallets2, setJsonWallets2] = useState([]);
  const [fieldValueWallet3, setJsonWallets3] = useState([]);
  const [jsonBudgets, setJsonBudgets] = useState([]);
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [budgetagencys, setBudgetAgencys] = useState([]);
  const [, setInputBudget] = useState([]);
  const [alowBudget, setAlowbudget] = useState([]);
  const [checked, setChecked] = useState(false);
  const handleChangeChecked = () => {
    setOpen2(true);
    // setChecked(event.target.checked);
  };
  const [input, setInput] = useState({
    nameGroup: " ",
    teleId: " ",
    notes: " ",
    status: " ",
  });

  const fetchdata = async () => {
    Promise.all([
      axiosusers.get(`/agencys/agencys/${id}`),
      axiosusers.get(`/games/games`),
      axiosusers.get(`/wallets/wallets`),
      axiosusers.get(`/agencys/budgets-agencys/${id}`),
      axiosusers.get(`/budgets/budgets`),
      axiosusers.get(`/agencys/agencys-wallets/${id}`),
      axiosusers.get(`/agencys/agencygames/${id}`),
    ]).then(async (results) => {
      const [
        clienId,
        games,
        wallets,
        budgetagencys,
        budgets,
        allowBudget,
        agencygames,
      ] = results.map((res) => res.data);
      setChecked(clienId.currentPrice);
      const filteredData = agencygames.filter(
        (product) => product.agencyId == id
      );

      const gamesName = games.filter((item) =>
        filteredData.some((selectedItem) => item.id === selectedItem.gameId)
      );

      const initialSelectedOptions = gamesName.filter((item) =>
        wallets.some((selectedItem) => selectedItem.gameId === item.id)
      );
      const extractedIds = initialSelectedOptions.map((item) => ({
        name: item.name,
        id: item.id,
      }));

      const jsonW = wallets.filter((item) =>
        extractedIds.some((selectedItem) => selectedItem.id === item.gameId)
      );

      const jsonW2 = jsonW.map((item) => {
        const gamesName2 = gamesName.find((row) => row.id === item.gameId);
        return {
          ...item,
          namegame: gamesName2?.name,
        };
      });

      setFieldValueWallet(gamesName);
      setInput(clienId);
      setGames(games);

      setJsonWallets2(jsonW2);
      setJsonWallets3(wallets);

      setBudgetAgencys(budgetagencys);
      setJsonBudgets(budgets);

      const allowBudgets = allowBudget.map((item) => {
        const walletsId = wallets.find((row) => row.id === item.walletId);

        return {
          ...item,
          gameId: walletsId?.gameId,
        };
      });

      const agencygames2 = agencygames.map((item) => {
        const budgetGame = allowBudgets.filter(
          (row) => row.gameId === item.gameId
        );
        const gamess = games.find((row) => row.id === item?.gameId);
        return {
          ...item,
          budgetGame,
          nameGame: gamess?.name,
        };
      });
      setAlowbudget(agencygames2);
    });
  };

  useEffect(() => {
    fetchdata();
  }, [id]);
  const filteredGame = games.filter(
    (item) =>
      !fieldValueWallet.some((selectedItem) => selectedItem.id === item.id)
  );
  const setChangeWallet = async (value) => {
    if (value.length > 0) {
      const jsonWalletsValue = fieldValueWallet3.filter((budget) => {
        return value.some((wallet) => wallet.id == budget.gameId);
      });
      setJsonWallets2(jsonWalletsValue);
    } else {
      const jsonWalletsValue = fieldValueWallet3;
      setJsonWallets2(jsonWalletsValue);
    }
  };
  const setChangeWallet2 = async () => {};
  const filteredAgencys = jsonBudgets.filter((agency) => {
    return fieldValueWallet2.some((wallet) => wallet.id == agency.walletId);
  });
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    let errorMessage = "";
    filteredAgencys.forEach((item) => {
      if (item.usedQuantity == "0" || item.usedQuantity == "") {
        errorMessage = "The data budgets invalid. Please check again";
      }
    });
    if (errorMessage !== "") {
      return seterrorMessage(errorMessage);
    }
    const dataBudgets = {
      id,
      teleId: input.teleId,
      nameGroup: input.nameGroup,
      status: input.status,
      notes: input.notes,
      agencysgames: fieldValueWallet,
      agencyswallets: fieldValueWallet2,
      budgetAgencys: filteredAgencys,
      currentPrice: checked,
    };

    try {
      const resbudgets = await axiosusers.patch(
        `/agencys/update-agencys`,
        dataBudgets
      );
      console.log(resbudgets);
      navigate("/app/order-agencys");
    } catch (error) {
      console.log(error);
      seterrorMessage(error?.response?.data?.message);
    }
  };

  const handleChangeInputWallet = (id, event) => {
    const newInputFields = fieldValueWallet2.map((w) => {
      if (id === w.id) {
        w[event.target.name] = event.target.checked;
      }
      return w;
    });

    setFieldValueWallet2(newInputFields);
  };
  const handleChangeInputBudget = (id, event) => {
    const newjsonBudgets = filteredAgencys.map((w) => {
      if (id === w.id) {
        w[event.target.name] = event.target.value;
      }
      return w;
    });
    setInputBudget(newjsonBudgets);
  };

  const DeleteWallet = (idagencywallets, idWallet, name, allowBudget) => {
    setOpen(true);
    idProductRef.current = idagencywallets;
    idProductRefW.current = idWallet;
    idProductRefWName.current = name;
    idProductRefWallowBudget.current = allowBudget;
  };
  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };
  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
      walletId: idProductRefW.current,
      name: idProductRefWName.current,
      agencyId: id,
      allowBudget: idProductRefWallowBudget.current === 1 ? 0 : 1,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes/delete-agencydelete-wallet-alowbudget`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        fetchdata();
      }
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
  };

  let number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const handleCurrentPrice = async () => {
    const dataBudgets = {
      id,
      teleId: input.teleId,
      nameGroup: input.nameGroup,
      status: input.status,
      notes: input.notes,
      currentPrice: checked ? false : true,
    };

    try {
      await axiosusers.patch(
        `/agencys/update-agencys-currentPrice`,
        dataBudgets
      );
      setOpen2(false);
      setChecked(checked ? false : true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Update agency
        </Typography>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{ marginTop: `0.5rem` }}
          size="small"
        >
          <InputLabel>Select Product</InputLabel>
          <Select
            value={input.statusGame}
            onChange={handleinput}
            label="Select Product"
            name="statusGame"
          >
            <MenuItem value="game">Games-Top up</MenuItem>
            <MenuItem value="giftCard">Gift Card</MenuItem>
            <MenuItem value="softWare">SoftWare Account</MenuItem>
            <MenuItem value="account">Games Account</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          sx={{ marginTop: `0.5rem` }}
          size="small"
          multiple
          limitTags={10}
          id="multiple-limit-tags"
          options={
            input.statusGame
              ? filteredGame.filter((game) => game.status === input.statusGame)
              : filteredGame
          }
          value={fieldValueWallet}
          filterSelectedOptions
          onChange={(event, values) => {
            setFieldValueWallet(values);
            setChangeWallet(values);
          }}
          getOptionLabel={(games) => games.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Product"
              placeholder="Favorites"
            />
          )}
        />
        <Autocomplete
          options={jsonWallets2.sort((a, b) => -b.gameId + a.gameId)}
          sx={{ marginTop: `0.5rem` }}
          fullWidth
          multiple
          filterSelectedOptions
          onChange={(event, values) => {
            setFieldValueWallet2(values);
            setChangeWallet2(values);
          }}
          groupBy={(option) => option.namegame}
          getOptionLabel={(jsonWallets2) => jsonWallets2.name}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Wallet" variant="outlined" />
          )}
        />
        {fieldValueWallet2 &&
          fieldValueWallet2.length != 0 &&
          fieldValueWallet2.map((item) => {
            return (
              <div key={item.id}>
                <div style={{ display: "flex" }}>
                  <Typography
                    sx={{
                      flexGrow: 1,
                      fontWeight: 500,
                      fontSize: "16px",
                      minWidth: "50%",
                      margin: (theme) => theme.spacing(2, 2, 0, 0),
                    }}
                  >
                    Wallet name: {item.name}
                  </Typography>
                  <div>
                    <label>Alow budget:</label>
                    <Switch
                      name="allowBudget"
                      color="primary"
                      onChange={(event) =>
                        handleChangeInputWallet(item.id, event)
                      }
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    margin: (theme) => theme.spacing(1, 0, 0, 0),
                  }}
                >
                  <Box sx={{ flexBasis: { xl: "33.33%", sm: "40%" } }}>
                    <Typography>Budget name</Typography>
                  </Box>
                  <Box sx={{ flexBasis: { xl: "33.33%", sm: "30%" } }}>
                    <Typography>Default Price</Typography>
                  </Box>
                  <Box sx={{ flexBasis: { xl: "33.33%", sm: "40%" } }}>
                    <Typography>Agency Price</Typography>
                  </Box>
                </div>
                {fieldValueWallet2 &&
                  fieldValueWallet2.length != 0 &&
                  filteredAgencys
                    .sort((a, b) => a.orderNumber - b.orderNumber)
                    .map((budget) => {
                      if (item.id == budget.walletId) {
                        return (
                          <div style={{ display: "flex" }} key={budget.id}>
                            <Box
                              sx={{ flexBasis: { xl: "33.33%", sm: "40%" } }}
                            >
                              <Typography>{budget.name}</Typography>
                            </Box>
                            <Box
                              sx={{ flexBasis: { xl: "33.33%", sm: "30%" } }}
                            >
                              <Typography>
                                {number.format(Number(budget.price))} ₫
                              </Typography>
                            </Box>
                            <Box
                              sx={{ flexBasis: { xl: "33.33%", sm: "40%" } }}
                            >
                              <TextField
                                value={budget.usedQuantity}
                                onChange={(event) =>
                                  handleChangeInputBudget(budget.id, event)
                                }
                                type="number"
                                name="usedQuantity"
                                label="Price"
                                variant="outlined"
                                size="small"
                              />
                              <Typography>
                                {number.format(Number(budget.usedQuantity))} ₫
                              </Typography>
                            </Box>
                          </div>
                        );
                      }
                    })}
                <br />
              </div>
            );
          })}
        <div style={{ marginTop: "15px" }} />
        {alowBudget.map((row) => {
          return (
            <div key={row.id}>
              <Typography
                sx={{
                  flexGrow: 1,
                  fontWeight: 500,
                  fontSize: "16px",
                  minWidth: "50%",
                }}
              >
                Game: {row.nameGame}
              </Typography>
              {row.budgetGame.map((alow) => {
                return (
                  <div key={alow.id} style={{ margin: "0px 0px 0px 10px" }}>
                    <div style={{ display: "flex" }}>
                      <Typography
                        sx={{
                          flexGrow: 1,
                          fontWeight: 500,
                          fontSize: "16px",
                          minWidth: "50%",
                          margin: (theme) => theme.spacing(1, 2, 0, 0),
                        }}
                      >
                        Wallet name:{" "}
                        {
                          fieldValueWallet3.find(
                            (item) => item.id === alow.walletId
                          )?.name
                        }
                      </Typography>
                      <div>
                        <label>Alow budget:</label>
                        <Switch
                          name="allowBudget"
                          color="primary"
                          checked={alow.allowBudget == 1 ? true : false}
                          onClick={() =>
                            DeleteWallet(
                              alow.id,
                              alow.walletId,
                              "allowBudget",
                              alow.allowBudget
                            )
                          }
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                        />
                        <IconButton
                          color="primary"
                          onClick={() =>
                            DeleteWallet(
                              alow.id,
                              alow.walletId,
                              "delete",
                              alow.allowBudget
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>

                    <Box sx={{ marginTop: `0.5rem` }}></Box>
                    {budgetagencys.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          margin: (theme) => theme.spacing(1, 0, 0, 0),
                        }}
                      >
                        <Box sx={{ flexBasis: { xl: "33.33%", sm: "40%" } }}>
                          <Typography>Budget name</Typography>
                        </Box>
                        <Box sx={{ flexBasis: { xl: "33.33%", sm: "30%" } }}>
                          <Typography>Default Price</Typography>
                        </Box>
                        <Box sx={{ flexBasis: { xl: "33.33%", sm: "40%" } }}>
                          <Typography>Agency Price</Typography>
                        </Box>
                      </div>
                    )}
                    {budgetagencys.map((row) => {
                      if (row.walletId == alow.walletId) {
                        return (
                          <div style={{ display: "flex" }} key={row.id}>
                            <Box
                              sx={{ flexBasis: { xl: "33.33%", sm: "40%" } }}
                            >
                              <Typography>{row.name}</Typography>
                            </Box>
                            <Box
                              sx={{ flexBasis: { xl: "33.33%", sm: "30%" } }}
                            >
                              <Typography>
                                {number.format(
                                  Number(
                                    jsonBudgets.find(
                                      (itemG) => itemG.id === row.budgetId
                                    )?.price || 0
                                  )
                                )}{" "}
                                ₫
                              </Typography>
                            </Box>
                            <Box
                              sx={{ flexBasis: { xl: "33.33%", sm: "40%" } }}
                            >
                              <Typography>
                                {number.format(Number(row.priceClient))} ₫
                              </Typography>
                            </Box>
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.nameGroup}
          name="nameGroup"
          error={Boolean(input.nameGroup === "")}
          helperText={input.nameGroup === "" ? "Name Group is required" : ""}
          label="Name Group"
          size="small"
          variant="outlined"
          fullWidth
        />
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          sx={{ marginTop: `0.5rem` }}
        >
          <InputLabel>Select status</InputLabel>
          <Select
            value={input.status}
            onChange={handleinput}
            label="Select status"
            name="status"
          >
            <MenuItem value="limit">Stop</MenuItem>
            <MenuItem value="trustSeller">Default</MenuItem>
            <MenuItem value="Prioritize">Prioritize</MenuItem>
          </Select>
        </FormControl>
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.teleId}
          name="teleId"
          label="telegram ID"
          size="small"
          variant="outlined"
          fullWidth
        />
        <label>Current price:</label>
        <Switch
          checked={checked}
          color="primary"
          onChange={handleChangeChecked}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          multiline
          minRows="4"
          type="text"
          value={input.notes}
          name="notes"
          label="Notes"
          size="small"
          variant="outlined"
          fullWidth
        />
        {errorMessage && (
          <Alert severity="error" sx={{ marginTop: `0.5rem` }}>
            {errorMessage}
          </Alert>
        )}
        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to {idProductRefWName.current} this wallet agencys?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to Current price agencys?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCurrentPrice} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditAgencys;
