import { CircularProgress } from "@mui/material";
import axiosusers from "utils/api/axiosusers";

import React from "react";
import useSWR from "swr";
import CountryGiftCards from "./FriendsGameId";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Friends Game Id");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const {
    data: countryData,
    error: countryError,
    isLoading: countryLoading,
  } = useSWR(`/friends/friends`, fetchDataSwr);

  return (
    <div>
      {countryError && <div>failed to load</div>}
      {countryLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {!countryLoading && <CountryGiftCards countrys={countryData} />}
    </div>
  );
};

export default index;
