import { Tabtitle } from "components/title/Title";
import React from "react";
import useSWR from "swr";
import axiosusers from "utils/api/axiosusers";
import AddClients from "./AddClients";

const index = () => {
  Tabtitle("Create Clients");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const {
    data: clientsData,
    error: clientsError,
    isLoading: clientsLoading,
  } = useSWR(`/primarychannel/primarychannel`, fetchDataSwr);

  const {
    data: games,
    error: gamesError,
    isLoading: gamesLoading,
  } = useSWR(`/games/games`, fetchDataSwr);
  return (
    <div>
      {clientsError && gamesError && <div>failed to load</div>}
      {clientsLoading && gamesLoading && <div>Loading...</div>}
      {!clientsLoading && !gamesLoading && (
        <AddClients primarychannel={clientsData} games={games} />
      )}
    </div>
  );
};

export default index;
