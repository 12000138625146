import { Tabtitle } from "components/title/Title";
import React from "react";
import Wallets from "./Wallets";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";

const index = () => {
  Tabtitle("Wallets");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const { data, error, isLoading } = useSWR(
    `/wallets/wallets-games`,
    fetchDataSwr
  );
  return (
    <div>
      {error && <div>failed to load</div>}

      {!isLoading && <Wallets wallets={data.wallets} games={data.games} />}
    </div>
  );
};

export default index;
