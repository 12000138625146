import { Tabtitle } from "components/title/Title";
import React from "react";
import Withdraws from "./Withdraws";
import { useParams } from "react-router-dom";

const index = () => {
  Tabtitle("Payment detail");
  const { id } = useParams();
  return <div>{id && <Withdraws id={id} />}</div>;
};

export default index;
