import { Tabtitle } from "components/title/Title";
import React from "react";
import Blacklists from "./Blacklists";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";
import { CircularProgress } from "@mui/material";

const index = () => {
  Tabtitle("Blacklists");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const { data, error, isLoading } = useSWR(
    `/budgets/blacklists`,
    fetchDataSwr
  );
  return (
    <div>
      {error && <div>failed to load</div>}
      {isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && <Blacklists blacklists={data} />}
    </div>
  );
};

export default index;
