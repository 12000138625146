import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";

import { FormAutocomplete } from "hooks/FormAutocomplete";
import { useNavigate } from "react-router-dom";

const AddGroups = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [jsonResuls, setJsonResuls] = React.useState([]);

  const [fieldValueGame, setFieldValueGame] = React.useState([]);
  const initialValues = {
    notes: "",
    Game: null,
  };
  const validationSchema = Yup.object().shape({
    Game: Yup.object().required("Game is required"),
  });
  const [image, setImage] = React.useState();
  const [avatar, setAvatar] = React.useState();
  const handleFile = (e) => {
    const file = e.target.files[0];
    setImage(file);

    file.preview = URL.createObjectURL(file);
    setAvatar(file);
  };

  useEffect(() => {
    Promise.all([axiosusers.get(`/facebook-manage/managefacebook`)])
      .then((results) => {
        const [orders] = results.map((res) => res.data);
        setJsonResuls(orders);
      })
      .catch((error) => console.error(error));
  }, []);
  const onSubmit = async (values) => {
    setDung(true);
    seterrorMessage("");
    if (image?.size > 2 * 1024 * 1024) {
      return seterrorMessage("size must be less than 2MB");
    }
    const formData = new FormData();
    formData.append("managefacebookId", fieldValueGame.id);
    formData.append("name", fieldValueGame.name);
    formData.append("notes", values.notes);
    formData.append("file", image);
    try {
      const response = await axiosusers.post(
        `/uploads/sales-article-notes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      setDung(false);
      if (response.status === 200) {
        navigate("/app/facebook-manage/");
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(error.response.data.message);
      setDung(false);
    }
  };

  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new sales article notes
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {() => (
            <Form autoComplete="off">
              <FormAutocomplete
                onChangeSelect={(value) => setFieldValueGame(value)}
                sx={{ marginTop: `0.5rem` }}
                label="Group"
                name="Game"
                options={jsonResuls}
              />

              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                multiline
                minRows="4"
                name="notes"
                label="Notes"
                variant="outlined"
                size="small"
                fullWidth
              />
              {avatar && (
                <Avatar
                  sx={{
                    width: (theme) => theme.spacing(25),
                    height: (theme) => theme.spacing(25),
                    margin: (theme) => theme.spacing(2, 0),
                  }}
                  src={avatar.preview}
                  variant="square"
                />
              )}
              <input
                accept="image/*"
                onChange={handleFile}
                id="file"
                name="img"
                multiple
                type="file"
                style={{
                  display: "none",
                  color: "#3f50b5",
                  margin: (theme) => theme.spacing(2, 0),
                }}
              />
              <label htmlFor="file">
                <Button component="span" color="primary">
                  UPLOAD PHOTOS
                </Button>
              </label>
              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddGroups;
