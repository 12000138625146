import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import useDecodedToken from "utils/token/useDecodedToken";
import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axiosusers from "utils/api/axiosusers";
import _ from "lodash";
import LinkIcon from "@mui/icons-material/Link";
import Images from "components/Avatar/Images";
import { NavLink } from "react-router-dom";
const SalesArticleNotes = () => {
  const { decodedToken } = useDecodedToken();
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const idProductRef = useRef();
  const idProductRefpage = useRef();
  const idProductRef_name = useRef();
  const [loading, setLoading] = useState(false);

  const [Message, setMessage] = useState(false);
  const [DonePage, setDonePage] = useState([]);
  const [open, setOpen] = useState(false);

  const [selected, setSelected] = useState([]);
  const [clients, setClients] = useState([]);

  const pageSize = 10;
  const [pageCount, setPageCount] = useState(0);
  const fetchData = async () => {
    Promise.all([
      axiosusers.get(`/facebook-manage/salesarticlenotes`),
      axiosusers.get(`/facebook-manage/managefacebook`),
    ])
      .then((results) => {
        const [transactions, clients] = results.map((res) => res.data);
        setPageCount(Math.ceil(transactions.length / pageSize));
        setProduct(transactions);
        setDonePage(transactions);
        setPagination(_(transactions).slice(0).take(pageSize).value());
        setClients(clients);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    setCurrentPage(1);
    fetchData();
  }, [pageSize]);
  useEffect(() => {
    let results = product;
    setCurrentPage(1);
    if (selected?.id !== undefined) {
      results = results.filter((item) => item.managefacebookId === selected.id);
    }
    setPageCount(Math.ceil(results.length / pageSize));
    setDonePage(results);
    setPagination(_(results).slice(0).take(pageSize).value());
  }, [selected, product]);
  setTimeout(function () {
    setMessage(false);
  }, 200);
  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes-item1/delete-sales-article-notes`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        setProduct(
          product.filter((item) => {
            return item.id !== idProductRef.current;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }

    setMessage(true);
    setOpen(false);
  };
  const handleClickOpen = (id, page, name) => {
    setOpen(true);
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Pagechane = (event, value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(DonePage).slice(startIndex).take(pageSize).value();
    setPagination(pagination);
    setCurrentPage(value);
    setMessage(true);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(12),
      boxShadow:
        "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)",
      margin: "auto",
      maxWidth: "1000px",
    },
  }));
  return (
    <div>
      {decodedToken?.role == "Admin" && (
        <NavLink
          to="/app/facebook-manage/sales-article-notes/add"
          className={styles.link}
        >
          <Button variant="contained" color="primary">
            ADD Sales Article Notes
          </Button>
        </NavLink>
      )}
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Autocomplete
                options={clients}
                fullWidth
                onChange={(event, value) => setSelected(value)}
                getOptionLabel={(jsonResuls) => jsonResuls.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Group"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {Message && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {loading && (
        <div className={styles.center}>
          <CircularProgress />
        </div>
      )}

      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name Group</TableCell>
              <TableCell>image</TableCell>
              <TableCell align="center">Link</TableCell>
              <TableCell>Template</TableCell>
              <TableCell>Created date</TableCell>
              <TableCell>Created date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {pagination.map((row) => {
              var nullCreate = row.createdAt.split("T");
              var dateCreate = nullCreate[0].split("-");
              var dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    {clients.find((itemC) => itemC.id === row.managefacebookId)
                      ?.name || ""}
                  </TableCell>
                  <TableCell>
                    <Images nameImage={`${row.image}`} />
                  </TableCell>
                  <TableCell align="center">
                    <a
                      target="_blank"
                      href={
                        clients.find(
                          (itemC) => itemC.id === row.managefacebookId
                        )?.link || ""
                      }
                      rel="noreferrer"
                    >
                      <IconButton color="primary">
                        <LinkIcon />
                      </IconButton>
                    </a>
                  </TableCell>
                  <TableCell>
                    <HtmlTooltip
                      placement="left-start"
                      title={
                        <React.Fragment>
                          <Typography
                            color="inherit"
                            sx={{
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                              color: "#263238",
                              fontWeight: 400,
                              fontSize: "16px",
                              lineHeight: 1.6,
                              letterSpacing: "0.00958em",
                            }}
                          >
                            {row.notes}
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <Button
                        variant="contained"
                        onClick={() =>
                          navigator.clipboard.writeText(`${row.notes}`)
                        }
                        color="primary"
                        size="small"
                      >
                        Copy
                      </Button>
                    </HtmlTooltip>
                  </TableCell>
                  <TableCell>{dateCreateAt}</TableCell>

                  <TableCell size="small" align="center">
                    <NavLink
                      to={`/app/facebook-manage/sales-article-notes/edit/${row.id}`}
                    >
                      <IconButton color="primary">
                        <EditIcon />
                      </IconButton>
                    </NavLink>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        handleClickOpen(row.id, DonePage, "Delete")
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {product.length > 10 && (
          <Grid
            container
            spacing={0}
            sx={{
              justifyContent: "center",
              color: "#000",
              display: "flex",
              padding: "10px",
            }}
          >
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={Pagechane}
              size="small"
              color="primary"
            />
          </Grid>
        )}
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to Delete this Groups?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SalesArticleNotes;
