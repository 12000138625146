import React, { useEffect, useRef, useState } from "react";

import axiosusers from "utils/api/axiosusers";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CardMedia } from "@material-ui/core";
import useLinks from "hooks/useLinks";

import styles from "./style.module.css";

import { NavLink } from "react-router-dom";
import useDecodedToken from "utils/token/useDecodedToken";

const Games = (props) => {
  const { data } = props;

  const idProductRef = useRef();
  const idProductRefpage = useRef();
  const idProductRef_name = useRef();
  const linkImage = useLinks();
  const { decodedToken } = useDecodedToken();
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState({
    name: "",
  });
  const SearchText = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };

  const [product, setProduct] = useState([]);

  useEffect(() => {
    let products = data;
    if (search.name !== "") {
      const numberRegex = new RegExp(`.*${search.name}.*`, "i");
      products = products.filter((item) => numberRegex.test(item.name));
    }
    setProduct(products);
  }, [search, data]);

  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
      status: idProductRefpage.current,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes/delete-games`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        if (idProductRefpage.current === "positive") {
          const updatedProduct = data.map((item) => {
            if (item.id === idProductRef.current) {
              return { ...item, positive: item.positive == 0 ? 1 : 0 };
            }
            return item;
          });

          setProduct(updatedProduct);
        } else {
          setProduct(data.filter((item) => item.id !== idProductRef.current));
        }
      }
    } catch (error) {
      console.log(error);
    }

    setOpen(false);
  };
  const handleClickOpen = (id, page, name) => {
    setOpen(true);

    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {decodedToken?.role == "Admin" && (
        <NavLink to="/app/soft-ware/add" className={styles.link}>
          <Button variant="contained" color="primary">
            ADD soft-ware
          </Button>
        </NavLink>
      )}
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutlinedInput
                fullWidth
                onChange={SearchText}
                placeholder="Select a name"
                name="name"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container spacing={3}>
        {product.map((item) => (
          <Grid item key={item.id} lg={3} md={6} sm={12} xs={12}>
            <Card>
              <CardMedia
                className={styles.img}
                component="img"
                height="194"
                image={`${linkImage + item.image}`}
                crossOrigin="anonymous"
              />
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  fontSize: (theme) => theme.typography.pxToRem(20),
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(2),
                }}
              >
                {item.name}
              </Typography>
              <Divider />
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: (theme) => theme.typography.pxToRem(16),
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(1),
                }}
              >
                {item.description}
              </Typography>
              <Divider />
              <Box
                sx={{
                  padding: (theme) => theme.spacing(1),
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <IconButton
                  color="primary"
                  onClick={() => handleClickOpen(item.id, "positive")}
                >
                  {item.positive == 0 ? <DoneIcon /> : <CancelIcon />}
                </IconButton>
                <NavLink to={`/app/soft-ware/${item.id}`}>
                  <IconButton color="primary">
                    <AddToQueueIcon />
                  </IconButton>
                </NavLink>
                {decodedToken?.role === "Admin" && (
                  <NavLink to={`/app/soft-ware/edit/${item.id}`}>
                    <IconButton color="primary">
                      <EditIcon />
                    </IconButton>
                  </NavLink>
                )}
                {decodedToken?.role === "Admin" && (
                  <IconButton
                    color="primary"
                    onClick={() => handleClickOpen(item.id, "delete")}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {idProductRefpage.current == "delete"
              ? "WARNING: If you delete this Game, all Wallets, Products and Orders which was created from this game will be deleted too. Are you sure?"
              : "Are you sure to turn positive?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Games;
