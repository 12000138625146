import React from "react";
import Transactions from "./Transactions";
import { Tabtitle } from "components/title/Title";
import useSWR from "swr";
import fetchData from "utils/data";
import { CircularProgress } from "@mui/material";

const index = () => {
  Tabtitle("Transactions");
  const { data, error, isLoading } = useSWR(
    "/transactions/transactions-all-new",
    (url) => fetchData(url)
  );
  return (
    <div>
      {error && <div>failed to load</div>}
      {isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && (
        <Transactions transactions={data.transactionsAll} page={data.pages} />
      )}
    </div>
  );
};

export default index;
