import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import { Tabtitle } from "components/title/Title";
import { Field, Form, Formik } from "formik";
import { FormAutocomplete } from "hooks/FormAutocomplete";
import useCountry from "hooks/useCountry";
import useUserId from "hooks/useUserId";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import * as Yup from "yup";
const index = () => {
  Tabtitle("Create country giftcard");
  const { countries } = useCountry();
  const navigate = useNavigate();

  const [fieldValueWallet, setFieldValueWallet] = useState([]);
  const [jsonWallets, setJsonWallets] = useState([]);
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [done, setDone] = React.useState(false);
  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);

  useEffect(() => {
    Promise.all([axiosusers.get(`/games/games`)])
      .then((results) => {
        const [games] = results.map((res) => res.data);
        const giftcard = games.filter((item) => item.status == "giftCard");
        setJsonWallets(giftcard);
      })
      .catch((error) => console.error(error));
  }, []);
  const initialValues = {
    fullName: "",
    notesen: "",
    noteses: "",
  };

  const validationSchema = Yup.object().shape({});

  const onSubmit = async (values) => {
    setDone(true);

    if (value == "") {
      setDone(false);
      return seterrorMessage(`Country is required`);
    }

    const data = {
      gameId: fieldValueWallet?.id,
      country: value,
      notesen: values.notesen,
      noteses: values.noteses,
    };
    try {
      const response = await axiosusers.post(`/countrys/create-country`, data);

      if (response.status === 200) {
        navigate("/app/countrygiftcard/");
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(error.response.data.message);
      setDone(false);
    }
  };

  const handleChangeSelect = (event, value) => {
    setValue(value.label);
  };
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "30px",
          margin: (theme) => theme.spacing(0, 0, 3, 0),
        }}
      >
        Create new country giftcard
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form autoComplete="off">
            <FormAutocomplete
              placeholder="Select one or multiple"
              onChange={(event, values) => {
                setFieldValueWallet(values);
              }}
              filterSelectedOptions={true}
              style={{ marginTop: `0.5rem` }}
              name="Game"
              label="Gift Card"
              options={jsonWallets}
            />
            <Autocomplete
              id="country-select-demo"
              options={countries}
              autoHighlight
              size="small"
              onChange={handleChangeSelect}
              getOptionLabel={(option) => option.label}
              sx={{ marginTop: `0.5rem` }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=""
                  />
                  {option.label} ({option.code}) +{option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <Field
              as={TextField}
              sx={{ marginTop: `0.5rem` }}
              name="notesen"
              label="Nofication English"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
            />
            <Field
              as={TextField}
              sx={{ marginTop: `0.5rem` }}
              name="noteses"
              label="Nofication Spanish"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
            />
            {errorMessage.length > 0 && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ marginTop: `3rem` }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={done}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default index;
