import React from "react";
import { Tabtitle } from "components/title/Title";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import useUserId from "hooks/useUserId";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import useCountryCode from "hooks/useCountryCode";

const Index = () => {
  Tabtitle("Create giftcards");

  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [fieldValueCountry, setFieldValueCountry] = React.useState([]);

  const [dung, setDung] = React.useState(false);
  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const initialValues = {
    name: "",
    description: "",
    notesen: "",
    noteses: "",
  };
  const [input, setInput] = React.useState({
    status: "",
    stock: "",
    selectOptions: "",
  });

  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(0, "It's too short").required("Name is required"),
  });

  const [image, setImage] = React.useState(null);
  const [avatar, setAvatar] = React.useState(null);

  const handleFile = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setAvatar({ preview: URL.createObjectURL(file) });
  };
  const onSubmit = async (values) => {
    setDung(true);
    seterrorMessage("");
    if (image?.size > 2 * 1024 * 1024) {
      setDung(false);
      return seterrorMessage("size must be less than 2MB");
    }

    const formData = new FormData();
    const datatime = new Date().getTime();
    formData.append("name", values.name);
    formData.append("notesen", values.notesen);
    formData.append("noteses", values.noteses);
    formData.append("positive", 0);
    formData.append("status", "giftCard");
    formData.append("description", values.description);
    formData.append("file", image);
    formData.append("datatime", datatime);
    formData.append("optionsCountry", input.selectOptions);
    formData.append("fieldValueCountry", fieldValueCountry);
    try {
      const response = await axiosusers.post(`/uploads/games`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      setDung(false);

      if (response.status === 200) {
        navigate("/app/giftcards/");
      }
      const dataBudgets = {
        gameId: response.data.id,
        fieldValueCountry,
      };
      try {
        const resbudgets = await axiosusers.post(
          `/games/create-games-language`,
          dataBudgets
        );

        if (resbudgets.status === 200) {
          console.log(resbudgets);
        }
      } catch (error) {
        setDung(false);
        console.log(error.response?.data.message);
        seterrorMessage(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(error.response.data.message);
      setDung(false);
    }
  };
  const [countryDatas, setCountryData] = React.useState([]);
  const { countries } = useCountryCode();
  React.useEffect(() => {
    setCountryData(countries);
  }, []);
  const setChangeCountry = async () => {};
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
          }}
        >
          Create new giftcards
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                sx={{ marginTop: `1rem` }}
                as={TextField}
                name="name"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                // eslint-disable-next-line react/prop-types
                error={props.errors.name && props.touched.name}
                helperText={<ErrorMessage name="name" />}
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="description"
                label="Description"
                variant="outlined"
                size="small"
                fullWidth
              />
              <FormControl
                size="small"
                fullWidth
                sx={{ marginTop: `0.5rem` }}
                variant="outlined"
                error={Boolean(errorMessage && input.selectOptions == "1")}
              >
                <InputLabel>Sell For Country</InputLabel>
                <Select
                  value={input.selectOptions}
                  onChange={handleChange}
                  label="Sell For Country"
                  name="selectOptions"
                >
                  <MenuItem value="global">Global</MenuItem>
                  <MenuItem value="showOnlySomeCountries">
                    Show only some countries
                  </MenuItem>
                  <MenuItem value="excludingSomeCountries">
                    Excluding some countries
                  </MenuItem>
                </Select>
                {errorMessage && input.selectOptions == "1" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>
              {input.selectOptions != "global" && (
                <Autocomplete
                  id="country-select-demo"
                  size="small"
                  sx={{ marginTop: `0.5rem` }}
                  options={countryDatas}
                  multiple
                  filterSelectedOptions
                  onChange={(event, values) => {
                    setFieldValueCountry(values);
                    setChangeCountry(values);
                  }}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Some countries"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="notesen"
                label="Nofication English"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                rows={4}
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="noteses"
                label="Nofication Spanish"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                rows={4}
              />
              {avatar && (
                <Avatar
                  src={avatar.preview}
                  variant="square"
                  sx={{
                    width: (theme) => theme.spacing(25),
                    height: (theme) => theme.spacing(25),
                    margin: (theme) => theme.spacing(2, 0),
                  }}
                />
              )}
              <input
                accept="image/*"
                style={{
                  display: "none",
                  color: "#3f50b5",
                  margin: (theme) => theme.spacing(2, 0),
                }}
                onChange={handleFile}
                id="file"
                name="file"
                multiple
                type="file"
              />
              <label htmlFor="file">
                <Button
                  component="span"
                  sx={{ marginTop: `1rem` }}
                  color="primary"
                >
                  UPLOAD PHOTOS
                </Button>
              </label>

              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default Index;
