import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
} from "@mui/material";
import React from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Logouts } from "hooks/useLogout";
import { useNavigate } from "react-router-dom";
import useDecodedToken from "utils/token/useDecodedToken";
import Profiles from "./Profiles";
import MenuItems from "./MenuItems";
import MenuIcon from "@mui/icons-material/Menu";
import useUserId from "hooks/useUserId";

const Drawers = () => {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      navigate("/login");
    }
  }, []);
  const { decodedToken } = useDecodedToken();

  const logoutHandler = async (e) => {
    e.preventDefault();
    setOpen(false);

    if (decodedToken) {
      Logouts(decodedToken?.jti);
    }
    navigate("/login");
    localStorage.removeItem("accessToken");
  };

  const { userId } = useUserId();

  const handleLogout = async () => {
    try {
      const datetimeString = userId.updatedAt;
      const datetimeObject = new Date(datetimeString);
      const timestamp = datetimeObject.getTime();
      const timeDifference = Date.now() - timestamp + 19999990;

      if (timeDifference > 0) {
        if (decodedToken) {
          Logouts(decodedToken?.jti);
        }
        navigate("/login");
        localStorage.removeItem("accessToken");
      }
    } catch (error) {
      console.error(error);
    }
  };
  if (userId) {
    handleLogout();
  }
  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Profiles />
      <MenuItems />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "20px 0px",
        }}
      >
        <Button
          onClick={handleClickOpen}
          startIcon={<ExitToAppIcon />}
          sx={{ width: 200 }}
        >
          Logout
        </Button>
      </div>
    </Box>
  );
  return (
    <>
      <Box sx={{ display: { md: "block", sx: "none", xs: "none" } }}>
        <IconButton onClick={handleClickOpen}>
          <ExitToAppIcon sx={{ color: "white" }} />
        </IconButton>
      </Box>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Box sx={{ display: { md: "none", sx: "block", xs: "block" } }}>
            <IconButton onClick={toggleDrawer(anchor, true)}>
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>
          </Box>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{ zIndex: 1500 }}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to logout the system?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={logoutHandler} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Drawers;
