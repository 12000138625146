import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";

import React from "react";
import styles from "./style.module.css";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";

import axiosusers from "utils/api/axiosusers";
import Images from "components/Avatar/Images";
import RepeatIcon from "@mui/icons-material/Repeat";
import CancelIcon from "@mui/icons-material/Cancel";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import UndoIcon from "@mui/icons-material/Undo";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import { sendOxxo } from "hooks/useSendMailOxxo";
import useDecodedToken from "utils/token/useDecodedToken";
import useUserId from "hooks/useUserId";
import { NavLink } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const Orders = (props) => {
  const {
    ordersApis,
    gamesApi,
    ordersPages,
    clientsApi,
    agencysApi,
    ordersIsLoading,
  } = props;
  const classes = useStyles();
  const { decodedToken } = useDecodedToken();
  const { userId } = useUserId();

  const [messages, setMessages] = React.useState("");
  const [texts, setTests] = React.useState("");

  const [currentPage, setCurrentPage] = React.useState(0);
  const [pagination, setPagination] = React.useState([]);
  const pageSize = 10;
  const [pageCount, setPageCount] = React.useState(0);
  const idProductRef = React.useRef();
  const idProductRefpage = React.useRef();
  const idProductRef_name = React.useRef();
  const idProductRef_paymentStatus = React.useRef();
  const [Message, setMessage] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [DonePage, setDonePage] = React.useState([]);
  const [jsonResuls, setJsonResuls] = React.useState([]);
  const [games, setGames] = React.useState([]);
  const [agencys, setAgencys] = React.useState([]);
  const [open2, setOpen2] = React.useState(false);
  const [searchOrderId, setSearchOrderId] = React.useState("");
  const [searchStatus, setSearchStatus] = React.useState("");
  const [searchPaymentStatus, setSearchPaymentStatus] = React.useState("");
  const [searchInfo, setSearchInfo] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [selectedGame, setSelectedGame] = React.useState([]);

  const [Message2, setMessage2] = React.useState(false);
  const [TrueClick, setTrueClick] = React.useState(false);
  const [selectedagencys, setSelectedagencys] = React.useState([]);

  const fetchData = async () => {
    const ordersApi = ordersApis;
    setJsonResuls(clientsApi === undefined ? [] : clientsApi);
    setPageCount(Math.ceil(ordersPages / pageSize));
    setDonePage(ordersApi);
    setPagination(ordersApi);
    setAgencys(agencysApi);
    setGames(gamesApi === undefined ? [] : gamesApi);
  };

  React.useEffect(() => {
    if (!ordersIsLoading) {
      fetchData();
    }
  }, [ordersApis, gamesApi, clientsApi, agencysApi, ordersIsLoading]);

  const fetchDataAll = async () => {
    Promise.all([
      axiosusers.get("/order/orders-new", {
        params: {
          searchStatus,
          searchPaymentStatus,
          clientId: selected?.id,
          gameId: selectedGame?.id,
          searchOrderId,
          searchInfo,
          currentPage: currentPage === 0 ? 1 : currentPage,
        },
      }),
    ])
      .then(async (results) => {
        const [res] = results.map((res) => res.data);
        setPageCount(Math.ceil(res.pages / pageSize));
        setPagination(res.orderAll);
      })
      .catch((error) => console.error(error));
  };
  React.useEffect(() => {
    if (
      searchStatus !== "" ||
      searchPaymentStatus !== "" ||
      selected?.id !== undefined ||
      currentPage !== 0 ||
      selectedGame?.id !== undefined ||
      searchOrderId !== "" ||
      searchInfo !== ""
    ) {
      fetchDataAll();
    }
  }, [
    searchStatus,
    searchPaymentStatus,
    selected,
    selectedGame,
    searchOrderId,
    searchInfo,
    currentPage,
  ]);

  const [state, setState] = React.useState(false);

  const handleChangeCkeck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const initialFormData = Object.freeze({
    info: "",
    notesLo: "",
    notesClient: "",
    notesCode: "",
  });
  const [update, setUpdate] = React.useState(initialFormData);
  const handleChange = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  };
  const handleClickOpen = async (id, page, name, paymentStatus) => {
    setOpen(true);

    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
    idProductRef_paymentStatus.current = paymentStatus;
    if (name == "inProgress") {
      setMessages("Are you sure to complete this order?");
    }
    if (name == "completed") {
      setMessages(
        "Are you sure to mark done this order? You can not update anything after mark done it."
      );
    }
    if (name == "doneAll") {
      setMessages(
        "Are you sure to mark done this order? You can not update anything after mark done it."
      );
    }
    if (name == "doneAllAll") {
      setMessages(
        "Are you sure to mark done this order? You can not update anything after mark done it."
      );
    }
    if (name == "done") {
      setMessages("Are you sure to complete this order?");
    }
    if (name == "delete") {
      setMessages("Are you sure to delete this order?");
    }
    console.log(userId);
  };
  const Pagechane = (event, value) => {
    setCurrentPage(value);
  };
  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };
  const handleClickRepeat = async (id, page, agencyId, cancel) => {
    const data1 = pagination.find((item) => item.id === id);
    setAgencys(agencysApi.filter((item) => item.id !== data1.agencyId));
    setUpdate(data1);

    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_paymentStatus.current = cancel;

    setOpen2(true);
  };
  const handleDelete = async () => {
    setOpen(false);
    if (
      idProductRef_name.current != "doneAll" &&
      idProductRef_name.current != "doneAllAll"
    ) {
      if (idProductRef_name.current == "inProgress") {
        idProductRef_name.current = "completed";
      } else if (idProductRef_name.current == "completed") {
        idProductRef_name.current = "done";
      } else if (idProductRef_name.current == "done") {
        idProductRef_name.current = "undoDone";
      } else {
        idProductRef_name.current = "delete";
      }
      const dataSend = {
        id: idProductRef.current,
        status: idProductRef_name.current,
      };
      try {
        const response = await axiosusers.patch(
          `/deletes/delete-orders-${idProductRef_name.current}`,
          dataSend
        );
        if (response.status === 200) {
          console.log(response);
          setMessage2(false);
          fetchDataAll();

          const orderProduct = pagination.find(
            (item) => item.id === idProductRef.current
          );
          const clientPage = jsonResuls.find(
            (item) => item.id === orderProduct.clientId
          );

          if (clientPage) {
            orderProduct.fullName = clientPage.fullName;
            orderProduct.primarychannelId = clientPage.primarychannelId;
            orderProduct.email = clientPage.email;
            orderProduct.phoneNumber = clientPage.phoneNumber;
          }
          if (
            idProductRef_name.current === "completed" &&
            clientPage &&
            pagination.find((item) => item.id === idProductRef.current)
              ?.primarychannelId == 4
          ) {
            let dataSend = {
              email: pagination.find((item) => item.id === idProductRef.current)
                ?.email,

              name: pagination.find((item) => item.id === idProductRef.current)
                ?.fullName,
              message: "",
              phoneNumber: pagination.find(
                (item) => item.id === idProductRef.current
              )?.phoneNumber,
              checkedID: false,
              checkedCode: false,
              checkedName: false,
              checkedServer: false,
              checkedAcountPass: false,
              subjectGift: true,
              orderId: idProductRef.current,
              clientId: pagination.find(
                (item) => item.id === idProductRef.current
              )?.clientId,
              gameId: pagination.find(
                (item) => item.id === idProductRef.current
              )?.gameId,
              status: "noneNotifications",
            };
            sendOxxo(dataSend);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (
      idProductRef_name.current === "doneAll" ||
      idProductRef_name.current === "doneAllAll"
    ) {
      const dataSend = {
        id: idProductRef.current,
        status: idProductRef_name.current,
        pagination,
      };

      try {
        const response = await axiosusers.patch(
          `/deletes/delete-orders-${idProductRef_name.current}`,
          dataSend
        );
        if (response.status === 200) {
          console.log(response);
          fetchDataAll();

          setMessage2(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setTimeout(function () {
      setMessage(false);
      setMessage2(false);
    }, 200);
  };
  const handleChuyen = async () => {
    setTrueClick(true);
    setOpen2(false);
    const dataSend = {
      id: idProductRef.current,
      status: idProductRef_paymentStatus.current,
      agencyId: selectedagencys?.id,
      info: update.info === undefined ? "" : update.info,
      notes: update.notesLo === undefined ? "" : update.notesLo,

      notesClient:
        update.notesClient === undefined
          ? state
            ? "Please check your Gmail.\n" + state.eventConcluded
            : ""
          : state
          ? "Please check your Gmail.\n" + state.eventConcluded
          : "" + update.notesClient,
      notesCode: update.notesCode === undefined ? "" : update.notesCode,
      state,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes/chuyen-orders-${idProductRef_paymentStatus.current}`,
        dataSend
      );
      if (response.status === 200) {
        setOpen2(false);
        setTests("");
        setMessage2(false);
        setTrueClick(false);
        setTimeout(function () {
          fetchDataAll();
        }, 10);

        if (state || update.notesClient) {
          const mess = update.notesClient;
          const orderProduct = pagination.find(
            (item) => item.id === idProductRef.current
          );
          const clientPage = jsonResuls.find(
            (item) => item.id === orderProduct.clientId
          );

          if (clientPage) {
            orderProduct.fullName = clientPage.fullName;
            orderProduct.primarychannelId = clientPage.primarychannelId;
            orderProduct.email = clientPage.email;
            orderProduct.phoneNumber = clientPage.phoneNumber;
          }
          let dataSend = {
            email: pagination.find((item) => item.id === idProductRef.current)
              ?.email,

            name: pagination.find((item) => item.id === idProductRef.current)
              ?.fullName,
            message: mess,
            phoneNumber: pagination.find(
              (item) => item.id === idProductRef.current
            )?.phoneNumber,
            orderId: idProductRef.current,
            checkedID: state.checkedID,
            checkedCode: state.checkedCode,
            checkedName: state.checkedName,
            checkedServer: state.checkedServer,
            checkedAcountPass: state.checkedAcountPass,
            clientId: pagination.find(
              (item) => item.id === idProductRef.current
            )?.clientId,
            gameId: pagination.find((item) => item.id === idProductRef.current)
              ?.gameId,
            status: "notifications",
          };
          sendOxxo(dataSend);
        }
        setState(false);
      }

      setSelectedagencys([]);
    } catch (error) {
      console.log(error);
      setOpen2(true);
      setTrueClick(false);

      setTests(error.response.data.message);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(12),
      boxShadow:
        "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)",
      margin: "auto",
      maxWidth: "1000px",
    },
  }));
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  setTimeout(function () {
    setMessage2(false);
  }, 500);
  return (
    <div>
      <NavLink to="/app/orders/add" className={styles.link}>
        <Button variant="contained" color="primary">
          ADD orders
        </Button>
      </NavLink>
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <div className={styles.container}>
            <div className={styles.item}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={searchStatus}
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                    setCurrentPage(1);
                  }}
                  label="Status"
                  name="status"
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="inProgress">In progress</MenuItem>
                  <MenuItem value="done">Done</MenuItem>
                  <MenuItem value="movingAgency">Moving agency</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.item}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Payment Status</InputLabel>
                <Select
                  value={searchPaymentStatus}
                  onChange={(e) => {
                    setSearchPaymentStatus(e.target.value);
                    setCurrentPage(1);
                  }}
                  label="Payment Status"
                  name="paymentStatus"
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                  <MenuItem value="unPaid">Un paid</MenuItem>
                  <MenuItem value="partPaid">Part paid</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.item}>
              <Autocomplete
                options={jsonResuls}
                fullWidth
                onChange={(event, value) => {
                  setSelected(value);

                  setCurrentPage(1);
                }}
                getOptionLabel={(jsonResuls) => jsonResuls.fullName}
                renderInput={(params) => (
                  <TextField {...params} label="Client" variant="outlined" />
                )}
              />
            </div>
            <div className={styles.item}>
              <Autocomplete
                options={games}
                fullWidth
                onChange={(event, value) => {
                  setSelectedGame(value);
                  setCurrentPage(1);
                }}
                getOptionLabel={(jsonResuls) => jsonResuls.name}
                renderInput={(params) => (
                  <TextField {...params} label="Game" variant="outlined" />
                )}
              />
            </div>
            <div className={styles.item}>
              <OutlinedInput
                fullWidth
                onChange={(e) => {
                  setSearchInfo(e.target.value);
                  setCurrentPage(1);
                }}
                placeholder="Search by info"
                name="info"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </div>
            <div className={styles.item}>
              <OutlinedInput
                fullWidth
                onChange={(e) => {
                  setSearchOrderId(e.target.value);
                  setCurrentPage(1);
                }}
                placeholder="Search by orders id"
                name="orderId"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </div>
          </div>
        </Paper>
      </Grid>

      {(Message || Message2) && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {ordersIsLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {!ordersIsLoading && (
        <TableContainer
          component={Paper}
          sx={{ width: { md: "100%", xs: "88vw" } }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Game</TableCell>
                {decodedToken?.role !== "Staff" && <TableCell>Sales</TableCell>}
                {decodedToken?.role !== "Staff" && (
                  <TableCell>Revenue</TableCell>
                )}
                <TableCell>Bonus Amount</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created date</TableCell>
                <TableCell>Template</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagination.map((row) => {
                var nullCreate = row.createdAt.split("T");
                var dateCreate = nullCreate[0].split("-");
                var dateCreateAt =
                  dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];

                const images = row.photosAll.map(
                  (photoItem) =>
                    `https://d1wbec5n.website/${row.apitelegrams[0]?.image}/${photoItem.image}`
                );
                const imageUrls = images.join("\n ");
                const image =
                  imageUrls === "" ? "" : `\nPhotos: \n ${imageUrls}`;

                const budgetOrdersMaps = row.budgetsordersAll.map(
                  (budgetorder) => {
                    const usedQuantity = budgetorder.quantity;
                    const totalAmount =
                      Number(
                        budgetorder.quantity *
                          Number(budgetorder.budgetsById?.price) *
                          Number(budgetorder.walletsById?.amount)
                      ) / Number(budgetorder.walletsById?.price);

                    return {
                      ...budgetorder,
                      walletId: budgetorder.budgetsById?.walletId,
                      price: budgetorder.budgetsById?.price,
                      name: budgetorder.budgetsById?.name,
                      orderNumber: budgetorder.budgetsById?.orderNumber,
                      usedQuantity,
                      totalAmount,
                    };
                  }
                );

                const resbudgetOrdersMap = budgetOrdersMaps.filter(
                  (budget) => budget.orderId === row.id
                );
                const totalAmount = resbudgetOrdersMap.reduce(
                  (total, order) => total + parseFloat(order.totalAmount),
                  0
                );
                const nameBudget = resbudgetOrdersMap
                  .map(
                    (itemG) => `${itemG.name} x ${Number(itemG.usedQuantity)}`
                  )
                  .join(", ")
                  .trim();

                row.text = row.text?.replace("image", `${image}`);
                row.text = row.text?.replace("totalAmount", `${totalAmount}`);
                row.text = row.text?.replace("nameBudget", `${nameBudget}`);

                // phoneNumber: apiClients?.phoneNumber,
                // email: apiClients?.email,
                // primarychannelId: apiClients?.primarychannelId,

                return (
                  <TableRow
                    key={row.id}
                    style={{
                      background:
                        row.primarychannelId == 4
                          ? row.status == "pending"
                            ? "#ea80fc"
                            : "#cddc39"
                          : "",
                    }}
                  >
                    <TableCell>
                      <NavLink
                        className={styles.link2}
                        to={`/app/orders/${row.id}`}
                      >
                        {row.id}
                      </NavLink>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Copy" placement="top" arrow>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigator.clipboard.writeText(`${row.fullName}`);
                          }}
                        >
                          {row.fullName}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Images nameImage={row.imageGame} />
                    </TableCell>
                    {decodedToken?.role !== "Staff" && (
                      <TableCell>
                        {number.format(Number(row.salePrice))} ₫
                      </TableCell>
                    )}
                    {decodedToken?.role !== "Staff" && (
                      <TableCell>
                        {number.format(
                          Number(row.salePrice) - Number(row.basePrice)
                        )}{" "}
                        ₫
                      </TableCell>
                    )}
                    <TableCell>
                      {number.format(Number(row.bonusAmount))} ₫
                    </TableCell>
                    <TableCell>
                      {row.paymentStatus
                        .replace(/([A-Z])/g, " $1")
                        .toLowerCase()
                        .replace(/^./, function (str) {
                          return str.toUpperCase();
                        })}
                    </TableCell>
                    <TableCell>
                      {row.agencyId == 0 && row.status == "inProgress"
                        ? row.status
                            .replace(/([A-Z])/g, " $1")
                            .toLowerCase()
                            .replace(/^./, function (str) {
                              return str.toUpperCase();
                            })
                        : row.status == "inProgress"
                        ? `Moving agency: ${row?.nameGroup}`
                        : row.status
                            .replace(/([A-Z])/g, " $1")
                            .toLowerCase()
                            .replace(/^./, function (str) {
                              return str.toUpperCase();
                            })}
                    </TableCell>

                    <TableCell>{dateCreateAt}</TableCell>
                    <TableCell>
                      <HtmlTooltip
                        placement="left-start"
                        title={
                          <React.Fragment>
                            <Typography
                              color="inherit"
                              className={styles.textnote}
                            >
                              {row.text}
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <Button
                          variant="contained"
                          onClick={() =>
                            navigator.clipboard.writeText(`${row.text}`)
                          }
                          color="primary"
                          size="small"
                        >
                          Copy
                        </Button>
                      </HtmlTooltip>
                    </TableCell>
                    <TableCell size="small" align="center">
                      {row.status == "inProgress" &&
                        row.clientId !== 6622 &&
                        decodedToken?.role !== "Staff" && (
                          <IconButton
                            color="primary"
                            onClick={() =>
                              handleClickRepeat(
                                row.id,
                                DonePage,
                                row.agencyId,
                                "chuyen"
                              )
                            }
                          >
                            <RepeatIcon />
                          </IconButton>
                        )}
                      {row.status == "inProgress" &&
                        row.agencyId !== 0 &&
                        decodedToken?.role !== "Staff" && (
                          <IconButton
                            color="primary"
                            onClick={() =>
                              handleClickRepeat(
                                row.id,
                                DonePage,
                                row.agencyId,
                                "cancel-lo"
                              )
                            }
                          >
                            <CancelIcon />
                          </IconButton>
                        )}
                      {row.status == "completed" &&
                        decodedToken?.role !== "Staff" && (
                          <IconButton
                            color="primary"
                            onClick={() =>
                              handleClickRepeat(
                                row.id,
                                DonePage,
                                row.agencyId,
                                "undo_cancel"
                              )
                            }
                          >
                            <RestorePageIcon />
                          </IconButton>
                        )}

                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleClickRepeat(
                            row.id,
                            DonePage,
                            row.agencyId,
                            "update_info"
                          )
                        }
                      >
                        <BorderColorIcon />
                      </IconButton>

                      <NavLink to={`/app/orders/${row.id}`}>
                        <IconButton color="primary">
                          <AddToQueueIcon />
                        </IconButton>
                      </NavLink>
                      {(row.status == "inProgress" && <DoneIcon />) ||
                      (row.status == "completed" &&
                        decodedToken?.role === "Admin" && <DoneAllIcon />) ||
                      (row.status == "done" &&
                        decodedToken?.role == "Admin" && <UndoIcon />) ? (
                        <IconButton
                          color="primary"
                          onClick={() =>
                            handleClickOpen(
                              row.id,
                              DonePage,
                              `${row.status}`,
                              row.paymentStatus
                            )
                          }
                        >
                          {row.status == "inProgress" && <DoneIcon />}
                          {row.status == "completed" &&
                            decodedToken?.role == "Admin" && <DoneAllIcon />}
                          {row.status == "done" &&
                            decodedToken?.role == "Admin" && <UndoIcon />}
                        </IconButton>
                      ) : null}

                      {row.status === "inProgress" && row.status !== "done" && (
                        <IconButton
                          color="primary"
                          onClick={() =>
                            handleClickOpen(
                              row.id,
                              DonePage,
                              "delete",
                              row.paymentStatus
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}

                      {row.status !== "inProgress" &&
                        decodedToken?.role !== "Staff" &&
                        row.status !== "done" && (
                          <IconButton
                            color="primary"
                            onClick={() =>
                              handleClickOpen(
                                row.id,
                                DonePage,
                                "delete",
                                row.paymentStatus
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {decodedToken?.role == "Admin" && (
            <Grid
              container
              spacing={0}
              sx={{
                justifyContent: "center",
                color: "#000",
                display: "flex",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClickOpen(0, DonePage, "doneAllAll")}
                size="medium"
              >
                DONE ORDERS ALL
              </Button>
            </Grid>
          )}
          {decodedToken?.role == "Admin" && (
            <Grid
              container
              spacing={0}
              sx={{
                justifyContent: "center",
                color: "#000",
                display: "flex",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClickOpen(0, DonePage, "doneAll")}
                size="medium"
              >
                DONE ORDERS OF THIS PAGE
              </Button>
            </Grid>
          )}
          {pageCount > 1 && (
            <Grid
              container
              spacing={0}
              sx={{
                justifyContent: "center",
                color: "#000",
                display: "flex",
                padding: "10px",
              }}
            >
              <Pagination
                count={pageCount}
                page={currentPage === 0 ? 1 : currentPage}
                onChange={Pagechane}
                size="small"
                color="primary"
              />
            </Grid>
          )}
        </TableContainer>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent className={styles.textColor}>
          <DialogContentText>{messages}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent className={styles.textColor}>
          <DialogContentText className={styles.dialog}>
            {idProductRef_paymentStatus.current == "cancel-lo" &&
              "Are you sure to cancel this agency?"}
            {idProductRef_paymentStatus.current == "undo_cancel" &&
              "Are you sure to undo cancel this agency?"}

            {idProductRef_paymentStatus.current != "update_info" &&
              idProductRef_paymentStatus.current != "cancel-lo" &&
              idProductRef_paymentStatus.current != "undo_cancel" && (
                <Box
                  sx={{
                    marginTop: "0.5rem",
                    minWidth: {
                      xs: "auto",
                      sm: "auto",
                      md: "500px",
                      lg: "500px",
                    },
                  }}
                >
                  <Autocomplete
                    className={classes.Autocomplete}
                    options={agencys}
                    fullWidth
                    onChange={(event, value) => setSelectedagencys(value)}
                    getOptionLabel={(jsonResuls) => jsonResuls.nameGroup}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(selectedagencys === null)}
                        helperText={
                          selectedagencys === null ? "Agency is required" : ""
                        }
                        fullWidth
                        label="Agency"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              )}
            {idProductRef_paymentStatus.current == "update_info" && (
              <>
                <TextField
                  style={{ marginTop: `0.5rem` }}
                  onChange={handleChange}
                  value={update.info}
                  multiline
                  minRows="4"
                  name="info"
                  label="Info"
                  size="small"
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  style={{ marginTop: `0.5rem` }}
                  onChange={handleChange}
                  value={update.notesCode}
                  multiline
                  minRows="2"
                  name="notesCode"
                  label="Code"
                  size="small"
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  style={{ marginTop: `0.5rem` }}
                  onChange={handleChange}
                  value={update.notesLo}
                  multiline
                  minRows="3"
                  name="notesLo"
                  label="Notes for home"
                  size="small"
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  style={{ marginTop: `0.5rem` }}
                  onChange={handleChange}
                  value={update.notesClient}
                  multiline
                  minRows="3"
                  name="notesClient"
                  label="Notes for client"
                  size="small"
                  fullWidth
                  variant="outlined"
                />
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedID}
                        onChange={handleChangeCkeck}
                        name="checkedID"
                        color="primary"
                      />
                    }
                    label="Wrong ID."
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedCode}
                        onChange={handleChangeCkeck}
                        name="checkedCode"
                        color="primary"
                      />
                    }
                    label="Wrong code."
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedServer}
                        onChange={handleChangeCkeck}
                        name="checkedServer"
                        color="primary"
                      />
                    }
                    label="Wrong Server."
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedName}
                        onChange={handleChangeCkeck}
                        name="checkedName"
                        color="primary"
                      />
                    }
                    label="Wrong Character Name."
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedAcountPass}
                        onChange={handleChangeCkeck}
                        name="checkedAcountPass"
                        color="primary"
                      />
                    }
                    label="Wrong Account or Password."
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.eventConcluded}
                        onChange={handleChangeCkeck}
                        name="eventConcluded"
                        color="primary"
                      />
                    }
                    label="Event concluded."
                  />
                </FormGroup>
              </>
            )}
            {texts && <div style={{ color: "red" }}>{texts}</div>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleChuyen}
            disabled={TrueClick}
            autoFocus
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Orders;
const useStyles = makeStyles((theme) => ({
  Autocomplete: {
    minWidth: "500px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "300px",
    },
  },
}));
