import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const GridsDetailOrdersBudgets = (props) => {
  const { budgetOrders, userId } = props;
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <Grid container spacing={1} sx={{ margin: "4px 0 0 0px" }}>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            variant="h5"
            color="primary"
            sx={{ color: "#3f51b5", fontWeight: 500, fontSize: "1rem" }}
          >
            Budgets:
          </Typography>
        </Grid>
        <Grid item lg={10} md={10} sm={10} xs={9}>
          <Table aria-label="a dense table" disableRipple={true}>
            <TableHead>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(0, 1, 1, 0),
                }}
              >
                <Typography>Wallet</Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(0, 1, 1, 0),
                }}
              >
                <Typography>Budget</Typography>
              </TableCell>
              {userId.role == "Admin" && (
                <TableCell
                  sx={{
                    border: 0,
                    padding: (theme) => theme.spacing(0, 1, 1, 0),
                  }}
                >
                  <Typography>Price</Typography>
                </TableCell>
              )}
            </TableHead>
            <TableBody>
              {budgetOrders.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell
                      sx={{
                        border: 0,
                        padding: (theme) => theme.spacing(0, 1, 1, 0),
                      }}
                    >
                      <Typography variant="subtitle1">
                        {item.nameWallet}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 0,
                        padding: (theme) => theme.spacing(0, 1, 1, 0),
                      }}
                    >
                      <Typography variant="subtitle1">
                        {item.nameBudget}
                      </Typography>
                    </TableCell>
                    {userId.role == "Admin" && (
                      <TableCell
                        sx={{
                          border: 0,
                          padding: (theme) => theme.spacing(0, 1, 1, 0),
                        }}
                      >
                        <Typography variant="subtitle1">
                          {number.format(item.priceBudget) +
                            " ₫ x " +
                            number.format(item.quantity)}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  );
};

export default GridsDetailOrdersBudgets;
