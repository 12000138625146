import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import axiosusers from "utils/api/axiosusers";
import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import useUserId from "hooks/useUserId";
import UndoIcon from "@mui/icons-material/Undo";
import _ from "lodash";
const GiftCodes = () => {
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const idProductRef = useRef();
  const idProductRefpage = useRef();
  const idProductRef_name = useRef();
  const [loading, setLoading] = useState(false);

  const [Message, setMessage] = useState(false);
  const [DonePage, setDonePage] = useState([]);
  const [open, setOpen] = useState(false);

  const { userId } = useUserId();
  const [searchCode, setSearchCode] = useState("");
  const [searchOrderId, setSearchOrderId] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const pageSize = 10;

  const [pageCount, setPageCount] = useState(0);
  const fetchData = async () => {
    Promise.all([axiosusers.get(`/giftcard/giftcode`)])
      .then((results) => {
        const [transactions] = results.map((res) => res.data);

        setPageCount(Math.ceil(transactions.length / pageSize));
        setDonePage(transactions);
        setProduct(transactions);
        setPagination(_(transactions).slice(0).take(pageSize).value());
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    if (userId) {
      fetchData();
    }
  }, [userId]);
  useEffect(() => {
    let results = product;
    setCurrentPage(1);
    if (searchCode) {
      const numberRegex = new RegExp(`.*${searchCode}.*`, "i");
      results = results.filter((item) => numberRegex.test(item.code));
    }
    if (searchOrderId) {
      const numberRegex = new RegExp(`.*${searchOrderId}.*`, "i");
      results = results.filter((item) => numberRegex.test(item.orderId));
    }
    if (searchStatus) {
      results = results.filter((item) => item.status === searchStatus);
    }
    setPageCount(Math.ceil(results.length / pageSize));
    setDonePage(results);
    setPagination(_(results).slice(0).take(pageSize).value());
  }, [searchCode, searchOrderId, searchStatus, product]);

  setTimeout(function () {
    setMessage(false);
  }, 200);
  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes-item1/delete-giftcode-${idProductRef_name.current}`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        if (idProductRef_name.current === "Delete") {
          setProduct(
            product.filter((item) => {
              return item.id !== idProductRef.current;
            })
          );
        } else if (idProductRef_name.current === "Undo") {
          fetchData();
        }
      }
    } catch (error) {
      console.log(error);
    }

    setMessage(true);
    setOpen(false);
  };
  const handleClickOpen = (id, page, name) => {
    setOpen(true);
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Pagechane = (event, value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(DonePage).slice(startIndex).take(pageSize).value();
    setPagination(pagination);
    setCurrentPage(value);
    setMessage(true);
  };

  return (
    <div>
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <FormControl
                className={styles.formControl}
                fullWidth
                variant="outlined"
              >
                <InputLabel>Gift status</InputLabel>
                <Select
                  value={searchStatus}
                  onChange={(e) => setSearchStatus(e.target.value)}
                  label="Gift status"
                  name="status"
                >
                  <MenuItem value="Used status" className={styles.select}>
                    Used status
                  </MenuItem>
                  <MenuItem value="Not used status" className={styles.select}>
                    Not used status
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <OutlinedInput
                fullWidth
                onChange={(e) => setSearchCode(e.target.value)}
                placeholder="Search by code"
                name="code"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <OutlinedInput
                fullWidth
                onChange={(e) => setSearchOrderId(e.target.value)}
                placeholder="Search by order id"
                name="orderId"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {Message && (
        <div className={styles.center}>
          <CircularProgress />
        </div>
      )}
      {loading && (
        <div className={styles.center}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <TableContainer component={Paper}>
          <Table className={styles.table} aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Price</TableCell>
                <TableCell>Order Id</TableCell>
                <TableCell>code</TableCell>
                <TableCell>status</TableCell>
                <TableCell>Created date</TableCell>
                <TableCell>Updated date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagination.map((row) => {
                const nullCreate = row.createdAt.split("T");
                const dateCreate = nullCreate[0].split("-");
                const dateCreateAt =
                  dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];

                const nullUpdate = row.updatedAt.split("T");
                const dateUpdate = nullUpdate[0].split("-");
                const dateUpdateAt =
                  dateUpdate[2] + "/" + dateUpdate[1] + "/" + dateUpdate[0];

                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.amount} $</TableCell>
                    <TableCell>{row.orderId}</TableCell>
                    <TableCell
                      style={{
                        color: row.status != "Used status" ? "red" : "blue",
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip
                        onClick={() => {
                          navigator.clipboard.writeText(`${row.code}`);
                        }}
                        title="Copy"
                        placement="top"
                        arrow
                      >
                        {row.code}
                      </Tooltip>
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{dateCreateAt}</TableCell>
                    <TableCell>{dateUpdateAt}</TableCell>

                    <TableCell size="small" align="center">
                      {row.status === "Used status" && (
                        <IconButton
                          color="primary"
                          onClick={() =>
                            handleClickOpen(row.id, DonePage, "Undo")
                          }
                        >
                          <UndoIcon />
                        </IconButton>
                      )}
                      {userId?.role == "Admin" && (
                        <IconButton
                          color="primary"
                          onClick={() =>
                            handleClickOpen(row.id, DonePage, "Delete")
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {pageCount > 0 && (
            <Grid container spacing={0} className={styles.trang}>
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={Pagechane}
                size="small"
                color="primary"
              />
            </Grid>
          )}
        </TableContainer>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to{" "}
            {idProductRef_name.current === "limit"
              ? "Stop"
              : idProductRef_name.current === "trustSeller"
              ? "Default"
              : idProductRef_name.current}{" "}
            this gift code?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GiftCodes;
