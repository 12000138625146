import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import Administrators from "./Administrators";
import Groups from "./Groups";
import Fanpage from "./Fanpage";
import SalesArticleNotes from "./SalesArticleNotes";
import Groups3Icon from "@mui/icons-material/Groups3";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

export default function BottomNavigations() {
  const fb = localStorage.getItem("FacebookManage");

  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    if (fb) {
      setValue(parseInt(fb));
    }
  }, [fb]);
  const onChange = (newValue) => {
    localStorage.setItem("FacebookManage", newValue);
  };
  return (
    <Box>
      <Box sx={{ maxWidth: 600, margin: "auto" }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            onChange(newValue);
          }}
        >
          <BottomNavigationAction
            label="Administrators"
            icon={<Groups3Icon />}
          />

          <BottomNavigationAction label="Groups" icon={<Diversity3Icon />} />
          <BottomNavigationAction label="Fanpages" icon={<Diversity1Icon />} />
          <BottomNavigationAction
            label="Sales Article Notes"
            icon={<SpeakerNotesIcon />}
          />
        </BottomNavigation>
      </Box>
      {value == 0 && <Administrators />}
      {value == 1 && <Groups />}
      {value == 2 && <Fanpage />}
      {value == 3 && <SalesArticleNotes />}
    </Box>
  );
}
