import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const AddClients = () => {
  Tabtitle("Create Gift");
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);

  const initialValues = {
    amount: "",
    no: "",
  };
  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .min(0.00000001, "Price amount must be greater than zero")
      .required("Price is required"),
    no: Yup.number().required("No is required"),
  });
  const [image, setImage] = React.useState();
  const [avatar, setAvatar] = React.useState();

  const handleFile = (e) => {
    const file = e.target.files[0];
    setImage(file);

    file.preview = URL.createObjectURL(file);
    setAvatar(file);
  };
  const onSubmit = async (values) => {
    setDung(true);
    if (image?.size > 2 * 1024 * 1024) {
      setDung(false);
      return seterrorMessage("size must be less than 2MB");
    }
    const formData = new FormData();
    const datatime = new Date().getTime();
    formData.append("no", values.no);
    formData.append("amount", Number(values.amount));
    formData.append("file", image);
    formData.append("datatime", datatime);
    try {
      const response = await axiosusers.post(`/uploads/gift`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      setDung(false);
      if (response.status === 200) {
        navigate("/app/gift-home/");
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(error.response.data.message);
      setDung(false);
    }
  };
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new gift home
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="no"
                label="No."
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.no && props.touched.no}
                helperText={<ErrorMessage name="no" />}
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="amount"
                label="Price"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.amount && props.touched.amount}
                helperText={<ErrorMessage name="amount" />}
              />
              {avatar && <Avatar src={avatar.preview} variant="square" />}
              <input
                accept="image/*"
                onChange={handleFile}
                id="file"
                name="img"
                multiple
                type="file"
                style={{
                  display: "none",
                  color: "#3f50b5",
                  margin: (theme) => theme.spacing(2, 0),
                }}
              />
              <label htmlFor="file">
                <Button component="span" color="primary">
                  UPLOAD PHOTOS
                </Button>
              </label>
              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddClients;
