import React from "react";
import AddBlacklists from "./AddBlacklists";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Create Blacklists");
  return (
    <div>
      <AddBlacklists />
    </div>
  );
};

export default index;
