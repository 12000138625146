import React from "react";
import Payments180Days from "./Payments180Days";
import { Tabtitle } from "components/title/Title";
import axiosusers from "utils/api/axiosusers";
import useSWR from "swr";
import { CircularProgress } from "@mui/material";

const index = () => {
  Tabtitle("Payments 180 Days");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const {
    data: paymentsData,
    error: paymentsError,
    isLoading: paymentsLoading,
  } = useSWR(`/payments/payments-180days`, fetchDataSwr);

  const {
    data: withdrawsData,
    error: withdrawsError,
    isLoading: withdrawsisLoading,
  } = useSWR(`/payments/withdraws-180days`, fetchDataSwr);

  const {
    data: transactionsAmoutData,
    error: transactionsAmoutError,
    isLoading: transactionsAmoutisLoading,
  } = useSWR(`/transactions/transactions-amount`, fetchDataSwr);

  return (
    <div>
      {paymentsError && withdrawsError && transactionsAmoutError && (
        <div>failed to load</div>
      )}
      {paymentsLoading && transactionsAmoutisLoading && withdrawsisLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {!paymentsLoading &&
        !transactionsAmoutisLoading &&
        !withdrawsisLoading && (
          <Payments180Days
            payments={paymentsData}
            transactionsAmount={transactionsAmoutData}
            withdraws={withdrawsData}
          />
        )}
    </div>
  );
};

export default index;
