import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useDecodedToken from "utils/token/useDecodedToken";
import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axiosusers from "utils/api/axiosusers";
import _ from "lodash";
import { NavLink } from "react-router-dom";

const ProductCategory = () => {
  const { decodedToken } = useDecodedToken();
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const idProductRef = useRef();
  const idProductRefpage = useRef();
  const idProductRef_name = useRef();
  const [loading, setLoading] = useState(false);

  const [Message, setMessage] = useState(false);
  const [DonePage, setDonePage] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchCode, setSearchCode] = useState("");

  const pageSize = 10;
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    setLoading(true);
    Promise.all([axiosusers.get(`/wallets/walletsproduct`)])
      .then((results) => {
        const [orders] = results.map((res) => res.data);
        setDonePage(orders);
        setPageCount(Math.ceil(orders.length / pageSize));
        setProduct(orders);
        setPagination(_(orders).slice(0).take(pageSize).value());
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    let results = product;
    setCurrentPage(1);
    if (searchCode !== "") {
      const numberRegex = new RegExp(`.*${searchCode}.*`, "i");
      results = results.filter((item) => numberRegex.test(item.name));
    }
    setPageCount(Math.ceil(results.length / pageSize));
    setDonePage(results);
    setPagination(_(results).slice(0).take(pageSize).value());
  }, [searchCode, product]);
  setTimeout(function () {
    setMessage(false);
  }, 200);
  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes-item1/delete-product-category`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        setProduct(
          product.filter((item) => {
            return item.id !== idProductRef.current;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }

    setMessage(true);
    setOpen(false);
  };
  const handleClickOpen = (id, page, name) => {
    setOpen(true);
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Pagechane = (event, value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(DonePage).slice(startIndex).take(pageSize).value();
    setPagination(pagination);
    setCurrentPage(value);
    setMessage(true);
  };

  return (
    <div>
      {decodedToken?.role == "Admin" && (
        <NavLink to="/app/product-category/add" className={styles.link}>
          <Button variant="contained" color="primary">
            ADD product category
          </Button>
        </NavLink>
      )}
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <OutlinedInput
                fullWidth
                onChange={(e) => setSearchCode(e.target.value)}
                placeholder="Search by  name"
                name="code"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {Message && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {loading && (
        <div className={styles.center}>
          <CircularProgress />
        </div>
      )}

      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Created date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {pagination.map((row) => {
              var nullCreate = row.createdAt.split("T");
              var dateCreate = nullCreate[0].split("-");
              var dateCreateAt =
                dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
              return (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.notes}</TableCell>
                  <TableCell>{dateCreateAt}</TableCell>

                  <TableCell size="small" align="center">
                    <NavLink to={`/app/product-category/edit/${row.id}`}>
                      <IconButton color="primary">
                        <EditIcon />
                      </IconButton>
                    </NavLink>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        handleClickOpen(row.id, DonePage, "Delete")
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {product.length > 10 && (
          <Grid
            container
            spacing={0}
            sx={{
              justifyContent: "center",
              color: "#000",
              display: "flex",
              padding: "10px",
            }}
          >
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={Pagechane}
              size="small"
              color="primary"
            />
          </Grid>
        )}
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to Delete this fanpages?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductCategory;
