import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import React from "react";
import styles from "./style.module.css";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import axiosusers from "utils/api/axiosusers";
import useUserId from "hooks/useUserId";
import Images from "components/Avatar/Images";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import { NavLink } from "react-router-dom";
const Payments = (props) => {
  const { payments, withdraws, transactionsAmount } = props;
  const { userId } = useUserId();

  const [done, setDone] = React.useState(false);

  const [product, setProduct] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pagination, setPagination] = React.useState([]);
  const pageSize = 100;
  const [pageCount, setPageCount] = React.useState(0);
  const idProductRef = React.useRef();
  const idProductRefpage = React.useRef();
  const idProductRef_name = React.useRef();
  const [Message, setMessage] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [DonePage, setDonePage] = React.useState([]);
  const [Withdraw, setWithdraw] = React.useState([]);
  const [transactions, setTransactions] = React.useState([]);

  React.useEffect(() => {
    setProduct(payments);
    setDonePage(payments);
    setPageCount(Math.ceil(payments.length / pageSize));
    setPagination(_(payments).slice(0).take(pageSize).value());
    setWithdraw(withdraws);
    setTransactions(transactionsAmount);
  }, [payments, withdraws, transactionsAmount]);

  const handleClickOpen = (id, page, name) => {
    setOpen(true);
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };
  const Pagechane = (event, value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(DonePage).slice(startIndex).take(pageSize).value();
    setPagination(pagination);
    setCurrentPage(value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes/delete-payments`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        setProduct(
          product.filter((item) => {
            return item.id !== idProductRef.current;
          })
        );
        setPagination(
          pagination.filter((item) => {
            return item.id !== idProductRef.current;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }

    setMessage(true);
    setTimeout(function () {
      setMessage(false);
    }, 200);
    setOpen(false);
  };
  setTimeout(function () {
    setMessage(false);
    setDone(false);
  }, 200);
  const chanDone = () => {
    setDone(true);
  };
  const filteredItems = product.filter(
    (item) => item.status !== "limit_180_day"
  );

  const { balancep, onholdp } = filteredItems.reduce(
    (acc, item) => {
      const balanceMultiplier = item.currency === "USD" ? 22200 : 1;

      const balancep1 = item.balance * balanceMultiplier;
      const onholdp1 = item.onHoldBalance * balanceMultiplier;

      return {
        balancep: acc.balancep + balancep1,
        onholdp: acc.onholdp + onholdp1,
      };
    },
    { balancep: 0, onholdp: 0 }
  );

  const withdrawsToMap = Withdraw;
  let withdrawp = 0;
  withdrawsToMap.forEach((item) => {
    withdrawp += Number(item.amount);
  });

  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const numberthem = new Intl.NumberFormat("en-NZ", {
    minimumFractionDigits: 2,
  });
  const transactionApi = transactions;
  return (
    <div>
      <NavLink to="/app/payments/add" className={styles.link}>
        <Button variant="contained" color="primary">
          ADD payments
        </Button>
      </NavLink>
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid lg={3} md={3} item sm={3} xs={3}>
              <Typography variant="subtitle2" color="primary">
                Total
              </Typography>
            </Grid>
            <Grid lg={3} md={3} item sm={3} xs={3}>
              <Typography variant="subtitle2" color="primary">
                Total Balance
              </Typography>
            </Grid>
            <Grid lg={3} md={3} item sm={3} xs={3}>
              <Typography variant="subtitle2" color="primary">
                Total On Hold
              </Typography>
            </Grid>
            <Grid lg={3} md={3} item sm={3} xs={3}>
              <Typography variant="subtitle2" color="primary">
                Total Withdraw
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid lg={3} md={3} item sm={3} xs={3}>
              <Typography className={styles.text}>
                {number.format(balancep + onholdp + withdrawp)} ₫
              </Typography>
            </Grid>
            <Grid lg={3} md={3} item sm={3} xs={3}>
              <Typography>{number.format(balancep)} ₫</Typography>
            </Grid>
            <Grid lg={3} md={3} item sm={3} xs={3}>
              <Typography>{number.format(onholdp)} ₫</Typography>
            </Grid>
            <Grid lg={3} md={3} item sm={3} xs={3}>
              <Typography>{number.format(withdrawp)} ₫</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {Message && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}

      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>.No</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Type</TableCell>
              {userId?.role == "Admin" && <TableCell>Bank Name</TableCell>}
              <TableCell>Status</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Monthly Balance</TableCell>
              <TableCell>On hold</TableCell>
              {userId?.role == "Admin" && <TableCell>Withdraw 1</TableCell>}
              {userId?.role == "Admin" && <TableCell>Withdraw 2</TableCell>}
              <TableCell>VPS</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell></TableCell>
              {userId?.role == "Admin" && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {pagination
              .filter((item) => item.status !== "limit_180_day")
              .map((row) => {
                const createdAtValue = Withdraw.filter(
                  (item2) => row.id === item2.paymentId
                ).slice(0)?.[0]?.createdAt;

                // Assuming createdAtValue is a date string
                const formattedCreatedAt = createdAtValue
                  ? new Date(createdAtValue).toLocaleString("en-US", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  : "";

                const createdAtValue2 = Withdraw.filter(
                  (item2) => row.id === item2.paymentId
                ).slice(1)?.[0]?.createdAt;

                // Assuming createdAtValue is a date string
                const formattedCreatedAt2 = createdAtValue2
                  ? new Date(createdAtValue).toLocaleString("en-US", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  : "";
                return (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.orderNumber}
                    </TableCell>
                    <TableCell
                      style={
                        row.status === "weak"
                          ? { color: "blue" }
                          : row.status === "limit"
                          ? { background: "red" }
                          : row.status === "limit_180_day"
                          ? { background: "red" }
                          : null
                      }
                    >
                      <Tooltip
                        title={done ? "Copied!" : "Copy"}
                        placement="top"
                        arrow
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigator.clipboard.writeText(`${row.owner}`);
                            chanDone();
                          }}
                        >
                          <pre style={{ whiteSpace: "pre" }}>
                            {row.owner.length > 50
                              ? row.owner
                              : row.owner.slice(0, 25) + "..."}
                          </pre>
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Images nameImage={`${row.type}.png`} />
                    </TableCell>
                    {userId?.role == "Admin" && (
                      <TableCell>{row.bank}</TableCell>
                    )}
                    <TableCell
                      style={
                        row.status === "weak"
                          ? { color: "blue" }
                          : row.status === "limit"
                          ? { color: "red" }
                          : row.status === "limit_180_day"
                          ? { color: "red" }
                          : null
                      }
                    >
                      {row.status
                        .replace(/([A-Z])/g, " $1")
                        .toLowerCase()
                        .replace(/^./, function (str) {
                          return str.toUpperCase();
                        })}
                    </TableCell>

                    <TableCell>
                      {row.currency == "VND"
                        ? number.format(row.balance) + ` ₫`
                        : numberthem
                            .format(Math.round(row.balance * 100) / 100)
                            .replace(".", ",") + ` $`}
                    </TableCell>
                    <TableCell>
                      {row.currency == "VND"
                        ? number.format(
                            transactionApi
                              .filter(
                                (transactionAmount) =>
                                  row.id === transactionAmount.paymentId
                              )
                              .reduce(
                                (total, item) =>
                                  Number(total) + Number(item.amount),
                                0
                              )
                          ) + ` ₫`
                        : numberthem
                            .format(
                              Math.round(
                                (transactionApi
                                  .filter(
                                    (transactionAmount) =>
                                      row.id === transactionAmount.paymentId
                                  )
                                  .reduce(
                                    (total, item) =>
                                      Number(total) + Number(item.amount),
                                    0
                                  ) /
                                  22200) *
                                  100
                              ) / 100
                            )
                            .replace(".", ",") + ` $`}
                    </TableCell>
                    <TableCell>
                      {row.currency == "VND"
                        ? number.format(row.onHoldBalance) + ` ₫`
                        : numberthem
                            .format(Math.round(row.onHoldBalance * 100) / 100)
                            .replace(".", ",") + ` $`}
                    </TableCell>
                    {userId?.role == "Admin" && (
                      <TableCell>
                        {number.format(
                          Withdraw.filter(
                            (item2) => row.id === item2.paymentId
                          ).slice(0)?.[0]?.amount ?? 0
                        ) == 0
                          ? ""
                          : number.format(
                              Withdraw.filter(
                                (item2) => row.id === item2.paymentId
                              ).slice(0)?.[0]?.amount
                            ) + ` ₫ \n`}
                        {formattedCreatedAt}
                      </TableCell>
                    )}
                    {userId?.role == "Admin" && (
                      <TableCell>
                        {number.format(
                          Withdraw.filter(
                            (item2) => row.id === item2.paymentId
                          ).slice(1)?.[0]?.amount ?? 0
                        ) == 0
                          ? ""
                          : number.format(
                              Withdraw.filter(
                                (item2) => row.id === item2.paymentId
                              ).slice(1)?.[0]?.amount
                            ) + ` ₫ \n`}
                        {formattedCreatedAt2}
                      </TableCell>
                    )}

                    <TableCell>{row.vps}</TableCell>

                    <TableCell>
                      <Tooltip
                        title={done ? "Copied!" : "Copy"}
                        placement="top"
                        arrow
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigator.clipboard.writeText(`${row.notes}`);
                            chanDone();
                          }}
                        >
                          <pre>{row.notes}</pre>
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() =>
                          navigator.clipboard.writeText(`${row.owner}`)
                        }
                        color="primary"
                        size="small"
                      >
                        Copy
                      </Button>
                    </TableCell>
                    {userId?.role == "Admin" && (
                      <TableCell align="center" size="small">
                        <NavLink to={`/app/payments/${row.id}`}>
                          <IconButton color="primary">
                            <AddToQueueIcon />
                          </IconButton>
                        </NavLink>
                        <NavLink to={`/app/payments/edit/${row.id}`}>
                          <IconButton color="primary">
                            <EditIcon />
                          </IconButton>
                        </NavLink>
                        {row.id != 19 && (
                          <IconButton
                            color="primary"
                            onClick={() =>
                              handleClickOpen(row.id, DonePage, "Delete")
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {product.length > 100 && (
          <Grid container spacing={0}>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={Pagechane}
              size="small"
              color="primary"
            />
          </Grid>
        )}
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to delete this blacklist?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Payments;
