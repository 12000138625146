import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Box,
  Button,
  Fab,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";

import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Tabtitle } from "components/title/Title";
import { useNavigate } from "react-router-dom";
const AddClientsYoutube = () => {
  Tabtitle("Create Product Category");
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [inputFields, setInputFields] = React.useState([
    { id: uuidv4(), name: "", quantity: "", price: "", orderNumber: "1" },
  ]);
  const initialValues = {
    name: "",
    price: "",
    amount: "",
    description: "",
    notes: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(0, "It's too short")
      .required("Wallet name is required"),
  });
  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    inputFields.map((item, i) => {
      setInputFields([
        ...inputFields,
        { id: uuidv4(), name: "", quantity: "", price: "", orderNumber: i + 1 },
      ]);
    });
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };
  const onSubmit = async (values) => {
    setDung(true);
    let errorMessage = "";
    inputFields.forEach((item) => {
      if (item.name === "" || item.price === "" || item.quantity === "") {
        errorMessage = "The data budgets invalid. Please check again";
      }
    });
    if (errorMessage !== "") {
      return seterrorMessage(errorMessage);
    }
    const data = {
      name: values.name,
      description: values.description,
      inputFields,
      notes: values.notes,
    };
    try {
      await axiosusers.post(`/wallets/create-wallets-product`, data);
      navigate("/app/product-category/");
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
    }
  };
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new product category
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="name"
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.name && props.touched.name}
                helperText={<ErrorMessage name="name" />}
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="description"
                label="Description"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Typography
                variant="subtitle1"
                sx={{ marginTop: `0.5rem`, fontWeight: 500 }}
              >
                Budgets:
              </Typography>
              {inputFields.map((inputField, id) => (
                <div key={inputField.id} style={{ display: "flex" }}>
                  <TextField
                    type="text"
                    name="name"
                    value={inputField.name}
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                    label="Name"
                    variant="outlined"
                    size="small"
                  />

                  <div style={{ margin: "0px 10px" }}>
                    <TextField
                      type="number"
                      name="quantity"
                      value={inputField.quantity}
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                      label="Quantity"
                      variant="outlined"
                      size="small"
                    />
                  </div>
                  <TextField
                    sx={{ width: "25ch" }}
                    type="number"
                    name="price"
                    value={inputField.price}
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                    label="Price"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <div>
                          {inputFields.length === Number(id + 1) && (
                            <Fab
                              color="primary"
                              aria-label="add"
                              size="small"
                              onClick={handleAddFields}
                            >
                              <AddIcon />
                            </Fab>
                          )}
                        </div>
                      ),
                    }}
                  />
                  {inputFields.length !== 1 && (
                    <IconButton
                      color="primary"
                      onClick={() => handleRemoveFields(inputField.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              ))}
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                multiline
                minRows="4"
                name="notes"
                label="Notes"
                variant="outlined"
                size="small"
                fullWidth
              />
              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddClientsYoutube;
