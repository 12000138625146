import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import useUserId from "hooks/useUserId";
import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";

const AddPrimaryChannel = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const initialValues = {
    channelName: "",
    phoneNumber: "",
    telegramUser: "",
    lineUser: "",
    instagramUser: "",
    panpageFacebook: "",
  };
  const validationSchema = Yup.object().shape({
    channelName: Yup.string()
      .min(0, "It's too short")
      .required("Name is required"),
  });
  const onSubmit = async (values) => {
    setDung(true);
    seterrorMessage("");
    if (userId) {
      const data = {
        channelName: values.channelName,
        phoneNumber: values.phoneNumber,
        telegramUser: values.telegramUser,
        lineUser: values.lineUser,
        instagramUser: values.instagramUser,
        panpageFacebook: values.panpageFacebook,
      };

      try {
        const resbudgets = await axiosusers.post(
          `/primarychannel/create-primarychannel`,
          data
        );

        if (resbudgets.status === 200) {
          console.log(resbudgets);
          setDung(true);
          navigate("/app/primary-channel");
        }
      } catch (error) {
        console.log(error);
        setDung(false);
      }
    }
  };
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
          }}
        >
          Create new gift cards
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                sx={{ marginTop: `1.5rem` }}
                as={TextField}
                name="channelName"
                label="Channel Name"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.channelName && props.touched.channelName}
                helperText={<ErrorMessage name="channelName" />}
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="telegramUser"
                label="Telegram User"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="lineUser"
                label="Line User"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="instagramUser"
                label="Instagram User"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="panpageFacebook"
                label="Fanpage"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="phoneNumber"
                label="Phone Number"
                variant="outlined"
                size="small"
                fullWidth
              />

              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddPrimaryChannel;
