const useCountryCode = () => {
  const countries = [
    { code: "AD", label: "Andorra", phone: "376", countryCode: "" },
    {
      code: "AE",
      label: "United Arab Emirates",
      phone: "971",
      countryCode: "",
    },
    { code: "AF", label: "Afghanistan", phone: "93", countryCode: "" },
    {
      code: "AG",
      label: "Antigua and Barbuda",
      phone: "1-268",
      countryCode: "",
    },
    { code: "AI", label: "Anguilla", phone: "1-264", countryCode: "" },
    { code: "AL", label: "Albania", phone: "355", countryCode: "" },
    { code: "AM", label: "Armenia", phone: "374", countryCode: "" },
    { code: "AO", label: "Angola", phone: "244", countryCode: "" },
    { code: "AQ", label: "Antarctica", phone: "672", countryCode: "" },
    { code: "AR", label: "Argentina", phone: "54", countryCode: "" },
    { code: "AS", label: "American Samoa", phone: "1-684", countryCode: "" },
    { code: "AT", label: "Austria", phone: "43", countryCode: "" },
    {
      code: "AU",
      label: "Australia",
      phone: "61",
      suggested: true,
      countryCode: "",
    },
    { code: "AW", label: "Aruba", phone: "297", countryCode: "" },
    { code: "AX", label: "Alland Islands", phone: "358", countryCode: "" },
    { code: "AZ", label: "Azerbaijan", phone: "994", countryCode: "" },
    {
      code: "BA",
      label: "Bosnia and Herzegovina",
      phone: "387",
      countryCode: "",
    },
    { code: "BB", label: "Barbados", phone: "1-246", countryCode: "" },
    { code: "BD", label: "Bangladesh", phone: "880", countryCode: "" },
    { code: "BE", label: "Belgium", phone: "32", countryCode: "" },
    { code: "BF", label: "Burkina Faso", phone: "226", countryCode: "" },
    { code: "BG", label: "Bulgaria", phone: "359", countryCode: "" },
    { code: "BH", label: "Bahrain", phone: "973", countryCode: "" },
    { code: "BI", label: "Burundi", phone: "257", countryCode: "" },
    { code: "BJ", label: "Benin", phone: "229", countryCode: "" },
    { code: "BL", label: "Saint Barthelemy", phone: "590", countryCode: "" },
    { code: "BM", label: "Bermuda", phone: "1-441", countryCode: "" },
    { code: "BN", label: "Brunei Darussalam", phone: "673", countryCode: "" },
    { code: "BO", label: "Bolivia", phone: "591", countryCode: "" },
    { code: "BR", label: "Brazil", phone: "55", countryCode: "" },
    { code: "BS", label: "Bahamas", phone: "1-242", countryCode: "" },
    { code: "BT", label: "Bhutan", phone: "975", countryCode: "" },
    { code: "BV", label: "Bouvet Island", phone: "47", countryCode: "" },
    { code: "BW", label: "Botswana", phone: "267", countryCode: "" },
    { code: "BY", label: "Belarus", phone: "375", countryCode: "" },
    { code: "BZ", label: "Belize", phone: "501", countryCode: "" },
    {
      code: "CA",
      label: "Canada",
      phone: "1",
      suggested: true,
      countryCode: "",
    },
    {
      code: "CC",
      label: "Cocos (Keeling) Islands",
      phone: "61",
      countryCode: "",
    },
    {
      code: "CD",
      label: "Congo, Democratic Republic of the",
      phone: "243",
      countryCode: "",
    },
    {
      code: "CF",
      label: "Central African Republic",
      phone: "236",
      countryCode: "",
    },
    {
      code: "CG",
      label: "Congo, Republic of the",
      phone: "242",
      countryCode: "",
    },
    { code: "CH", label: "Switzerland", phone: "41", countryCode: "" },
    { code: "CI", label: "Cote d'Ivoire", phone: "225", countryCode: "" },
    { code: "CK", label: "Cook Islands", phone: "682", countryCode: "" },
    { code: "CL", label: "Chile", phone: "56", countryCode: "" },
    { code: "CM", label: "Cameroon", phone: "237", countryCode: "" },
    { code: "CN", label: "China", phone: "86", countryCode: "" },
    { code: "CO", label: "Colombia", phone: "57", countryCode: "" },
    { code: "CR", label: "Costa Rica", phone: "506", countryCode: "" },
    { code: "CU", label: "Cuba", phone: "53", countryCode: "" },
    { code: "CV", label: "Cape Verde", phone: "238", countryCode: "" },
    { code: "CW", label: "Curacao", phone: "599", countryCode: "" },
    { code: "CX", label: "Christmas Island", phone: "61", countryCode: "" },
    { code: "CY", label: "Cyprus", phone: "357", countryCode: "" },
    { code: "CZ", label: "Czech Republic", phone: "420", countryCode: "" },
    {
      code: "DE",
      label: "Germany",
      phone: "49",
      suggested: true,
      countryCode: "",
    },
    { code: "DJ", label: "Djibouti", phone: "253", countryCode: "" },
    { code: "DK", label: "Denmark", phone: "45", countryCode: "" },
    { code: "DM", label: "Dominica", phone: "1-767", countryCode: "" },
    {
      code: "DO",
      label: "Dominican Republic",
      phone: "1-809",
      countryCode: "",
    },
    { code: "DZ", label: "Algeria", phone: "213", countryCode: "" },
    { code: "EC", label: "Ecuador", phone: "593", countryCode: "" },
    { code: "EE", label: "Estonia", phone: "372", countryCode: "" },
    { code: "EG", label: "Egypt", phone: "20", countryCode: "" },
    { code: "EH", label: "Western Sahara", phone: "212", countryCode: "" },
    { code: "ER", label: "Eritrea", phone: "291", countryCode: "" },
    {
      code: "ES",
      label: "Spain",
      phone: "34",
      countryCode: "es",
    },
    { code: "ET", label: "Ethiopia", phone: "251", countryCode: "" },
    { code: "FI", label: "Finland", phone: "358", countryCode: "" },
    { code: "FJ", label: "Fiji", phone: "679", countryCode: "" },
    {
      code: "FK",
      label: "Falkland Islands (Malvinas)",
      phone: "500",
      countryCode: "",
    },
    {
      code: "FM",
      label: "Micronesia, Federated States of",
      phone: "691",
      countryCode: "",
    },
    { code: "FO", label: "Faroe Islands", phone: "298", countryCode: "" },
    {
      code: "FR",
      label: "France",
      phone: "33",
      suggested: true,
      countryCode: "",
    },
    { code: "GA", label: "Gabon", phone: "241", countryCode: "" },
    { code: "GB", label: "United Kingdom", phone: "44", countryCode: "" },
    { code: "GD", label: "Grenada", phone: "1-473", countryCode: "" },
    { code: "GE", label: "Georgia", phone: "995", countryCode: "" },
    { code: "GF", label: "French Guiana", phone: "594", countryCode: "" },
    { code: "GG", label: "Guernsey", phone: "44", countryCode: "" },
    { code: "GH", label: "Ghana", phone: "233", countryCode: "" },
    { code: "GI", label: "Gibraltar", phone: "350", countryCode: "" },
    { code: "GL", label: "Greenland", phone: "299", countryCode: "" },
    { code: "GM", label: "Gambia", phone: "220", countryCode: "" },
    { code: "GN", label: "Guinea", phone: "224", countryCode: "" },
    { code: "GP", label: "Guadeloupe", phone: "590", countryCode: "" },
    { code: "GQ", label: "Equatorial Guinea", phone: "240", countryCode: "" },
    { code: "GR", label: "Greece", phone: "30", countryCode: "" },
    {
      code: "GS",
      label: "South Georgia and the South Sandwich Islands",
      phone: "500",
      countryCode: "",
    },
    { code: "GT", label: "Guatemala", phone: "502", countryCode: "" },
    { code: "GU", label: "Guam", phone: "1-671", countryCode: "" },
    { code: "GW", label: "Guinea-Bissau", phone: "245", countryCode: "" },
    { code: "GY", label: "Guyana", phone: "592", countryCode: "" },
    { code: "HK", label: "Hong Kong", phone: "852", countryCode: "" },
    {
      code: "HM",
      label: "Heard Island and McDonald Islands",
      phone: "672",
      countryCode: "",
    },
    { code: "HN", label: "Honduras", phone: "504", countryCode: "" },
    { code: "HR", label: "Croatia", phone: "385", countryCode: "" },
    { code: "HT", label: "Haiti", phone: "509", countryCode: "" },
    { code: "HU", label: "Hungary", phone: "36", countryCode: "" },
    { code: "ID", label: "Indonesia", phone: "62", countryCode: "" },
    { code: "IE", label: "Ireland", phone: "353", countryCode: "" },
    { code: "IL", label: "Israel", phone: "972", countryCode: "" },
    { code: "IM", label: "Isle of Man", phone: "44", countryCode: "" },
    { code: "IN", label: "India", phone: "91", countryCode: "" },
    {
      code: "IO",
      label: "British Indian Ocean Territory",
      phone: "246",
      countryCode: "",
    },
    { code: "IQ", label: "Iraq", phone: "964", countryCode: "" },
    {
      code: "IR",
      label: "Iran, Islamic Republic of",
      phone: "98",
      countryCode: "",
    },
    { code: "IS", label: "Iceland", phone: "354", countryCode: "" },
    { code: "IT", label: "Italy", phone: "39", countryCode: "" },
    { code: "JE", label: "Jersey", phone: "44", countryCode: "" },
    { code: "JM", label: "Jamaica", phone: "1-876", countryCode: "" },
    { code: "JO", label: "Jordan", phone: "962", countryCode: "" },
    {
      code: "JP",
      label: "Japan",
      phone: "81",
      suggested: true,
      countryCode: "",
    },
    { code: "KE", label: "Kenya", phone: "254", countryCode: "" },
    { code: "KG", label: "Kyrgyzstan", phone: "996", countryCode: "" },
    { code: "KH", label: "Cambodia", phone: "855", countryCode: "" },
    { code: "KI", label: "Kiribati", phone: "686", countryCode: "" },
    { code: "KM", label: "Comoros", phone: "269", countryCode: "" },
    {
      code: "KN",
      label: "Saint Kitts and Nevis",
      phone: "1-869",
      countryCode: "",
    },
    {
      code: "KP",
      label: "Korea, Democratic People's Republic of",
      phone: "850",
      countryCode: "",
    },
    { code: "KR", label: "Korea, Republic of", phone: "82", countryCode: "" },
    { code: "KW", label: "Kuwait", phone: "965", countryCode: "" },
    { code: "KY", label: "Cayman Islands", phone: "1-345", countryCode: "" },
    { code: "KZ", label: "Kazakhstan", phone: "7", countryCode: "" },
    {
      code: "LA",
      label: "Lao People's Democratic Republic",
      phone: "856",
      countryCode: "",
    },
    { code: "LB", label: "Lebanon", phone: "961", countryCode: "" },
    { code: "LC", label: "Saint Lucia", phone: "1-758", countryCode: "" },
    { code: "LI", label: "Liechtenstein", phone: "423", countryCode: "" },
    { code: "LK", label: "Sri Lanka", phone: "94", countryCode: "" },
    { code: "LR", label: "Liberia", phone: "231", countryCode: "" },
    { code: "LS", label: "Lesotho", phone: "266", countryCode: "" },
    { code: "LT", label: "Lithuania", phone: "370", countryCode: "" },
    { code: "LU", label: "Luxembourg", phone: "352", countryCode: "" },
    { code: "LV", label: "Latvia", phone: "371", countryCode: "" },
    { code: "LY", label: "Libya", phone: "218", countryCode: "" },
    { code: "MA", label: "Morocco", phone: "212", countryCode: "" },
    { code: "MC", label: "Monaco", phone: "377", countryCode: "" },
    {
      code: "MD",
      label: "Moldova, Republic of",
      phone: "373",
      countryCode: "",
    },
    { code: "ME", label: "Montenegro", phone: "382", countryCode: "" },
    {
      code: "MF",
      label: "Saint Martin (French part)",
      phone: "590",
      countryCode: "",
    },
    { code: "MG", label: "Madagascar", phone: "261", countryCode: "" },
    { code: "MH", label: "Marshall Islands", phone: "692", countryCode: "" },
    {
      code: "MK",
      label: "Macedonia, the Former Yugoslav Republic of",
      phone: "389",
      countryCode: "",
    },
    { code: "ML", label: "Mali", phone: "223", countryCode: "" },
    { code: "MM", label: "Myanmar", phone: "95", countryCode: "" },
    { code: "MN", label: "Mongolia", phone: "976", countryCode: "" },
    { code: "MO", label: "Macao", phone: "853", countryCode: "" },
    {
      code: "MP",
      label: "Northern Mariana Islands",
      phone: "1-670",
      countryCode: "",
    },
    { code: "MQ", label: "Martinique", phone: "596", countryCode: "" },
    { code: "MR", label: "Mauritania", phone: "222", countryCode: "" },
    { code: "MS", label: "Montserrat", phone: "1-664", countryCode: "" },
    { code: "MT", label: "Malta", phone: "356", countryCode: "" },
    { code: "MU", label: "Mauritius", phone: "230", countryCode: "" },
    { code: "MV", label: "Maldives", phone: "960", countryCode: "" },
    { code: "MW", label: "Malawi", phone: "265", countryCode: "" },
    { code: "MX", label: "Mexico", phone: "52", countryCode: "" },
    { code: "MY", label: "Malaysia", phone: "60", countryCode: "" },
    { code: "MZ", label: "Mozambique", phone: "258", countryCode: "" },
    { code: "NA", label: "Namibia", phone: "264", countryCode: "" },
    { code: "NC", label: "New Caledonia", phone: "687", countryCode: "" },
    { code: "NE", label: "Niger", phone: "227", countryCode: "" },
    { code: "NF", label: "Norfolk Island", phone: "672", countryCode: "" },
    { code: "NG", label: "Nigeria", phone: "234", countryCode: "" },
    { code: "NI", label: "Nicaragua", phone: "505", countryCode: "" },
    { code: "NL", label: "Netherlands", phone: "31", countryCode: "" },
    { code: "NO", label: "Norway", phone: "47", countryCode: "" },
    { code: "NP", label: "Nepal", phone: "977", countryCode: "" },
    { code: "NR", label: "Nauru", phone: "674", countryCode: "" },
    { code: "NU", label: "Niue", phone: "683", countryCode: "" },
    { code: "NZ", label: "New Zealand", phone: "64", countryCode: "" },
    { code: "OM", label: "Oman", phone: "968", countryCode: "" },
    { code: "PA", label: "Panama", phone: "507", countryCode: "" },
    { code: "PE", label: "Peru", phone: "51", countryCode: "" },
    { code: "PF", label: "French Polynesia", phone: "689", countryCode: "" },
    { code: "PG", label: "Papua New Guinea", phone: "675", countryCode: "" },
    { code: "PH", label: "Philippines", phone: "63", countryCode: "" },
    { code: "PK", label: "Pakistan", phone: "92", countryCode: "" },
    { code: "PL", label: "Poland", phone: "48", countryCode: "" },
    {
      code: "PM",
      label: "Saint Pierre and Miquelon",
      phone: "508",
      countryCode: "",
    },
    { code: "PN", label: "Pitcairn", phone: "870", countryCode: "" },
    { code: "PR", label: "Puerto Rico", phone: "1", countryCode: "" },
    { code: "PS", label: "Palestine, State of", phone: "970", countryCode: "" },
    { code: "PT", label: "Portugal", phone: "351", countryCode: "" },
    { code: "PW", label: "Palau", phone: "680", countryCode: "" },
    { code: "PY", label: "Paraguay", phone: "595", countryCode: "" },
    { code: "QA", label: "Qatar", phone: "974", countryCode: "" },
    { code: "RE", label: "Reunion", phone: "262", countryCode: "" },
    { code: "RO", label: "Romania", phone: "40", countryCode: "" },
    { code: "RS", label: "Serbia", phone: "381", countryCode: "" },
    { code: "RU", label: "Russian Federation", phone: "7", countryCode: "" },
    { code: "RW", label: "Rwanda", phone: "250", countryCode: "" },
    { code: "SA", label: "Saudi Arabia", phone: "966", countryCode: "" },
    { code: "SB", label: "Solomon Islands", phone: "677", countryCode: "" },
    { code: "SC", label: "Seychelles", phone: "248", countryCode: "" },
    { code: "SD", label: "Sudan", phone: "249", countryCode: "" },
    { code: "SE", label: "Sweden", phone: "46", countryCode: "" },
    { code: "SG", label: "Singapore", phone: "65", countryCode: "" },
    { code: "SH", label: "Saint Helena", phone: "290", countryCode: "" },
    { code: "SI", label: "Slovenia", phone: "386", countryCode: "" },
    {
      code: "SJ",
      label: "Svalbard and Jan Mayen",
      phone: "47",
      countryCode: "",
    },
    { code: "SK", label: "Slovakia", phone: "421", countryCode: "" },
    { code: "SL", label: "Sierra Leone", phone: "232", countryCode: "" },
    { code: "SM", label: "San Marino", phone: "378", countryCode: "" },
    { code: "SN", label: "Senegal", phone: "221", countryCode: "" },
    { code: "SO", label: "Somalia", phone: "252", countryCode: "" },
    { code: "SR", label: "Suriname", phone: "597", countryCode: "" },
    { code: "SS", label: "South Sudan", phone: "211", countryCode: "" },
    {
      code: "ST",
      label: "Sao Tome and Principe",
      phone: "239",
      countryCode: "",
    },
    { code: "SV", label: "El Salvador", phone: "503", countryCode: "" },
    {
      code: "SX",
      label: "Sint Maarten (Dutch part)",
      phone: "1-721",
      countryCode: "",
    },
    {
      code: "SY",
      label: "Syrian Arab Republic",
      phone: "963",
      countryCode: "",
    },
    { code: "SZ", label: "Swaziland", phone: "268", countryCode: "" },
    {
      code: "TC",
      label: "Turks and Caicos Islands",
      phone: "1-649",
      countryCode: "",
    },
    { code: "TD", label: "Chad", phone: "235", countryCode: "" },
    {
      code: "TF",
      label: "French Southern Territories",
      phone: "262",
      countryCode: "",
    },
    { code: "TG", label: "Togo", phone: "228", countryCode: "" },
    { code: "TH", label: "Thailand", phone: "66", countryCode: "" },
    { code: "TJ", label: "Tajikistan", phone: "992", countryCode: "" },
    { code: "TK", label: "Tokelau", phone: "690", countryCode: "" },
    { code: "TL", label: "Timor-Leste", phone: "670", countryCode: "" },
    { code: "TM", label: "Turkmenistan", phone: "993", countryCode: "" },
    { code: "TN", label: "Tunisia", phone: "216", countryCode: "" },
    { code: "TO", label: "Tonga", phone: "676", countryCode: "" },
    { code: "TR", label: "Turkey", phone: "90", countryCode: "" },
    {
      code: "TT",
      label: "Trinidad and Tobago",
      phone: "1-868",
      countryCode: "",
    },
    { code: "TV", label: "Tuvalu", phone: "688", countryCode: "" },
    {
      code: "TW",
      label: "Taiwan, Province of China",
      phone: "886",
      countryCode: "",
    },
    {
      code: "TZ",
      label: "United Republic of Tanzania",
      phone: "255",
      countryCode: "",
    },
    { code: "UA", label: "Ukraine", phone: "380", countryCode: "" },
    { code: "UG", label: "Uganda", phone: "256", countryCode: "" },
    {
      code: "US",
      label: "United States",
      phone: "1",
      suggested: true,
      countryCode: "en",
    },
    { code: "UY", label: "Uruguay", phone: "598", countryCode: "" },
    { code: "UZ", label: "Uzbekistan", phone: "998", countryCode: "" },
    {
      code: "VA",
      label: "Holy See (Vatican City State)",
      phone: "379",
      countryCode: "",
    },
    {
      code: "VC",
      label: "Saint Vincent and the Grenadines",
      phone: "1-784",
      countryCode: "",
    },
    { code: "VE", label: "Venezuela", phone: "58", countryCode: "" },
    {
      code: "VG",
      label: "British Virgin Islands",
      phone: "1-284",
      countryCode: "",
    },
    { code: "VI", label: "US Virgin Islands", phone: "1-340", countryCode: "" },
    {
      code: "VN",
      label: "Vietnam",
      phone: "84",
      countryCode: "vi",
    },
    { code: "VU", label: "Vanuatu", phone: "678", countryCode: "" },
    { code: "WF", label: "Wallis and Futuna", phone: "681", countryCode: "" },
    { code: "WS", label: "Samoa", phone: "685", countryCode: "" },
    { code: "XK", label: "Kosovo", phone: "383", countryCode: "" },
    { code: "YE", label: "Yemen", phone: "967", countryCode: "" },
    { code: "YT", label: "Mayotte", phone: "262", countryCode: "" },
    { code: "ZA", label: "South Africa", phone: "27", countryCode: "" },
    { code: "ZM", label: "Zambia", phone: "260", countryCode: "" },
    { code: "ZW", label: "Zimbabwe", phone: "263", countryCode: "" },
    { code: "EU", label: "EU", phone: "49", countryCode: "" },
  ];
  return { countries };
};

export default useCountryCode;
