import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";

import {
  FormAutocomplete,
  FormAutocompleteClient,
} from "hooks/FormAutocomplete";
import { useNavigate } from "react-router-dom";

const AddClientsYoutube = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [games, setGames] = React.useState([]);
  const [clients, setClients] = React.useState([]);

  const [jsonWallets, setJsonWallets] = useState([]);
  const [, setFieldValueGame] = useState([]);
  const [fieldValueGame2, setFieldValueGame2] = useState([]);

  const [jsonBudgets, setJsonBudgets] = useState([]);
  const [, setInputBudget] = useState([]);
  const [input, setInput] = useState({
    type: "",
    currency: "",
    status: "",
    Adfee: "",
  });
  const initialValues = {
    code: "",
    amount: 0,
    Game: null,
    Client: null,
    notes: "",
    Wallets: null,
  };
  const validationSchema = Yup.object().shape({
    Game: Yup.object().required("Game is required"),
  });
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const getProductData = async () => {
    const client = await axiosusers.get(`/clients/clientsyoutube`);
    setClients(client.data);
    const payment = await axiosusers.get(`/games/games`);
    setGames(payment.data);
    const wallet = await axiosusers.get(`/wallets/walletsproduct`);
    setJsonWallets(wallet.data);
  };
  const getProductData2 = async () => {
    const budget = await axiosusers.get(
      `/wallets/wallets-product-budget/${fieldValueGame2?.id}`
    );
    setJsonBudgets(budget.data);
  };
  useEffect(() => {
    if (fieldValueGame2?.id !== undefined) {
      getProductData2();
    }
  }, [fieldValueGame2]);
  useEffect(() => {
    getProductData();
  }, []);

  const onSubmit = async (values) => {
    setDung(true);
    let total = 0;
    jsonBudgets.forEach((item) => {
      total += Number(item.usedQuantity * item.price);
    });
    if (input.currency == "" && input.Adfee == "youtube") {
      input.currency = "1";
      return seterrorMessage("The currency not found.");
    }
    const amount =
      input.currency == "VND" ? values.amount : Number(values.amount) * 22200;
    const data = {
      code: input.Adfee == "youtube" ? "Youtube" : "Product Catery",
      clientsyoutubeId:
        input.Adfee == "youtube" ? values.Client.id : fieldValueGame2.id,
      gameId: values.Game.id,
      amount: input.Adfee == "youtube" ? amount : total,
      remainAmount: input.Adfee == "youtube" ? amount : total,
      statusAdfee: input.Adfee,
      currency: input.Adfee == "youtube" ? input.currency : "VND",
    };
    try {
      await axiosusers.post(`/facebook-manage/create-advertisementfee`, data);
      navigate("/app/advertise-fee/");
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
    }
  };
  let number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  const handleChangeInputBudget = (id, event) => {
    const newjsonBudgets = jsonBudgets.map((w) => {
      if (id === w.id) {
        w[event.target.name] = event.target.value;
      }
      return w;
    });
    setInputBudget(newjsonBudgets);
  };
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new advertise-fee
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: `0.5rem` }}
                size="small"
              >
                <InputLabel>Select Product</InputLabel>
                <Select
                  value={input.status}
                  onChange={handleChange}
                  label="Select Product"
                  name="status"
                >
                  <MenuItem value="game">Games-Top up</MenuItem>
                  <MenuItem value="giftCard">Gift Card</MenuItem>
                  <MenuItem value="softWare">SoftWare Account</MenuItem>
                  <MenuItem value="account">Games Account</MenuItem>
                </Select>
              </FormControl>
              <FormAutocomplete
                onChangeSelect={(value) => setFieldValueGame(value)}
                style={{ marginTop: `0.5rem` }}
                label="Product"
                name="Game"
                options={
                  input.status
                    ? games.filter((game) => game.status === input.status)
                    : games
                }
              />

              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                style={{ marginTop: `0.5rem` }}
                error={Boolean(
                  errorMessage.length > 0 && input.currency == "1"
                )}
              >
                <InputLabel>Advertisement fee</InputLabel>
                <Select
                  value={input.Adfee}
                  onChange={handleChange}
                  label="Advertisement fee"
                  name="Adfee"
                >
                  <MenuItem value="youtube">Youtube</MenuItem>
                  <MenuItem value="product-catery">Product Catery</MenuItem>
                </Select>
              </FormControl>
              {input.Adfee == "product-catery" && (
                <div>
                  <FormAutocomplete
                    onChangeSelect={(value) => setFieldValueGame2(value)}
                    style={{ marginTop: `0.5rem` }}
                    label="Select an Product category"
                    name="Wallets"
                    options={jsonWallets}
                  />
                  {fieldValueGame2 && (
                    <div>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Wallet name: {fieldValueGame2.name}
                      </Typography>

                      {jsonBudgets.length > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            margin: (theme) => theme.spacing(1, 0),
                          }}
                        >
                          <Box
                            sx={{
                              flexBasis: {
                                md: "33.33%",
                                xs: "40%",
                                lx: "33.33%",
                                sx: "33.33%",
                              },
                            }}
                          >
                            <Typography>Budget name</Typography>
                          </Box>
                          <Box
                            ox
                            sx={{
                              flexBasis: {
                                md: "33.33%",
                                xs: "30%",
                                lx: "33.33%",
                                sx: "30%",
                              },
                            }}
                          >
                            <Typography>Price</Typography>
                          </Box>
                          <Box
                            sx={{
                              flexBasis: {
                                md: "33.33%",
                                xs: "40%",
                                lx: "33.33%",
                                sx: "40%",
                              },
                            }}
                          >
                            <Typography>Used Quantity</Typography>
                          </Box>
                        </Box>
                      )}
                      {jsonBudgets
                        .sort((a, b) => a.orderNumber - b.orderNumber)
                        .map((budget) => {
                          if (fieldValueGame2.id == budget.walletId) {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  margin: (theme) => theme.spacing(1, 0),
                                }}
                                key={budget.id}
                              >
                                <Box
                                  sx={{
                                    flexBasis: {
                                      md: "33.33%",
                                      xs: "40%",
                                      lx: "33.33%",
                                      sx: "40%",
                                    },
                                  }}
                                >
                                  <Typography>{budget.name}</Typography>
                                </Box>
                                <Box
                                  sx={{
                                    flexBasis: {
                                      md: "33.33%",
                                      xs: "40%",
                                      lx: "33.33%",
                                      sx: "40%",
                                    },
                                  }}
                                >
                                  <Typography>
                                    {number.format(Number(budget.price))} ₫
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    flexBasis: {
                                      md: "33.33%",
                                      xs: "40%",
                                      lx: "33.33%",
                                      sx: "40%",
                                    },
                                  }}
                                >
                                  <TextField
                                    value={budget.usedQuantity}
                                    onChange={(event) =>
                                      handleChangeInputBudget(budget.id, event)
                                    }
                                    type="number"
                                    name="usedQuantity"
                                    label="Used Quantity"
                                    variant="outlined"
                                    size="small"
                                  />
                                </Box>
                              </Box>
                            );
                          }
                        })}
                      <br />
                    </div>
                  )}
                </div>
              )}
              {input.Adfee == "youtube" && (
                <div>
                  <FormAutocompleteClient
                    style={{ marginTop: `0.5rem` }}
                    label="Client youtube"
                    name="Client"
                    options={clients}
                  />

                  <FormControl
                    size="small"
                    fullWidth
                    variant="outlined"
                    style={{ marginTop: `0.5rem` }}
                    error={Boolean(
                      errorMessage.length > 0 && input.currency == "1"
                    )}
                  >
                    <InputLabel>Select currency</InputLabel>
                    <Select
                      value={input.currency}
                      onChange={handleChange}
                      label="Select currency"
                      name="currency"
                    >
                      <MenuItem value="VND">VND</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                    </Select>
                    {errorMessage.length > 0 && input.currency == "1" && (
                      <FormHelperText>{errorMessage}</FormHelperText>
                    )}
                  </FormControl>
                  <Field
                    as={TextField}
                    style={{ marginTop: `0.5rem` }}
                    error={props.errors.amount && props.touched.amount}
                    helperText={<ErrorMessage name="amount" />}
                    name="amount"
                    type="number"
                    label="Amount"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </div>
              )}
              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddClientsYoutube;
