import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { Tabtitle } from "components/title/Title";
import useCountry from "hooks/useCountry";
import useUserId from "hooks/useUserId";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";

const index = () => {
  Tabtitle("Update country giftcard");
  let navigate = useNavigate();
  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const { id } = useParams();

  const [jsonResuls, setJsonResuls] = useState([]);
  const { countries } = useCountry();
  const [selectedGame, setSelectedGame] = useState({
    name: "",
    id: "",
  });
  const [selectedCountry, setSelectedCountry] = useState({
    code: "",
    label: "",
    phone: "",
  });
  const [input, setInput] = useState({
    name: " ",
    description: " ",
    status: " ",
    notesen: " ",
    noteses: " ",
  });
  useEffect(() => {
    Promise.all([
      axiosusers.get(`/Countrys/country/${id}`),
      axiosusers.get(`/games/games`),
    ]).then(async (results) => {
      const [clienId, games] = results.map((res) => res.data);
      setInput(clienId);
      const giftcard = games.filter((item) => item.status == "giftCard");
      setJsonResuls(giftcard);

      setSelectedCountry({ label: clienId.country });
      const gameId = await axiosusers.get(`/games/games/${clienId.gameId}`);
      setSelectedGame({
        name: gameId.data.name,
        id: gameId.data.id,
      });
    });
  }, [id]);

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = async () => {
    const data = {
      id,
      gameId: selectedGame.id,
      country: selectedCountry.label,
      notesen: input.notesen,
      noteses: input.noteses,
    };
    try {
      const response = await axiosusers.post(`/countrys/update-country`, data);

      if (response.status === 200) {
        navigate("/app/countrygiftcard/");
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <div>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "30px",
              margin: (theme) => theme.spacing(0, 0, 3, 0),
            }}
          >
            Update country giftcard
          </Typography>

          <Autocomplete
            sx={{ marginTop: `0.5rem` }}
            options={jsonResuls}
            fullWidth
            value={selectedGame}
            onChange={(event, value) => setSelectedGame(value)}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(jsonResuls) => jsonResuls.name}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(selectedGame === null)}
                helperText={
                  selectedGame === null ? "Gift Card is required" : ""
                }
                label="Gift Card"
                variant="outlined"
              />
            )}
          />
          <Autocomplete
            id="country-select-demo"
            options={countries}
            autoHighlight
            size="small"
            value={selectedCountry}
            onChange={(event, value) => setSelectedCountry(value)}
            getOptionLabel={(option) => option.label}
            sx={{ marginTop: `0.5rem` }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />

          <TextField
            onChange={handleinput}
            multiline
            minRows={4}
            sx={{ marginTop: `0.5rem` }}
            type="text"
            value={input.notesen}
            name="notesen"
            label="Nofication English"
            size="small"
            variant="outlined"
            fullWidth
          />
          <TextField
            onChange={handleinput}
            sx={{ marginTop: `0.5rem` }}
            type="text"
            multiline
            minRows={4}
            value={input.noteses}
            name="noteses"
            label="Nofication Spanish"
            size="small"
            variant="outlined"
            fullWidth
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            color="primary"
            sx={{ marginTop: `3rem` }}
            onClick={onSubmit}
          >
            Update
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default index;
