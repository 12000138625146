import React from "react";
import EditPayments from "./EditPayments";
import { Tabtitle } from "components/title/Title";
import { useParams } from "react-router-dom";

const index = () => {
  Tabtitle("Update Payments");
  const { id } = useParams();
  return <div>{id && <EditPayments id={id} />}</div>;
};

export default index;
