import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";

import React from "react";
import axiosusers from "utils/api/axiosusers";

const BudgetsOrders = (props) => {
  const { id, budgets, budgetOrders, totalRecharge } = props;
  const [FieldValueGame, setFieldValueGame] = React.useState([]);
  const [Message, setMessage] = React.useState(false);
  const [Message2, setMessage2] = React.useState(false);

  const handleSubmitBudget = async () => {
    if (FieldValueGame?.budgetId !== undefined) {
      setMessage(true);
      const data = {
        budgetOrders,
        orderId: id,
        FieldValueGame,
        totalRecharge,
      };
      try {
        const response = await axiosusers.post(
          `/budgets/update-budgets-orders`,
          data
        );

        if (response.status === 200) {
          console.log(response);
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
        setMessage2(error.response.data.message);
      }
    }
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item lg={2} md={2} sm={2} xs={3}></Grid>
        <Grid item lg={5} md={5} sm={5} xs={5}>
          <Autocomplete
            options={budgets}
            style={{ marginBottom: "20px" }}
            fullWidth
            filterSelectedOptions
            onChange={(e, value) => setFieldValueGame(value)}
            getOptionLabel={(jsonTransactions) => jsonTransactions.name}
            size="medium"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Budget"
                placeholder="Select one or multiple"
                variant="outlined"
              />
            )}
          />
          {Message2 && <FilledAlerts message={Message2} success="error" />}
          <Button
            onClick={() => handleSubmitBudget()}
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            disabled={Message}
          >
            update Budget
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default BudgetsOrders;
