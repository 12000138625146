/* eslint-disable no-inner-declarations */
import {
  Accordion,
  AccordionSummary,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useUserId from "hooks/useUserId";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./style.module.css";

import axiosusers from "utils/api/axiosusers";
import { NavLink } from "react-router-dom";
import useLinks from "hooks/useLinks";

const Wallets = (props) => {
  const [product, setProduct] = useState([]);
  const [pagination, setPagination] = useState([]);
  const idProductRef = useRef();
  const idProductRefpage = useRef();
  const idProductRef_name = useRef();
  const [jsonResuls, setJsonResuls] = useState([]);
  const [selected, setSelected] = useState([]);
  const [Message, setMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const { userId } = useUserId();
  const linkImage = useLinks();
  const [search, setSearch] = useState({
    name: "",
    number: "",
  });
  const SearchText = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };
  const pageSize = 32;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [DonePage, setDonePage] = useState([]);
  const fetchData = async () => {
    setPageCount(Math.ceil(props.wallets.length / pageSize));

    setProduct(props.wallets);
    setDonePage(props.wallets);
    setPagination(_(props.wallets).slice(0).take(pageSize).value());
    setJsonResuls(props.games);
  };

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);
  useEffect(() => {
    let results = product.sort((a, b) => a.id - b.id);
    setCurrentPage(1);
    if (search.name !== "") {
      const nameRegex = new RegExp(`.*${escapeRegExp(search.name)}.*`, "i");

      function escapeRegExp(string) {
        return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
      }

      results = results
        .filter((item) => nameRegex.test(item.name))
        .sort((a, b) => a.orderNumber - b.orderNumber);
    }

    if (search.number !== "") {
      const numberRegex = new RegExp(`.*${escapeRegExp(search.name)}.*`, "i");

      function escapeRegExp(string) {
        return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
      }
      results = results
        .filter((item) => numberRegex.test(item.orderNumber))
        .sort((a, b) => a.orderNumber - b.orderNumber);
    }
    if (selected?.id !== undefined) {
      results = results
        .filter((item) => item.gameId === selected.id)
        .sort((a, b) => a.orderNumber - b.orderNumber);
    }

    const paginatedResults = _(results).slice(0).take(pageSize).value();
    setPageCount(Math.ceil(results.length / pageSize));
    setPagination(paginatedResults);
  }, [search, selected]);

  setTimeout(function () {
    setMessage(false);
  }, 400);
  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes/delete-wallets`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        const data = product.filter((item) => item.id !== idProductRef.current);
        const startIndex = (currentPage - 1) * pageSize;
        setPagination(_(data).slice(startIndex).take(pageSize).value());
        setProduct(data);
        setDonePage(data);
      }
    } catch (error) {
      console.log(error);
    }

    setMessage(true);
    setOpen(false);
  };
  const handleClickOpen = (id, page, name) => {
    setOpen(true);
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };

  const handleClose = () => {
    setOpen(false);
  };
  const numberthem = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  });
  let number = new Intl.NumberFormat("en-US");

  const Pagechane = (event, value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(DonePage).slice(startIndex).take(pageSize).value();
    setPagination(pagination);
    setCurrentPage(value);
    setMessage(true);
  };

  return (
    <div>
      {userId?.role == "Admin" && (
        <NavLink to="/app/wallets/add" className={styles.link}>
          <Button variant="contained" color="primary">
            ADD wallet
          </Button>
        </NavLink>
      )}
      <Grid container className={styles.centerGrid}>
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Autocomplete
                className={styles.fontText}
                options={jsonResuls}
                fullWidth
                onChange={(event, value) => setSelected(value)}
                getOptionLabel={(jsonResuls) => jsonResuls.name}
                renderInput={(params) => (
                  <TextField {...params} label="Game" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutlinedInput
                fullWidth
                onChange={SearchText}
                placeholder="Type an order number"
                name="number"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutlinedInput
                fullWidth
                onChange={SearchText}
                placeholder="Type a wallet name"
                name="name"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {Message && (
        <div className={styles.center}>
          <CircularProgress />
        </div>
      )}
      {!userId && (
        <div className={styles.center}>
          <CircularProgress />
        </div>
      )}
      <Grid container spacing={3}>
        {pagination.map((item) => (
          <Grid
            item
            key={item.id}
            lg={4}
            md={6}
            sm={6}
            xs={12}
            className={styles.griditem2}
          >
            <Accordion sx={{ justifyContent: "center", textAlign: "center" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  "&.MuiAccordionSummary-root": {
                    borderRadius: "4px 4px 4px 4px",
                    backgroundColor: "#3f50b5", // thay đổi màu sắc ở đây
                  },
                  "&.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded":
                    {
                      borderRadius: "4px 4px 0px 0px",
                    },
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: (theme) => theme.typography.pxToRem(16),
                    fontWeight: 500,
                    justifyContent: "center",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {item.name}
                </Typography>
              </AccordionSummary>
              <div style={{ flexBasis: "33.33%" }} />
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: "0.9375rem",
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(1),
                }}
              >
                {item?.status == "game" ? "Game: " : "Gift Card: "}
                {item.gameName}
              </Typography>
              <Divider />
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: (theme) => theme.typography.pxToRem(15),
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(1),
                }}
              >
                Remain Packages: {numberthem.format(item.packages)}
              </Typography>
              <Divider />
              {userId?.role == "Admin" && (
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "0.9375rem",
                    fontWeight: 500,
                    padding: (theme) => theme.spacing(1),
                  }}
                >
                  Price: {number.format(item.price)} ₫
                </Typography>
              )}
              {userId?.role == "Admin" && <Divider />}
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: (theme) => theme.typography.pxToRem(15),
                  fontWeight: 500,
                  padding: (theme) => theme.spacing(1),
                }}
              >
                Price in dollar: {numberthem.format(item.amount)} $
              </Typography>
              {item.country != "" && <Divider />}
              {item.country != "" && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: (theme) => theme.typography.pxToRem(15),
                    fontWeight: 500,
                    padding: (theme) => theme.spacing(1),
                  }}
                >
                  Country: {item.country}
                </Typography>
              )}
              <Divider />
              {item.image !== "" && (
                <>
                  <img
                    src={`${linkImage + item.image}`}
                    crossOrigin="anonymous"
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "10px",
                      margin: "1rem 0",
                    }}
                    alt={item.name}
                  />
                  <Divider />
                </>
              )}
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  textAlign: "left",
                  marginTop: "2px",
                }}
              >
                {item.notes}
              </pre>
              <Divider />
              <div className={styles.iconw}>
                {userId?.role !== "Admin" && (
                  <NavLink to={`/app/wallets/${item.id}`}>
                    <IconButton color="primary">
                      <AddToQueueIcon />
                    </IconButton>
                  </NavLink>
                )}
                {userId?.role == "Admin" && (
                  <NavLink to={`/app/wallets/edit/${item.id}`}>
                    <IconButton color="primary">
                      <EditIcon />
                    </IconButton>
                  </NavLink>
                )}
                {userId?.role == "Admin" && (
                  <IconButton
                    color="primary"
                    onClick={() => handleClickOpen(item.id, "Delete")}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </Accordion>
          </Grid>
        ))}
      </Grid>
      {product.length > 10 && (
        <Grid
          container
          spacing={0}
          sx={{
            justifyContent: "center",
            color: "#000",
            display: "flex",
            padding: "20px",
          }}
        >
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={Pagechane}
            size="small"
            color="primary"
          />
        </Grid>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent className={styles.textColor}>
          <DialogContentText>
            Are you sure to delete this wallet?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Wallets;
