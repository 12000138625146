import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";

import useUserId from "hooks/useUserId";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useNavigate } from "react-router-dom";
const AddPayments180Days = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(null);

  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const { userId } = useUserId();
  const [input, setInput] = React.useState({
    type: "",
    currency: "",
    status: "",
  });
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const initialValues = {
    orderNumber: "",
    owner: "",
    bank: "",
    balance: "0",
    onHoldBalance: "0",
    notes: "",
    phoneNumber: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    orderNumber: Yup.string()
      .min(0, "It's too short")
      .required("No is required"),
    owner: Yup.string()
      .min(0, "Must be a valid email")
      .required("Owner is required"),
    bank: Yup.string()
      .min(0, "password must be at least 6 characters")
      .required("Bank name is required"),
  });
  const onSubmit = async (values) => {
    setDung(true);
    if (userId?.role != "Admin") return navigate("/login");
    if (input.type === "") {
      input.type = "1";
      setDung(false);
      return seterrorMessage("The type not found.");
    }
    if (input.status === "") {
      input.status = "1";
      setDung(false);
      return seterrorMessage("The status not found.");
    }
    if (input.currency === "") {
      input.currency = "1";
      setDung(false);
      return seterrorMessage("The currency not found.");
    }
    const data = {
      type: input.type,
      password: values.password,
      phoneNumber: values.phoneNumber,
      owner: values.owner,
      balance: values.balance,
      onHoldBalance: values.onHoldBalance,
      createdAt: value ? value : new Date(),
      notes: values.notes,
      currency: input.currency,
      status: "limit",
      bank: values.bank,
      orderNumber: values.orderNumber,
    };
    try {
      await axiosusers.post(`/payments/create-payments-180days`, data);
      navigate("/app/paypal-180-days/");
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
    }
  };

  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
          }}
        >
          Create new payment
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack size="small" spacing={3} style={{ marginTop: `1.5rem` }}>
                  <DesktopDatePicker
                    label="Limit date"
                    format="DD/MM/YYYY"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                type="number"
                name="orderNumber"
                label="Payment .No"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.orderNumber && props.touched.orderNumber}
                helperText={<ErrorMessage name="orderNumber" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                multiline
                minRows="1"
                as={TextField}
                name="owner"
                label="Payment Owner"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.owner && props.touched.owner}
                helperText={<ErrorMessage name="owner" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="password"
                label="Password"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.password && props.touched.password}
                helperText={<ErrorMessage name="password" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="bank"
                label="Bank Name"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.bank && props.touched.bank}
                helperText={<ErrorMessage name="bank" />}
              />
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                sx={{ marginTop: `0.5rem` }}
                error={Boolean(errorMessage.length > 0 && input.type == "1")}
              >
                <InputLabel>Select type</InputLabel>
                <Select
                  value={input.type}
                  onChange={handleChange}
                  label="Select type"
                  name="type"
                >
                  <MenuItem value="Paypal">Paypal</MenuItem>
                  <MenuItem value="Momo">Momo</MenuItem>
                  <MenuItem value="Airpay">Airpay</MenuItem>
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="BTC">BTC</MenuItem>
                  <MenuItem value="BankTransfer">BankTransfer</MenuItem>
                  <MenuItem value="Skrill">Skrill</MenuItem>
                  <MenuItem value="TransferWise">TransferWise</MenuItem>
                  <MenuItem value="WebMoney">WebMoney</MenuItem>
                  <MenuItem value="PayOneer">PayOneer</MenuItem>
                  <MenuItem value="PingPong">PingPong</MenuItem>
                  <MenuItem value="USDT">USDT</MenuItem>
                  <MenuItem value="ETH">ETH</MenuItem>
                </Select>
                {errorMessage.length > 0 && input.type == "1" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                sx={{ marginTop: `0.5rem` }}
                error={Boolean(errorMessage.length > 0 && input.status == "1")}
              >
                <InputLabel>Select status</InputLabel>
                <Select
                  value={input.status}
                  onChange={handleChange}
                  label="Select status"
                  name="status"
                >
                  <MenuItem value="trustSeller">Trust seller</MenuItem>
                  <MenuItem value="limit">Limit</MenuItem>
                  <MenuItem value="disable">Disable</MenuItem>
                  <MenuItem value="strong">Strong</MenuItem>
                  <MenuItem value="weak">Weak</MenuItem>
                  <MenuItem value="openCase">Open case</MenuItem>
                </Select>
                {errorMessage.length > 0 && input.status == "1" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                sx={{ marginTop: `0.5rem` }}
                error={Boolean(
                  errorMessage.length > 0 && input.currency == "1"
                )}
              >
                <InputLabel>Select currency</InputLabel>
                <Select
                  value={input.currency}
                  onChange={handleChange}
                  label="Select currency"
                  name="currency"
                >
                  <MenuItem value="VND">VND</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
                {errorMessage.length > 0 && input.currency == "1" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>

              <Field
                sx={{ marginTop: `0.5rem` }}
                type="number"
                as={TextField}
                name="balance"
                label="Balance"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                type="number"
                name="onHoldBalance"
                label="On Hold Balance"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="phoneNumber"
                label="Phone Number"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                multiline
                minRows="4"
                as={TextField}
                name="notes"
                label="Notes"
                variant="outlined"
                size="small"
                fullWidth
              />
              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddPayments180Days;
