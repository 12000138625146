import React from "react";
import EditWallets from "./EditWallets";
import { useParams } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Update Wallets");
  const { id } = useParams();
  return <div> {id && <EditWallets id={id} />}</div>;
};

export default index;
