import React, { useEffect, useState } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";

import axiosusers from "utils/api/axiosusers";
import { useNavigate, useParams } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const EditAdministrators = () => {
  Tabtitle("Update administrators");
  const { id } = useParams();
  const navigate = useNavigate();
  const [input, setInput] = useState({
    no: " ",
    notes: " ",
    name: " ",
    phoneNumber: " ",
    link: " ",
  });

  useEffect(() => {
    Promise.all([axiosusers.get(`/facebook-manage/administrators/${id}`)]).then(
      async (results) => {
        const [clienId] = results.map((res) => res.data);
        setInput(clienId);
      }
    );
  }, [id]);

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    const data = {
      id,
      no: input.no,
      name: input.name,
      link: input.link,
      phoneNumber: input.phoneNumber,
      notes: input.notes,
    };
    try {
      const res = await axiosusers.post(
        `/facebook-manage/update-administrators`,
        data
      );
      console.log(res);
      navigate("/app/facebook-manage");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Update administrators
        </Typography>

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="number"
          value={input.no}
          name="no"
          error={Boolean(input.no === "")}
          helperText={input.no === "" ? ".No is required" : ""}
          label=".No"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.name}
          name="name"
          error={Boolean(input.name === "")}
          helperText={input.name === "" ? "Name is required" : ""}
          label="Name"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.link}
          name="link"
          label="Link"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.phoneNumber}
          name="phoneNumber"
          label="Phone Number"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          multiline
          minRows="4"
          type="text"
          value={input.notes}
          name="notes"
          label="Notes"
          size="small"
          variant="outlined"
          fullWidth
        />

        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default EditAdministrators;
