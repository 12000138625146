import React, { useEffect, useState } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";

import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";

const EditBlacklists = (props) => {
  const { id } = props;

  const navigate = useNavigate();
  const [input, setInput] = useState({
    name: " ",
    notes: " ",
  });

  useEffect(() => {
    Promise.all([axiosusers.get(`/budgets/blacklists/${id}`)]).then(
      async (results) => {
        const [clienId] = results.map((res) => res.data);
        setInput(clienId);
      }
    );
  }, [id]);

  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    const data = {
      id,
      name: input.name,
      notes: input.notes,
    };
    try {
      const res = await axiosusers.patch(`/budgets/update-blacklists`, data);
      console.log(res);
      navigate("/app/blacklists");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Update Blacklists
        </Typography>

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.name}
          name="name"
          error={Boolean(input.name === "")}
          helperText={input.name === "" ? "Account is required" : ""}
          label="Account"
          size="small"
          variant="outlined"
          fullWidth
        />

        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          multiline
          minRows="4"
          type="text"
          value={input.notes}
          name="notes"
          label="Notes"
          size="small"
          variant="outlined"
          fullWidth
        />

        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default EditBlacklists;
