import React, { useEffect, useState } from "react";

import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import { Tabtitle } from "components/title/Title";
import { useNavigate, useParams } from "react-router-dom";
import useUserId from "hooks/useUserId";
import axiosusers from "utils/api/axiosusers";
import useLinks from "hooks/useLinks";

const index = () => {
  Tabtitle("Update Sanpham");
  let navigate = useNavigate();
  const linkImage = useLinks();
  const [image, setImage] = useState();
  const [avatar, setAvatar] = useState();

  const { id } = useParams();
  const [input, setInput] = useState({
    name: " ",
    price: " ",
    notes: " ",
  });
  const getProduct = async () => {
    const data = await axiosusers.get(`/sanpham/sanpham/${id}`);
    console.log(data.data);
    setInput(data.data);
  };
  useEffect(() => {
    getProduct();
  }, [id]);
  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    setImage(file);

    file.preview = URL.createObjectURL(file);
    setAvatar(file);
  };

  const onSubmit = async () => {
    const formData = new FormData();
    const datatime = new Date().getTime();
    formData.append("id", id);
    formData.append("name", input.name);
    formData.append("notes", input.notes);
    formData.append("price", input.price);
    formData.append("file", image);
    formData.append("datatime", datatime);

    try {
      const response = await axiosusers.post(
        `/sanpham/update-sanpham`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        navigate("/sanpham/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
          }}
        >
          Update Sanpham
        </Typography>

        <TextField
          sx={{ marginTop: `1rem` }}
          onChange={handleinput}
          type="text"
          value={input.name}
          name="name"
          error={Boolean(input.name === "")}
          helperText={input.name === "" ? "Name is required" : ""}
          label="Name"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="number"
          value={input.price}
          name="price"
          label="Price"
          size="small"
          variant="outlined"
          fullWidth
        />

        <TextField
          onChange={handleinput}
          multiline
          minRows={4}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.notes}
          name="notes"
          label="Notes"
          size="small"
          variant="outlined"
          fullWidth
        />

        {avatar && (
          <Avatar
            src={avatar.preview}
            variant="square"
            sx={{
              width: (theme) => theme.spacing(25),
              height: (theme) => theme.spacing(25),
              margin: (theme) => theme.spacing(2, 0),
            }}
          />
        )}
        {!avatar && input.image !== "" && (
          <img
            src={`${linkImage}${input.image}`}
            crossOrigin="anonymous"
            style={{
              width: "200px",
              height: "200px",
              margin: "1rem 0 0 0",
            }}
          />
        )}
        <br />
        <input
          accept="image/*"
          style={{
            display: "none",
            color: "#3f50b5",
            margin: (theme) => theme.spacing(2, 0),
          }}
          onChange={handleFile}
          id="file"
          name="avatar"
          multiple
          type="file"
        />
        <label htmlFor="file">
          <Button component="span" color="primary" sx={{ marginTop: `1rem` }}>
            UPLOAD PHOTOS
          </Button>
        </label>

        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default index;
