/* eslint-disable react/prop-types */
/* eslint-disable no-undef */

import { Avatar, Box, Divider, Typography } from "@mui/material";

import React from "react";
import { NavLink } from "react-router-dom";

const Profiles = (props) => {
  const { data } = props;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: (theme) => theme.spacing(2, 0, 1, 0),
        }}
      >
        <NavLink to={`/app/profiles/${data?.id}`}>
          {(!data || data?.avatarUrl === "") && (
            <Avatar
              alt="Remy Sharp"
              sx={{
                width: (theme) => theme.spacing(8),
                height: (theme) => theme.spacing(8),
              }}
            />
          )}
          {data?.avatarUrl !== undefined && data?.avatarUrl !== "" && (
            <Box
              sx={{
                width: { lg: "64px", sm: "64px", md: "64px", xs: "64px" },
                height: { lg: "64px", sm: "64px", md: "64px", xs: "64px" },
                display: "flex",
                overflow: "hidden",
                position: "relative",
                fontSize: "1rem",
                alignItems: "center",
                lineHeight: "1",
                userSelect: "none",
                borderRadius: "50%",
                justifyContent: "center",
              }}
            >
              <img
                src={`${process.env.REACT_APP_NOT_SECRET_CODE}images/${data?.avatarUrl}`}
                alt="logo"
                crossOrigin="anonymous"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  verticalAlign: "middle",
                  borderStyle: "none",
                }}
              />
            </Box>
          )}
        </NavLink>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 500, fontSize: "20px" }}>
          {data?.name === undefined ? "Name" : data?.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            color: "#546e7a",
            fontSize: "16px",
            padding: (theme) => theme.spacing(0, 0, 2, 0),
          }}
        >
          {data?.role === undefined ? "Admin" : data?.role}
        </Typography>
      </Box>
      <Divider />
    </div>
  );
};

export default Profiles;
