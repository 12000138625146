import React, { useEffect, useState } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";

import useUserId from "hooks/useUserId";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";

const EditPrimaryChannel = (props) => {
  const { id } = props;

  const navigate = useNavigate();
  const [input, setInput] = useState({
    channelName: " ",
    phoneNumber: " ",
    telegramUser: " ",
    lineUser: " ",
    instagramUser: " ",
    panpageFacebook: " ",
  });
  const getProduct = async () => {
    const data = await axiosusers.get(`/primarychannel/primarychannel/${id}`);
    setInput(data.data);
  };
  useEffect(() => {
    getProduct();
  }, [id]);
  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    const data = {
      id,
      channelName: input.channelName,
      phoneNumber: input.phoneNumber,
      telegramUser: input.telegramUser,
      lineUser: input.lineUser,
      instagramUser: input.instagramUser,
      panpageFacebook: input.panpageFacebook,
    };

    try {
      const resbudgets = await axiosusers.patch(
        `/primarychannel/update-primarychannel`,
        data
      );

      if (resbudgets.status === 200) {
        console.log(resbudgets);

        navigate("/app/primary-channel");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
          }}
        >
          Update Primary Channel
        </Typography>

        <TextField
          sx={{ marginTop: `1.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.channelName}
          name="channelName"
          label="Channel Name"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.telegramUser}
          name="telegramUser"
          label="Telegram User"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.lineUser}
          name="lineUser"
          label="Line User"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.instagramUser}
          name="instagramUser"
          label="Instagram User"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.panpageFacebook}
          name="panpageFacebook"
          label="Fanpage"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          onChange={handleinput}
          sx={{ marginTop: `0.5rem` }}
          type="text"
          value={input.phoneNumber}
          name="phoneNumber"
          label="Phone Number"
          size="small"
          variant="outlined"
          fullWidth
        />
        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default EditPrimaryChannel;
