import React from "react";
import AddPrimaryChannel from "./AddPrimaryChannel";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Create Primary Channel");
  return (
    <div>
      <AddPrimaryChannel />
    </div>
  );
};

export default index;
