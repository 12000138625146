import GridsDetailOrders from "components/Grid/GridsDetailOrders";
import { CircularProgress, Divider, Typography } from "@mui/material";
import axiosusers from "utils/api/axiosusers";
import React, { useEffect, useState } from "react";
import BudgetsStatisticals from "./BudgetsStatisticals";
import TotalPackage from "./TotalPackage";
import AccordionsGames from "./AccordionsGames";
import useUserId from "hooks/useUserId";

const DetailGames = (props) => {
  const { id } = props;
  const [games, setGames] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userId } = useUserId();
  useEffect(() => {
    setLoading(true);
    if (userId) {
      Promise.all([
        axiosusers.get(`/games/games/${id}`),
        axiosusers.get(`/budgets/budgets-gameId/${id}`),
        axiosusers.get(`/wallets/wallets-gameId/${id}`),
      ])
        .then((results) => {
          const [gameId, budgets, wallets] = results.map((res) => res.data);
          setGames(gameId);
          setBudgets(budgets);
          setWallets(wallets);
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [id, userId]);
  return (
    <div>
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <>
          <Typography
            color="primary"
            variant="h4"
            sx={{
              fontWeight: 500,
              margin: (theme) => theme.spacing(0, 0, 3, 0),
              textAlign: "center",
            }}
          >
            Detail game
          </Typography>
          <GridsDetailOrders
            nameOne="Name"
            nameTwo={games.name}
            gridmain={false}
          />
          <GridsDetailOrders
            nameOne="Description:"
            nameTwo={games.description}
            gridmain={false}
          />
          <Divider sx={{ backgroundColor: "#000", margin: "10px 0px" }} />
          <BudgetsStatisticals budgets={budgets} />
          <Divider sx={{ backgroundColor: "#000", margin: "10px 0px" }} />
          <TotalPackage budgets={budgets} wallets={wallets} />
          <Divider sx={{ backgroundColor: "#000", margin: "10px 0px" }} />
          <AccordionsGames budgets={budgets} wallets={wallets} />
        </>
      )}
    </div>
  );
};

export default DetailGames;
