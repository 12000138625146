import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import axiosusers from "utils/api/axiosusers";
import FilledAlerts from "components/Alert/FilledAlerts";
import { useNavigate, useParams } from "react-router-dom";

const EditUsers = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [input, setInput] = useState({
    name: true,
    email: true,
    code: true,
    salary: "",
    bonus: true,
    bankName: true,
    bankNumber: true,
    bankAccount: true,
    zaloId: true,
    phoneNumber: true,
    maxAbsent: true,
    password: true,
    telegramId: true,
  });

  const [age, setAge] = React.useState("");

  const [errorMessage, seterrorMessage] = React.useState(false);
  const getProduct = async () => {
    const data = await axiosusers.get(`/usersLamlainao/profile/${id}`);

    setInput(data.data);
    setAge(data.data.role);
  };

  useEffect(() => {
    getProduct();
  }, [id]);
  const [state, setState] = React.useState({
    allowZalo: false,
    allowSms: false,
  });

  const handleChange = (e) => {
    setAge(e.target.value);
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  const [error, setError] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleinput = (e) => {
    if (!isValidEmail(input.email)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }

    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setState({ ...state, [e.target.name]: e.target.checked });
  };

  const onSubmit = async () => {
    if (input.role === "1") {
      input.role = "";
    }
    if (input.role === "") {
      return seterrorMessage("The role not found.");
    }
    if (input.password !== undefined && input.password.length < 6) {
      return seterrorMessage("password must be at least 6 characters");
    }
    const data = {
      id,
      name: input.name,
      email: input.email,
      code: age,
      password: input.password,
      salary: input.salary,
      bonus: input.bonus,
      bankName: input.bankName,
      bankAccount: input.bankAccount,
      zaloId: input.zaloId,
      phoneNumber: input.phoneNumber,
      maxAbsent: input.maxAbsent,
      allowSms: state.allowSms,
      allowZalo: state.allowZalo,
      telegramId: input.telegramId,
    };
    try {
      const res = await axiosusers.post(`/usersLamlainao/update-users`, data);
      console.log(res);
      navigate("/app/users");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <div>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Update users
        </Typography>
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.name}
          name="name"
          error={Boolean(input.name == "")}
          helperText={input.name == "" ? "Name is required" : ""}
          label="Name"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.email}
          name="email"
          error={Boolean(error)}
          helperText={error}
          label="Email Address"
          size="small"
          variant="outlined"
          fullWidth
        />
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          sx={{ marginTop: `0.5rem` }}
        >
          <InputLabel>Select Role</InputLabel>
          <Select
            value={age}
            onChange={handleChange}
            label="Select Role"
            name="code"
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Staff">Staff</MenuItem>
            <MenuItem value="Manager">Manager</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          name="password"
          label="Password"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.salary}
          name="salary"
          label="Salary"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.bonus}
          name="bonus"
          label="Bonus"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.bankAccount}
          name="bankAccount"
          label="Bank Account Number"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.bankName}
          name="bankName"
          label="Bank Name"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.zaloId}
          name="zaloId"
          label="Zalo Id"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.telegramId}
          name="telegramId"
          label="Telegram Id"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.phoneNumber}
          name="phoneNumber"
          label="Phone Number"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          sx={{ marginTop: `0.5rem` }}
          onChange={handleinput}
          type="text"
          value={input.maxAbsent}
          name="maxAbsent"
          label="Max Absent"
          size="small"
          variant="outlined"
          fullWidth
        />
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <label>Alow Zalo:</label>
            <Switch
              checked={
                input.allowZalo == 1
                  ? (state.allowZalo = true)
                  : state.allowZalo
              }
              name="allowZalo"
              color="primary"
              onChange={handleinput}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Grid>
          <Grid item xs={6}>
            <label>Alow SMS:</label>
            <Switch
              checked={
                input.allowSms == 1 ? (state.allowSms = true) : state.allowSms
              }
              name="allowSms"
              color="primary"
              onChange={handleinput}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Grid>
        </Grid>
        {errorMessage && (
          <FilledAlerts message={errorMessage} success="error" />
        )}
        <Button
          type="submit"
          sx={{ margin: (theme) => theme.spacing(5, 0) }}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
          onClick={onSubmit}
        >
          Update
        </Button>
      </div>
    </Box>
  );
};

export default EditUsers;
