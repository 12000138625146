import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { FormAutocomplete } from "hooks/FormAutocomplete";
import { useNavigate } from "react-router-dom";

const AddGroups = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const [clients, setClients] = React.useState([]);
  const [fieldValueWallet, setFieldValueWallet] = React.useState([]);
  const [input, setInput] = React.useState({
    type: "",
    groups: "",
    status: "",
  });
  const initialValues = {
    no: "",
    name: "",
    link: "",
    notes: "",
    numberLike: "0",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full name is required"),
    no: Yup.string().required(".No is required"),
  });
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const getProductData = async () => {
    const client = await axiosusers.get(`/facebook-manage/administrators`);
    setClients(client.data);
  };

  React.useEffect(() => {
    getProductData();
  }, []);
  const onSubmit = async (values) => {
    setDung(true);
    if (input.groups === "") {
      input.groups = "1";
      return seterrorMessage("The groups not found.");
    }
    const data = {
      no: values.no,
      name: values.name,
      groups: input.groups,
      numberLike: values.numberLike,
      link: values.link,
      notes: values.notes,
      fieldValueWallet,
    };
    try {
      await axiosusers.post(`/facebook-manage/create-managefacebook`, data);
      navigate("/app/facebook-manage/");
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
    }
  };
  const setChangeWallet = async () => {};
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new groups
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <FormAutocomplete
                placeholder="Select one or multiple"
                onChange={(event, values) => {
                  setFieldValueWallet(values);
                  setChangeWallet(values);
                }}
                multiple={true}
                filterSelectedOptions={true}
                sx={{ marginTop: `0.5rem` }}
                name="Wallet"
                label="Administrator"
                options={clients}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="no"
                label=".No"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.no && props.touched.no}
                helperText={<ErrorMessage name="no" />}
              />
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="name"
                label="Full Name"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.name && props.touched.name}
                helperText={<ErrorMessage name="name" />}
              />
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                sx={{ marginTop: `0.5rem` }}
                error={Boolean(errorMessage.length > 0 && input.groups == "1")}
              >
                <InputLabel>Select Group</InputLabel>
                <Select
                  value={input.groups}
                  onChange={handleChange}
                  label="Select Group"
                  name="groups"
                >
                  <MenuItem value="Group">Group</MenuItem>
                  <MenuItem value="Fanpage">Fanpage</MenuItem>
                </Select>
                {errorMessage > 0 && input.groups == "1" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="numberLike"
                label="Number of memes"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="link"
                label="Link"
                variant="outlined"
                size="small"
                fullWidth
              />

              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                multiline
                minRows="4"
                name="notes"
                label="Notes"
                variant="outlined"
                size="small"
                fullWidth
              />
              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddGroups;
