// Assuming the correct path to axiosclients module

import axiosusers from "utils/api/axiosusers";

const Logouts = async (jti) => {
  const dataSend = { jti };

  try {
    // Assuming you have a correct endpoint for sending OXXO data
    const res = await axiosusers.post(`/authLamlainao/logout`, dataSend);

    if (res.status === 200) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }
  } catch (error) {
    console.log(error);
  }
};

// Assuming you want to return the sendOxxo function from the module
export { Logouts };
