import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import { Tabtitle } from "components/title/Title";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FormAutocomplete } from "hooks/FormAutocomplete";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosusers from "utils/api/axiosusers";
import * as Yup from "yup";
const index = () => {
  Tabtitle("Create Friends Game Id");
  const navigate = useNavigate();
  const [fieldValueWallet, setFieldValueWallet] = useState([]);
  const [jsonWallets, setJsonWallets] = useState([]);
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [done, setDone] = React.useState(false);

  const [statusGame, setStatusGame] = useState("skinItemGeneral");
  const [status, setStatus] = useState("notFull");
  const handleChange = (e) => {
    setStatusGame(e.target.value);
  };
  const handleStatusGame = (e) => {
    setStatus(e.target.value);
  };

  useEffect(() => {
    Promise.all([axiosusers.get(`/games/games`)])
      .then((results) => {
        const [games] = results.map((res) => res.data);
        const giftcard = games.filter((item) => item.status == statusGame);
        setJsonWallets(giftcard);
      })
      .catch((error) => console.error(error));
  }, [statusGame]);
  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(0, "It's too short").required("Game ID is required"),
  });

  const onSubmit = async (values) => {
    setDone(true);
    if (fieldValueWallet?.id === undefined) {
      seterrorMessage("Please select a game");
      setDone(false);
      return;
    }
    const data = {
      gameId: fieldValueWallet?.id,
      name: values.name,
      status,
      statusGame,
    };
    try {
      const response = await axiosusers.post(
        `/friends/create-friends-game-id`,
        data
      );

      if (response.status === 200) {
        navigate("/app/friends-game-id/");
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(error.response.data.message);
      setDone(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: `auto` }}>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "30px",
          margin: (theme) => theme.spacing(0, 0, 3, 0),
        }}
      >
        Create new Friends Game Id
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form autoComplete="off">
            <FormControl
              size="small"
              fullWidth
              variant="outlined"
              style={{ marginTop: `1.5rem` }}
            >
              <InputLabel>Product</InputLabel>
              <Select
                value={statusGame}
                onChange={handleChange}
                label="Product"
                name="status"
              >
                <MenuItem value="game">Games-Top up</MenuItem>
                <MenuItem value="giftCard">Gift Card</MenuItem>
                <MenuItem value="softWare">softWare</MenuItem>
                <MenuItem value="account">Games Account</MenuItem>
                <MenuItem value="skinItemGeneral">Shin Item General</MenuItem>
              </Select>
            </FormControl>
            <FormAutocomplete
              onChange={(event, values) => {
                setFieldValueWallet(values);
              }}
              filterSelectedOptions={true}
              style={{ marginTop: `1rem` }}
              name="Game"
              label={statusGame
                .replace(/([A-Z])/g, " $1")
                .toLowerCase()
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })}
              options={jsonWallets}
            />

            <Field
              as={TextField}
              sx={{ marginTop: `0.5rem` }}
              name="name"
              label="Game ID"
              variant="outlined"
              size="small"
              fullWidth
              error={props.errors.name && props.touched.name}
              helperText={<ErrorMessage name="name" />}
            />
            <FormControl
              size="small"
              fullWidth
              variant="outlined"
              style={{ marginTop: `0.5rem` }}
            >
              <InputLabel>Status game id</InputLabel>
              <Select
                value={status}
                onChange={handleStatusGame}
                label="Status game id"
              >
                <MenuItem value="full">Full ID</MenuItem>
                <MenuItem value="notFull">Not Full ID</MenuItem>
              </Select>
            </FormControl>
            {errorMessage.length > 0 && (
              <FilledAlerts message={errorMessage} success="error" />
            )}

            <Button
              type="submit"
              sx={{ marginTop: `3rem` }}
              variant="contained"
              size="large"
              fullWidth
              color="primary"
              disabled={done}
            >
              Create
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default index;
