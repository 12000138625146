import { useJwt } from "react-jwt";

function useDecodedToken() {
  const { decodedToken, isExpired } = useJwt(
    localStorage.getItem("accessToken")
  );
  return { decodedToken, isExpired };
}

export default useDecodedToken;
