import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Box, Button, TextField, Typography } from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { useNavigate } from "react-router-dom";

const AddBlacklists = () => {
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);

  const initialValues = {
    fullName: "",
    notes: "",
  };
  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(0, "It's too short")
      .required("Accnout is required"),
  });
  const onSubmit = async (values) => {
    setDung(true);
    const data = {
      name: values.fullName,
      notes: values.notes,
    };
    try {
      await axiosusers.post(`/budgets/create-blacklists`, data);
      navigate("/app/blacklists/");
    } catch (error) {
      setDung(false);
      console.log(error?.response);
      seterrorMessage(error.response.data.message);
    }
  };
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new blacklists
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="fullName"
                label="Account"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.fullName && props.touched.fullName}
                helperText={<ErrorMessage name="fullName" />}
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                multiline
                minRows="4"
                name="notes"
                label="Notes"
                variant="outlined"
                size="small"
                fullWidth
              />

              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddBlacklists;
