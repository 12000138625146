import React from "react";
import Orders from "./Orders";
import fetchData from "utils/data";
import useSWR from "swr";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Orders");
  const {
    data: odersApi,
    error: ordersError,
    isLoading: ordersIsLoading,
  } = useSWR("/order/orders-new", (url) => fetchData(url));
  const { data: gamesApi } = useSWR("/games/games", (url) => fetchData(url));
  const { data: clientsApi } = useSWR("/clients/clients", (url) =>
    fetchData(url)
  );
  const { data: agencysApi } = useSWR("/agencys/agencys", (url) =>
    fetchData(url)
  );
  useSWR("/photos/photos-key", (url) => fetchData(url));
  return (
    <div>
      {ordersError && <div>failed to load</div>}
      {odersApi != undefined && (
        <Orders
          ordersApis={odersApi?.orderAll}
          ordersPages={odersApi?.pages}
          gamesApi={gamesApi}
          clientsApi={clientsApi}
          ordersIsLoading={ordersIsLoading}
          agencysApi={agencysApi}
        />
      )}
    </div>
  );
};

export default index;
