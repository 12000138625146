import useCountry from "hooks/useCountry";
import useUserId from "hooks/useUserId";
import axiosusers from "utils/api/axiosusers";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styles from "./style.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Fab,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useDecodedToken from "utils/token/useDecodedToken";

const Detail = (props) => {
  const { id } = props;

  const { countries } = useCountry();

  const { userId } = useUserId();
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);

  const [input, setInput] = useState({
    name: " ",
    orderNumber: " ",
    price: 0,
    amount: 0,
    notes: " ",
  });
  const [state, setState] = React.useState({
    positive: false,
  });
  const handleinput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  const [selectedCountry, setSelectedCountry] = useState({
    code: "",
    label: "",
    phone: "",
  });
  const [selectedGame, setSelectedGame] = useState({
    name: "",
    id: "",
  });
  const [jsonResuls, setJsonResuls] = useState([]);
  const [countrygiftcard, setCountrygiftcard] = useState([]);
  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), name: "", quantity: "", price: "", orderNumber: "1" },
  ]);

  const initialValues = {
    name: "",
    no: "",
    price: "",
    amount: "",
    Game: null,
    notes: "",
  };

  const validationSchema = Yup.object().shape({});

  const getProductData = async () => {
    const data = await axiosusers.get(`/wallets/wallets/${id}`);
    setInput(data.data);
    setSelectedCountry({ label: data.data.country });
    const data1 = await axiosusers.get(`/games/games`);
    setJsonResuls(data1.data);

    const gameId = await axiosusers.get(`/games/games/${data.data.gameId}`);
    setSelectedGame({
      name: gameId.data.name,
      id: gameId.data.id,
      status: gameId.data.status,
    });
    const buget = await axiosusers.get(`/budgets/budgets-walletId/${id}`);
    setInputFields(buget.data);
    const country = await axiosusers.get(`/countrys/country`);
    setCountrygiftcard(country.data);
  };

  useEffect(() => {
    getProductData();
  }, [id]);

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };

  const { decodedToken, isExpired } = useDecodedToken();

  React.useEffect(() => {
    if (isExpired) {
      if (decodedToken?.id === undefined) {
        window.location.href = "/login";
      }
    }
  }, [isExpired]);
  const formattedAmount = Number.isNaN(parseFloat(input.amount))
    ? ""
    : Number.parseFloat(input.amount);
  const formattedPrice = Number.isNaN(parseFloat(input.price))
    ? ""
    : Number.parseFloat(input.price);

  const countryData = countries.filter((item) => {
    return countrygiftcard.some((item2) => item2.country === item.label);
  });
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new wallet
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {() => (
            <Form autoComplete="off">
              <TextField
                onChange={handleinput}
                type="number"
                value={input.orderNumber}
                name="orderNumber"
                error={Boolean(input.orderNumber === "")}
                helperText={input.orderNumber === "" ? "No is required" : ""}
                label="No"
                size="small"
                variant="outlined"
                fullWidth
              />
              <div className={styles.fontText}></div>
              <Autocomplete
                className={styles.fontText}
                options={jsonResuls}
                fullWidth
                value={selectedGame}
                onChange={(event, value) => setSelectedGame(value)}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(jsonResuls) => jsonResuls.name}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(selectedGame === null)}
                    helperText={selectedGame === null ? "Game is required" : ""}
                    label="Game"
                    variant="outlined"
                  />
                )}
              />

              {selectedGame.status == "giftCard" && (
                <Autocomplete
                  id="country-select-demo"
                  size="small"
                  className={styles.fontText}
                  options={countryData}
                  value={selectedCountry}
                  onChange={(event, value) => setSelectedCountry(value)}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}

              <TextField
                onChange={handleinput}
                type="text"
                value={input.name}
                name="name"
                error={Boolean(input.name === "")}
                helperText={input.name === "" ? "Name is required" : ""}
                label="Name"
                size="small"
                variant="outlined"
                fullWidth
              />
              <TextField
                onChange={handleinput}
                sx={{ margin: (theme) => theme.spacing(1, 0) }}
                type="number"
                value={formattedPrice}
                name="price"
                error={Boolean(input.price === "")}
                helperText={input.price === "" ? "Price is required" : ""}
                label="Price"
                size="small"
                variant="outlined"
                fullWidth
              />
              <TextField
                onChange={handleinput}
                type="number"
                value={formattedAmount}
                name="amount"
                error={Boolean(input.amount === "")}
                helperText={input.amount === "" ? "Dollar is required" : ""}
                label="Price in Dollar"
                size="small"
                variant="outlined"
                fullWidth
              />
              <label>Positive Wallet:</label>
              <Switch
                checked={
                  input.positive == 1 ? (state.positive = true) : state.positive
                }
                color="primary"
                onChange={handleinput}
                name="positive"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <div className={styles.fontText}></div>
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, fontSize: "14px" }}
              >
                Budgets:
              </Typography>
              {inputFields.map((inputField, id) => (
                <div
                  style={{ display: "flex", margin: "10px 0px" }}
                  key={inputField.id}
                >
                  <TextField
                    type="text"
                    name="name"
                    value={inputField.name}
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                    label="Name"
                    variant="outlined"
                    size="small"
                  />

                  <Box sx={{ margin: (theme) => theme.spacing(0, 1, 0, 1) }}>
                    <TextField
                      type="number"
                      name="quantity"
                      value={inputField.quantity}
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                      label="Quantity"
                      variant="outlined"
                      size="small"
                    />
                  </Box>
                  <TextField
                    sx={{ width: "30ch" }}
                    type="number"
                    name="price"
                    value={inputField.price}
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                    label="Price"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <div>
                          {inputFields.length === Number(id + 1) && (
                            <Fab color="primary" aria-label="add" size="small">
                              <AddIcon />
                            </Fab>
                          )}
                        </div>
                      ),
                    }}
                  />
                  {inputFields.length !== 1 && (
                    <IconButton color="primary" disabled={true}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              ))}

              <TextField
                label="Notes"
                multiline
                minRows="4"
                onChange={handleinput}
                name="notes"
                value={input.notes}
                sx={{ margin: (theme) => theme.spacing(2, 0, 2, 0) }}
                variant="outlined"
                fullWidth
              />
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default Detail;
