import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import SearchIcon from "@mui/icons-material/Search";
import axiosusers from "utils/api/axiosusers";
import _ from "lodash";
import Images from "components/Avatar/Images";
import RepeatIcon from "@mui/icons-material/Repeat";
import CancelIcon from "@mui/icons-material/Cancel";

import useSWR from "swr";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

const OrderWarhouses = () => {
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const idProductRef = useRef();
  const idProductRefpage = useRef();
  const idProductRef_name = useRef();
  const idProductRef_status = useRef();
  const idProductRef_paymentStatus = useRef();
  const [loading, setLoading] = useState(false);

  const [Message, setMessage] = useState(false);
  const [Message2, setMessage2] = useState("");
  const [DonePage, setDonePage] = useState([]);
  const [selectedagencys, setSelectedagencys] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [selectedname, setSelectedname] = useState([]);
  const [orderapi, setOrderapi] = useState([]);
  const [orderapi2, setOrderapi2] = useState([]);

  const [texts, setTests] = useState("");
  const [TrueClick, setTrueClick] = useState(false);
  const [searchCode, setSearchCode] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const pageSize = 10;
  const [pageCount, setPageCount] = useState(0);
  const fetchDataTest = async (url) => {
    const results = await axiosusers.get(url);
    return results.data;
  };
  const { data, error, isLoading } = useSWR(
    `/order/orderapi-warhouses`,
    fetchDataTest
  );
  console.log(error);
  const fetchData = async () => {
    Promise.all([axiosusers.get(`/order/agencys`)])
      .then((results) => {
        const [orderapi] = results.map((res) => res.data);

        setOrderapi(orderapi);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    if (!isLoading) {
      setPageCount(Math.ceil(data.length / pageSize));
      setProduct(data);
      setDonePage(data);
      setPagination(_(data).slice(0).take(pageSize).value());
      setOrderapi2(data);
    }

    fetchData();
  }, [isLoading]);
  useEffect(() => {
    const filterResults = () => {
      let filteredResults = product;
      setCurrentPage(1);

      if (searchStatus && searchStatus !== "none") {
        filteredResults = filteredResults.filter(
          (item) => item.status === searchStatus
        );
      } else {
        filteredResults = filteredResults.filter(
          (item) => item.status === "inProgress"
        );
      }

      if (searchCode) {
        const numberRegex = new RegExp(`.*${searchCode}.*`, "i");
        filteredResults = filteredResults.filter((item) =>
          numberRegex.test(item.orderId)
        );
      }

      if (selectedname?.id !== undefined) {
        filteredResults = filteredResults
          .sort((a, b) => a.numberOrder - b.numberOrder)
          .filter((item) => item.agencyId === selectedname?.id);
      }

      return filteredResults;
    };

    const updatePagination = (filteredResults) => {
      const paginatedResults = _(filteredResults).take(pageSize).value();
      setPageCount(Math.ceil(filteredResults.length / pageSize));
      setPagination(paginatedResults);
      setDonePage(filteredResults);
    };
    if (!isLoading) {
      const filteredResults = filterResults();
      updatePagination(filteredResults);
    }
  }, [
    product,
    setCurrentPage,
    pageSize,
    setPageCount,
    setPagination,
    setDonePage,
    searchStatus,
    searchCode,
    selectedname,
  ]);

  setTimeout(function () {
    setMessage(false);
  }, 200);

  const handleChuyen = async () => {
    setTrueClick(true);
    const dataSend = {
      id: idProductRef.current,
      agencyId: selectedagencys?.id,
    };
    try {
      const response = await axiosusers.patch(
        `/deletes/chuyen-orders-chuyen`,
        dataSend
      );
      if (response.status === 200) {
        setOpen2(false);
        setTests("");
        setOpen(false);
        console.log(response);
        setMessage2(false);
        setTrueClick(false);
        setTimeout(function () {
          fetchData();
        }, 10);
      }

      setSelectedagencys([]);
    } catch (error) {
      console.log(error);
      setTrueClick(false);
      setTests(error.response.data.message);
    }
  };

  const handleClickOpen2 = (id, page, name, status) => {
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
    idProductRef_status.current = status;
    setOpen2(true);
    let mess = "";
    if (status == "cancel") {
      mess = "Are you sure to cancel this order?";
    }
    if (status == "delete") {
      mess = "Are you sure to delete this order?";
    }
    if (status == "undo") {
      mess =
        "This order has been canceled are you sure you want to return it to this oven?";
    }
    setMessage2(mess);
  };
  const handleClickRepeat = async (id, page, agencyId, cancel) => {
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_paymentStatus.current = cancel;

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };
  const Pagechane = (event, value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(DonePage).slice(startIndex).take(pageSize).value();
    setPagination(pagination);
    setCurrentPage(value);
  };

  const handleDelete = async () => {
    const dataSend = {
      id: idProductRef.current,
    };

    try {
      const response = await axiosusers.patch(
        `/deletes/chuyen-orders-cancel-lo`,
        dataSend
      );
      if (response.status === 200) {
        setOpen2(false);
        setTests("");
        setOpen(false);
        console.log(response);
        setMessage2(false);
        setTrueClick(false);
        setTimeout(function () {
          fetchData();
        }, 10);
      }

      setSelectedagencys([]);
    } catch (error) {
      console.log(error);
      setTrueClick(false);
      setTests(error.response.data.message);
    }
  };
  let totalDoneQuantity = 0;
  let inProgress = 0;
  let inProgress2 = 0;
  let totalOder = 0;
  let totalinProgress = 0;
  let totalDone = 0;
  if (!isLoading) {
    const Done = orderapi2.filter((item) => item.status === "completed");
    totalDone = Done.reduce((acc, item) => acc + Number(item.totalRecharge), 0);

    totalDoneQuantity = Done.reduce((acc) => acc + Number(1), 0);

    inProgress = orderapi2.filter((item) => item.status === "inProgress");
    totalinProgress = inProgress.reduce(
      (acc, item) => Number(acc + Number(item.totalRecharge)),
      0
    );

    inProgress2 = orderapi2.filter((item) => item.status === "inProgress");
    totalOder = inProgress2.reduce((acc) => acc + Number(1), 0);
  }
  const numbers = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  });
  return (
    <div>
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutlinedInput
                fullWidth
                onChange={(e) => {
                  setSearchCode(e.target.value);
                }}
                placeholder="Search by order id"
                name="searchCode"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Payment Status</InputLabel>
                <Select
                  value={searchStatus}
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                  }}
                  label="Payment Status"
                  name="searchStatus"
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="completed">Done</MenuItem>
                  <MenuItem value="cancel">Cancel</MenuItem>
                  <MenuItem value="inProgress">In progress</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Autocomplete
                options={orderapi}
                fullWidth
                onChange={(event, value) => setSelectedname(value)}
                getOptionLabel={(clients) => clients.nameGroup}
                renderInput={(params) => (
                  <TextField {...params} label="Agency" variant="outlined" />
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {Message && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {loading && (
        <div className={styles.center}>
          <CircularProgress />
        </div>
      )}
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
          variant="outlined"
        >
          <Grid container spacing={1}>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography
                color="primary"
                sx={{ fontSize: "16px", fontWeight: 500 }}
              >
                Total
              </Typography>{" "}
            </Grid>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography
                color="primary"
                sx={{ fontSize: "16px", fontWeight: 500 }}
              >
                Quantity Total
              </Typography>{" "}
            </Grid>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography
                color="primary"
                sx={{ fontSize: "16px", fontWeight: 500 }}
              >
                Quantity In progress
              </Typography>{" "}
            </Grid>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography
                color="primary"
                sx={{ fontSize: "16px", fontWeight: 500 }}
              >
                Total In progress
              </Typography>{" "}
            </Grid>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography
                color="primary"
                sx={{ fontSize: "16px", fontWeight: 500 }}
              >
                Quantity Done
              </Typography>{" "}
            </Grid>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography
                color="primary"
                sx={{ fontSize: "16px", fontWeight: 500 }}
              >
                Total Done
              </Typography>{" "}
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography sx={{ fontWeight: 400 }}>
                {Number(numbers.format(totalinProgress + totalDone))}
              </Typography>{" "}
            </Grid>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography sx={{ fontWeight: 400 }}>
                {Number(totalOder + totalDoneQuantity)}
              </Typography>{" "}
            </Grid>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography sx={{ fontWeight: 400 }}>{totalOder}</Typography>{" "}
            </Grid>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography sx={{ fontWeight: 400 }}>
                {numbers.format(totalinProgress)}
              </Typography>{" "}
            </Grid>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography sx={{ fontWeight: 400 }}>
                {totalDoneQuantity}
              </Typography>{" "}
            </Grid>
            <Grid lg={2} md={2} item sm={2} xs={2}>
              <Typography sx={{ fontWeight: 400 }}>
                {numbers.format(totalDone)}
              </Typography>{" "}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {!isLoading && (
        <TableContainer
          component={Paper}
          sx={{ width: { md: "100%", xs: "88vw" } }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>STT</TableCell>
                <TableCell>Order Id</TableCell>
                <TableCell>Games</TableCell>
                <TableCell>Recharge</TableCell>
                <TableCell>Agency</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Created date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagination.map((row, stt) => {
                const nullCreate = row.createdAt.split("T");
                const dateCreate = nullCreate[0].split("-");
                const dateCreateAt =
                  dateCreate[2] + "/" + dateCreate[1] + "/" + dateCreate[0];
                return (
                  <TableRow
                    key={row.id}
                    style={
                      row.status == "cancel"
                        ? { backgroundColor: "#CF4848 " }
                        : {}
                    }
                  >
                    <TableCell>
                      {selectedname?.id
                        ? row.numberOrder
                        : Number(stt) + 1 + (currentPage - 1) * pageSize}
                    </TableCell>
                    <TableCell>{row.orderId}</TableCell>
                    <TableCell>
                      <Images key={row.id} nameImage={row.image} />
                    </TableCell>
                    <TableCell>{row.totalRecharge}</TableCell>
                    <TableCell>
                      {orderapi.find((itemG) => itemG.id === row.agencyId)
                        ?.nameGroup || ""}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() =>
                          navigator.clipboard.writeText(`${row.info}`)
                        }
                        color="primary"
                        size="small"
                      >
                        Copy
                      </Button>
                    </TableCell>
                    <TableCell>{dateCreateAt}</TableCell>
                    <TableCell>
                      {row.status == "inProgress" && (
                        <IconButton
                          color="primary"
                          onClick={() =>
                            handleClickRepeat(
                              row.orderId,
                              DonePage,
                              row.agencyId,
                              "chuyen_cancel"
                            )
                          }
                        >
                          <RepeatIcon />
                        </IconButton>
                      )}
                      {row.status != "cancel" && (
                        <IconButton
                          color="primary"
                          onClick={() =>
                            handleClickOpen2(
                              row.orderId,
                              DonePage,
                              row.numberOrder,
                              "cancel"
                            )
                          }
                        >
                          <CancelIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {product.length > 10 && (
            <Grid
              container
              spacing={0}
              sx={{
                justifyContent: "center",
                color: "#000",
                display: "flex",
                padding: "10px",
              }}
            >
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={Pagechane}
                size="small"
                color="primary"
              />
            </Grid>
          )}
        </TableContainer>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ maxWidth: "600px", padding: "10px" }}>
            <Autocomplete
              sx={{
                margin: (theme) => theme.spacing(1, 0),
                minWidth: { md: "500px", sm: "auto" },
              }}
              className={styles.Autocomplete}
              options={orderapi.filter(
                (item) => item.id !== selectedagencys?.id
              )}
              fullWidth
              onChange={(event, value) => setSelectedagencys(value)}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(clients) => clients.nameGroup}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(selectedagencys === null)}
                  helperText={
                    selectedagencys === null ? "Agency is required" : ""
                  }
                  fullWidth
                  label="Agency"
                  variant="outlined"
                />
              )}
            />
            {texts && <div style={{ color: "red" }}>{texts}</div>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleChuyen}
            autoFocus
            color="primary"
            disabled={TrueClick}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ maxWidth: "600px", padding: "10px" }}>
            {Message2}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderWarhouses;
