import React from "react";
import Details from "./Details";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Detail Order");

  return (
    <div>
      <Details />
    </div>
  );
};

export default index;
