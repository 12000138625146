import React from "react";
import AdvertiseFee from "./AdvertiseFee";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Advertise Fee");
  return (
    <div>
      <AdvertiseFee />
    </div>
  );
};

export default index;
