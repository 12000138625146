import {
  Autocomplete,
  AvatarGroup,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import useDecodedToken from "utils/token/useDecodedToken";
import React from "react";
import styles from "./style.module.css";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axiosusers from "utils/api/axiosusers";
import _ from "lodash";
import Images from "components/Avatar/Images";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
const Agencys = () => {
  const { decodedToken } = useDecodedToken();
  const idProductRef_status = React.useRef();
  const idProductRef_clear = React.useRef();
  const [product, setProduct] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pagination, setPagination] = React.useState([]);
  const pageSize = 10;
  const [pageCount, setPageCount] = React.useState(0);
  const idProductRef = React.useRef();
  const idProductRefpage = React.useRef();
  const idProductRef_name = React.useRef();
  const [Message, setMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [DonePage, setDonePage] = React.useState([]);
  const [orderapi, setOrderapi] = React.useState([]);
  const [wallets, setWallets] = React.useState([]);
  const [orderapi2, setOrderapi2] = React.useState([]);
  const [clientgames, setClientGames] = React.useState([]);
  const [payments, setPayments] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [searchStatus, setSearchStatus] = React.useState("");
  const [searchCode, setSearchCode] = React.useState("");

  const fetchDataAll = async () => {
    Promise.all([
      axiosusers.get(`/order/agencys`),
      axiosusers.get(`/games/games`),
      axiosusers.get(`/order/orderapi`),
      axiosusers.get(`/order/agencygames`),
      axiosusers.get(`/order/agencywallets`),
    ])
      .then((results) => {
        const [transactions, payments, orderapi, agencygames, wallets] =
          results.map((res) => res.data);
        setProduct(transactions);
        setPageCount(Math.ceil(transactions.length / pageSize));
        setDonePage(transactions);
        setPagination(_(transactions).slice(0).take(pageSize).value());

        setPayments(payments);
        setOrderapi(orderapi);
        setClientGames(agencygames);
        setOrderapi2(orderapi);
        setWallets(wallets);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };
  React.useEffect(() => {
    setLoading(true);

    fetchDataAll();
  }, []);
  const fetchData = async () => {
    let results = product;

    if (searchCode) {
      setCurrentPage(1);
      results = results.filter((item) => {
        const nameRegex = new RegExp(`.*${searchCode}.*`, "i");
        return nameRegex.test(item.nameGroup);
      });
    }
    if (searchStatus) {
      setCurrentPage(1);
      results = results.filter((item) => item.status === searchStatus);
    }
    if (selected && selected.id !== undefined) {
      results = results.filter((transaction) => {
        return clientgames.some(
          (order) =>
            order.agencyId === transaction.id && selected.id === order.gameId
        );
      });
    }
    const page = (currentPage - 1) * pageSize;
    const paginatedResults = _(results).slice(page).take(pageSize).value();
    setPageCount(Math.ceil(results.length / pageSize));
    setPagination(paginatedResults);
    setDonePage(results);
  };

  React.useEffect(() => {
    fetchData();
  }, [searchCode, searchStatus, selected, product]);
  const handleClickOpen = (id, page, name) => {
    setOpen(true);
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
  };
  const handleClickOpen2 = (id, page, name, status, clear) => {
    setOpen2(true);
    idProductRef.current = id;
    idProductRefpage.current = page;
    idProductRef_name.current = name;
    idProductRef_status.current = status;
    idProductRef_clear.current = clear;
  };

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };
  const handleChuyen = async () => {
    setMessage(true);
    setOpen2(false);

    const dataSend = {
      agencyId: idProductRef.current,
    };
    try {
      const response = await axiosusers.post(
        `/agencys/update-agencys-${idProductRef_status.current}`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
        setMessage(false);
        fetchData();
      }
    } catch (error) {
      setMessage(false);
      console.log(error);
    }
    fetchDataAll();
  };
  const handleDelete = async () => {
    setMessage(true);
    const dataSend = {
      id: idProductRef.current,
      status: idProductRef_name.current,
    };

    try {
      const response = await axiosusers.patch(
        `/deletes/delete-agencys`,
        dataSend
      );
      if (response.status === 200) {
        setMessage(false);
        console.log(response);
        if (idProductRef_name.current === "Delete") {
          setProduct(
            product.filter((item) => {
              return item.id !== idProductRef.current;
            })
          );
        } else {
          setProduct((prevProducts) => {
            return prevProducts.map((item) => {
              if (item.id === idProductRef.current) {
                // Update the status for the item with matching ID
                return { ...item, status: idProductRef_name.current };
              }
              return item;
            });
          });
        }
      }
    } catch (error) {
      setMessage(false);
      console.log(error);
    }

    setOpen(false);
  };
  const Pagechane = (event, value) => {
    const startIndex = (value - 1) * pageSize;
    const pagination = _(DonePage).slice(startIndex).take(pageSize).value();
    setPagination(pagination);
    setCurrentPage(value);
  };
  const [input, setInput] = React.useState({ message: "" });
  const handleinput = (event) => {
    setInput({ ...input, message: event.target.value });
  };

  const submitSend = async () => {
    if (input.message == "" || input.message == " ") {
      return setInput({ ...input, message: " " });
    }
    const dataSend = {
      message: input.message,
    };
    try {
      const response = await axiosusers.post(
        `/agencys/agency-message`,
        dataSend
      );
      if (response.status === 200) {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }

    setInput({ ...input, message: "" });
  };
  const numbers = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  });
  return (
    <div>
      {decodedToken?.role == "Admin" && (
        <a href="/app/order-agencys/add" className={styles.link}>
          <Button variant="contained" color="primary">
            ADD agency
          </Button>
        </a>
      )}
      <Grid container justifyContent="center" className={styles.centerGrid}>
        <Paper
          variant="outlined"
          sx={{
            flexGrow: 1,
            padding: (theme) => theme.spacing(3),
            margin: "auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <OutlinedInput
                fullWidth
                onChange={(e) => {
                  setSearchCode(e.target.value);
                }}
                placeholder="Search by name"
                name="code"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Autocomplete
                options={payments}
                fullWidth
                onChange={(event, value) => setSelected(value)}
                getOptionLabel={(payments) => payments.name}
                renderInput={(params) => (
                  <TextField {...params} label="Game" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={searchStatus}
                  onChange={(e) => {
                    setSearchStatus(e.target.value);
                  }}
                  label="Status"
                  name="status"
                >
                  <MenuItem value="limit">Stop</MenuItem>
                  <MenuItem value="trustSeller">Default</MenuItem>
                  <MenuItem value="Prioritize">Prioritize</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {Message && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {loading && (
        <div className={styles.center}>
          <CircularProgress />
        </div>
      )}
      <div style={{ maxWidth: 600, margin: `auto` }}>
        <TextField
          style={{ margin: "10px 0px" }}
          onChange={handleinput}
          value={input.message}
          multiline
          minRows="4"
          name="message"
          label="Message"
          variant="outlined"
          error={Boolean(input.message == " ")}
          size="small"
          fullWidth
        />
        <Button
          type="submit"
          onClick={submitSend}
          variant="contained"
          size="large"
          fullWidth
          color="primary"
        >
          Send
        </Button>
      </div>
      {pagination.length > 0 && pagination[0].pause === "" && (
        <div className={styles.link}>
          <Button
            onClick={() => handleClickOpen2("", "", "", "pause")}
            variant="contained"
            color="primary"
          >
            Pause
          </Button>
        </div>
      )}
      {pagination.length > 0 && pagination[0].pause === "pause" && (
        <div className={styles.link}>
          <Button
            onClick={() => handleClickOpen2("", "", "", "cancelPause")}
            variant="contained"
            color="primary"
          >
            cancel Pause
          </Button>
        </div>
      )}
      <TableContainer
        component={Paper}
        sx={{ width: { md: "100%", xs: "88vw" } }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {decodedToken?.role == "Admin" && <TableCell>Id</TableCell>}
              <TableCell>Name</TableCell>
              {decodedToken?.role == "Admin" && <TableCell></TableCell>}
              {decodedToken?.role == "Admin" && (
                <TableCell>In progress</TableCell>
              )}
              {decodedToken?.role == "Admin" && <TableCell>Done</TableCell>}
              <TableCell>Game</TableCell>
              <TableCell>wallets</TableCell>
              <TableCell>status</TableCell>
              <TableCell>Pause</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Send amount</TableCell>
              <TableCell></TableCell>
              {decodedToken?.role == "Admin" && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {pagination.map((row) => {
              const gamesOfClient = orderapi.filter(
                (item) =>
                  item.agencyId === row.id && item.status === "inProgress"
              );
              const totalGames = gamesOfClient.reduce(
                (acc, item) => acc + Number(item.totalRecharge),
                0
              );

              const Done = orderapi2.filter(
                (item) =>
                  item.agencyId === row.id && item.status === "completed"
              );
              const totalDone = Done.reduce(
                (acc, item) => acc + Number(item.totalRecharge),
                0
              );

              const walletsNames = wallets
                .filter((itemC) => itemC.agencyId == row.id)
                .map((itemC) => itemC.name + ", ")
                .join(" ")
                .replace(/,([^,]*)$/, "$1");
              return (
                <TableRow key={row.id}>
                  {decodedToken?.role == "Admin" && (
                    <TableCell>{row.id}</TableCell>
                  )}
                  <TableCell>{row.nameGroup}</TableCell>
                  {decodedToken?.role == "Admin" && (
                    <TableCell>
                      <Button
                        onClick={() =>
                          handleClickOpen2(
                            row.id,
                            DonePage,
                            row.teleId,
                            "clear-throwback",
                            totalGames
                          )
                        }
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        Clear throwback
                      </Button>
                    </TableCell>
                  )}
                  {decodedToken?.role == "Admin" && (
                    <TableCell>{numbers.format(totalGames)}</TableCell>
                  )}
                  {decodedToken?.role == "Admin" && (
                    <TableCell>{numbers.format(totalDone)}</TableCell>
                  )}
                  <TableCell>
                    <AvatarGroup max={4}>
                      {clientgames.map((item) => {
                        if (item.agencyId == row.id) {
                          return <Images key={row.id} nameImage={item.image} />;
                        }
                      })}
                    </AvatarGroup>
                  </TableCell>
                  <TableCell style={{ maxWidth: "200px" }}>
                    {walletsNames}
                  </TableCell>
                  <TableCell
                    style={
                      row.status === "Prioritize"
                        ? { color: "blue" }
                        : row.status === "limit"
                        ? { color: "red" }
                        : null
                    }
                  >
                    {row.status == "limit"
                      ? "Stop"
                      : row.status == "trustSeller"
                      ? "Default"
                      : row.status}
                  </TableCell>
                  <TableCell style={{ color: "red" }}>{row.pause}</TableCell>
                  <TableCell>
                    <pre>{row.notes}</pre>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={"Send the total amount."}
                      placement="top"
                      arrow
                    >
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleClickOpen2(
                            row.id,
                            DonePage,
                            row.teleId,
                            "payment"
                          )
                        }
                      >
                        <ScheduleSendIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={"Stop"} placement="top" arrow>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleClickOpen(row.id, DonePage, "limit")
                        }
                      >
                        <DoDisturbIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Default"} placement="top" arrow>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleClickOpen(row.id, DonePage, "trustSeller")
                        }
                      >
                        <DoneIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Prioritize"} placement="top" arrow>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleClickOpen(row.id, DonePage, "Prioritize")
                        }
                      >
                        <DoneAllIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  {decodedToken?.role == "Admin" && (
                    <TableCell size="small" align="center">
                      <a href={`/app/order-agencys/edit/${row.id}`}>
                        <IconButton color="primary">
                          <EditIcon />
                        </IconButton>
                      </a>
                      {totalGames == 0 && (
                        <IconButton
                          color="primary"
                          onClick={() =>
                            handleClickOpen(row.id, DonePage, "Delete")
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {product.length > 10 && (
          <Grid
            container
            spacing={0}
            sx={{
              justifyContent: "center",
              color: "#000",
              display: "flex",
              padding: "10px",
            }}
          >
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={Pagechane}
              size="small"
              color="primary"
            />
          </Grid>
        )}
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to{" "}
            {idProductRef_name.current === "limit"
              ? "Stop"
              : idProductRef_name.current === "trustSeller"
              ? "Default"
              : idProductRef_name.current}{" "}
            this agency?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: 600 } }}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {idProductRef_status.current == "clear"
              ? idProductRef_clear.current > 0
                ? "Clear if there are any processes still running with the notification that there are pending orders in the oven, so it cannot be cleared. Please handle the orders first and then proceed with the clear."
                : `Are you sure to ${idProductRef_status.current} this agency??`
              : `Are you sure to ${idProductRef_status.current} this agency??`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {idProductRef_status.current == "clear" &&
            idProductRef_clear.current == 0 && (
              <Button onClick={handleChuyen} autoFocus color="primary">
                Ok
              </Button>
            )}
          {idProductRef_status.current != "clear" && (
            <Button onClick={handleChuyen} autoFocus color="primary">
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Agencys;
