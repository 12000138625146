import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import useUserId from "hooks/useUserId";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";

import { FormAutocomplete } from "hooks/FormAutocomplete";
import { useNavigate } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const AddAgencys = () => {
  Tabtitle("Create agencys");
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);
  const { userId } = useUserId();
  const [input, setInput] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [jsonWallets, setJsonWallets] = React.useState([]);
  const [, setInputBudget] = React.useState([]);
  const [fieldValueWallet, setFieldValueWallet] = React.useState([]);
  const [jsonWallets2, setJsonWallets2] = React.useState([]);
  const [jsonBudgets, setJsonBudgets] = React.useState([]);
  const [fieldValueWallet3, setFieldValueWallet3] = React.useState([]);
  const [fieldValueWallet2, setFieldValueWallet2] = React.useState([]);
  const setChangeWallet2 = async () => {};
  const setChangeWallet = async (value) => {
    if (value.length > 0) {
      const jsonWalletsValue = fieldValueWallet3.filter((budget) => {
        return value.some((wallet) => wallet.id == budget.gameId);
      });
      setJsonWallets2(jsonWalletsValue);
    } else {
      const jsonWalletsValue = fieldValueWallet3;
      setJsonWallets2(jsonWalletsValue);
    }
  };
  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  React.useEffect(() => {
    Promise.all([
      axiosusers.get(`/games/games`),
      axiosusers.get(`/wallets/wallets`),
      axiosusers.get(`/budgets/budgets`),
    ])
      .then((results) => {
        const [games, wallets, budgets] = results.map((res) => res.data);

        setJsonWallets(games);
        setJsonWallets2(wallets);
        setFieldValueWallet3(wallets);
        setJsonBudgets(budgets);
      })
      .catch((error) => console.error(error));
  }, []);
  React.useEffect(() => {
    if (userId) {
      if (userId.role != "Admin") {
        window.location.href = "/login";
      }
    }
  }, []);
  const initialValues = {
    fullName: "",
    teleId: "",
    notes: "",
  };
  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(0, "It's too short")
      .required("Name is required"),
    teleId: Yup.string()
      .min(0, "It's too short")
      .required("telegram ID is required"),
  });

  const onSubmit = async (values) => {
    setDung(true);
    let errorMessage = "";
    filteredAgencys.forEach((item) => {
      if (item.usedQuantity == "0" || item.usedQuantity == "") {
        errorMessage = "The data budgets invalid. Please check again";
      }
    });
    if (errorMessage !== "") {
      return seterrorMessage(errorMessage);
    }
    const dataBudgets = {
      teleId: values.teleId,
      nameGroup: values.fullName,
      currentPrice: checked,
      status: input.status,
      notes: values.notes,
      agencysgames: fieldValueWallet,
      agencyswallets: fieldValueWallet2,
      budgetAgencys: filteredAgencys,
    };

    try {
      const resbudgets = await axiosusers.post(
        `/agencys/create-agencys`,
        dataBudgets
      );

      if (resbudgets.status === 200) {
        console.log(resbudgets);
        setDung(true);

        navigate("/app/order-agencys");
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(error.response.data.message);
      setDung(false);
    }
  };

  const handleChangeInputWallet = (id, event) => {
    const newInputFields = fieldValueWallet2.map((w) => {
      if (id === w.id) {
        w[event.target.name] = event.target.checked;
      }
      return w;
    });
    setFieldValueWallet2(newInputFields);
  };
  const filteredAgencys = jsonBudgets.filter((agency) => {
    return fieldValueWallet2.some((wallet) => wallet.id == agency.walletId);
  });
  const handleChangeInputBudget = (id, event) => {
    const newjsonBudgets = filteredAgencys.map((w) => {
      if (id === w.id) {
        w[event.target.name] = event.target.value;
      }
      return w;
    });
    setInputBudget(newjsonBudgets);
  };
  let number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new agencys
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ marginTop: `0.5rem` }}
              >
                <InputLabel>Select Product</InputLabel>
                <Select
                  value={input.statusGame}
                  onChange={handleChange}
                  label="Select Product"
                  name="statusGame"
                >
                  <MenuItem value="game">Games-Top up</MenuItem>
                  <MenuItem value="giftCard">Gift Card</MenuItem>
                  <MenuItem value="softWare">SoftWare Account</MenuItem>
                  <MenuItem value="account">Games Account</MenuItem>
                </Select>
              </FormControl>
              <FormAutocomplete
                placeholder="Select one or multiple"
                onChange={(event, values) => {
                  setFieldValueWallet(values);
                  setChangeWallet(values);
                }}
                multiple={true}
                filterSelectedOptions={true}
                style={{ marginTop: `0.5rem` }}
                name="Game"
                label="Product"
                options={
                  input.statusGame
                    ? jsonWallets.filter(
                        (game) => game.status === input.statusGame
                      )
                    : jsonWallets
                }
              />
              <FormAutocomplete
                placeholder="Select one or multiple"
                onChange={(event, values) => {
                  setFieldValueWallet2(values);
                  setChangeWallet2(values);
                }}
                multiple={true}
                filterSelectedOptions={true}
                style={{ marginTop: `0.5rem` }}
                name="Wallets"
                label="Wallets"
                options={jsonWallets2}
              />
              {fieldValueWallet2 &&
                fieldValueWallet2.length != 0 &&
                fieldValueWallet2.map((item) => {
                  return (
                    <div key={item.id}>
                      <div style={{ display: "flex" }}>
                        <Typography
                          sx={{
                            flexGrow: 1,
                            fontWeight: 500,
                            fontSize: "16px",
                            minWidth: "50%",
                            margin: (theme) => theme.spacing(2, 2, 0, 0),
                          }}
                        >
                          Wallet name: {item.name}
                        </Typography>
                        <div>
                          <label>Alow budget:</label>
                          <Switch
                            name="allowBudget"
                            color="primary"
                            onChange={(event) =>
                              handleChangeInputWallet(item.id, event)
                            }
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                        </div>
                      </div>

                      <Box
                        style={{
                          display: "flex",
                          margin: (theme) => theme.spacing(1, 0, 0, 0),
                        }}
                      >
                        <Box sx={{ flexBasis: { xl: "33.33%", sm: "40%" } }}>
                          <Typography>Budget name</Typography>
                        </Box>
                        <Box sx={{ flexBasis: { xl: "33.33%", sm: "40%" } }}>
                          <Typography>Default Price</Typography>
                        </Box>
                        <Box sx={{ flexBasis: { xl: "33.33%", sm: "30%" } }}>
                          <Typography>Agency Price</Typography>
                        </Box>
                      </Box>
                      {fieldValueWallet2 &&
                        fieldValueWallet2.length != 0 &&
                        filteredAgencys
                          .sort((a, b) => a.orderNumber - b.orderNumber)
                          .map((budget) => {
                            if (item.id == budget.walletId) {
                              return (
                                <div
                                  style={{ display: "flex" }}
                                  key={budget.id}
                                >
                                  <Box
                                    sx={{
                                      flexBasis: { xl: "33.33%", sm: "40%" },
                                    }}
                                  >
                                    <Typography>{budget.name}</Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      flexBasis: { xl: "33.33%", sm: "30%" },
                                    }}
                                  >
                                    <Typography>
                                      {number.format(Number(budget.price))} ₫
                                    </Typography>
                                  </Box>
                                  <div
                                    style={{
                                      flexBasis: { xl: "33.33%", sm: "40%" },
                                    }}
                                  >
                                    <TextField
                                      value={budget.usedQuantity}
                                      onChange={(event) =>
                                        handleChangeInputBudget(
                                          budget.id,
                                          event
                                        )
                                      }
                                      type="number"
                                      name="usedQuantity"
                                      label="Price"
                                      variant="outlined"
                                      size="small"
                                    />
                                    <Typography>
                                      {number.format(
                                        Number(budget.usedQuantity)
                                      )}{" "}
                                      ₫
                                    </Typography>
                                  </div>
                                </div>
                              );
                            }
                          })}
                      <br />
                    </div>
                  );
                })}
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="fullName"
                label="Name Group"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.fullName && props.touched.fullName}
                helperText={<ErrorMessage name="fullName" />}
              />
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                sx={{ marginTop: `0.5rem` }}
                error={Boolean(errorMessage.length > 0 && input.status == "1")}
              >
                <InputLabel>Select status</InputLabel>
                <Select
                  value={input.status}
                  onChange={handleChange}
                  label="Select status"
                  name="status"
                >
                  <MenuItem value="limit">Stop</MenuItem>
                  <MenuItem value="trustSeller">Default</MenuItem>
                  <MenuItem value="Prioritize">Prioritize</MenuItem>
                </Select>
                {errorMessage.length > 0 && input.status == "1" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="teleId"
                label="telegram ID"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.teleId && props.touched.teleId}
                helperText={<ErrorMessage name="teleId" />}
              />
              <label>Current price:</label>
              <Switch
                name="currentPrice"
                color="primary"
                onChange={handleChangeChecked}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                multiline
                minRows="4"
                name="notes"
                label="Notes"
                variant="outlined"
                size="small"
                fullWidth
              />

              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddAgencys;
