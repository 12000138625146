import { Grid, Typography } from "@mui/material";
import React from "react";

const GridsDetailOrders = (props) => {
  const { nameOne, nameTwo, gridmain } = props;
  return (
    <div>
      <Grid
        container
        spacing={1}
        sx={{ margin: gridmain ? "6px 0 0 6px" : "4px 0 " }}
      >
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            variant="h5"
            color="primary"
            sx={{ color: "#3f51b5", fontWeight: 500, fontSize: "1rem" }}
          >
            {nameOne}
          </Typography>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={5}>
          <Typography
            variant="h6"
            sx={{ color: "#263238", fontWeight: 400, fontSize: "1rem" }}
          >
            {nameTwo}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default GridsDetailOrders;
