import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const GridsDetailOrdersTransactions = (props) => {
  const { transactions } = props;
  const number = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div>
      <Grid container spacing={1} sx={{ margin: "4px 0 0 0px" }}>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography
            variant="h5"
            color="primary"
            sx={{ color: "#3f51b5", fontWeight: 500, fontSize: "1rem" }}
          >
            Transactions:
          </Typography>
        </Grid>
        <Grid item lg={10} md={10} sm={10} xs={9}>
          <Table aria-label="a dense table" disableRipple={true}>
            <TableHead>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(0, 1, 1, 0),
                }}
              >
                <Typography>ID</Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(0, 1, 1, 0),
                }}
              >
                <Typography>Code</Typography>
              </TableCell>

              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(0, 1, 1, 0),
                }}
              >
                <Typography>Amount</Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: 0,
                  padding: (theme) => theme.spacing(0, 1, 1, 0),
                }}
              >
                <Typography>Payment</Typography>
              </TableCell>
            </TableHead>
            <TableBody>
              {transactions.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell
                      sx={{
                        border: 0,
                        padding: (theme) => theme.spacing(0, 1, 1, 0),
                      }}
                    >
                      <Typography variant="subtitle1">
                        {item.transactionId}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: 0,
                        padding: (theme) => theme.spacing(0, 1, 1, 0),
                      }}
                    >
                      <Typography variant="subtitle1">{item.code}</Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        border: 0,
                        padding: (theme) => theme.spacing(0, 1, 1, 0),
                      }}
                    >
                      <Typography variant="subtitle1">
                        {number.format(item.amount)} ₫
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        border: 0,
                        padding: (theme) => theme.spacing(0, 1, 1, 0),
                      }}
                    >
                      <Typography variant="subtitle1">{item.owner}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  );
};

export default GridsDetailOrdersTransactions;
