import React from "react";
import Agencys from "./Agencys";

const index = () => {
  return (
    <div>
      <Agencys />
    </div>
  );
};

export default index;
