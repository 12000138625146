import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Box,
  Button,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FilledAlerts from "components/Alert/FilledAlerts";
import axiosusers from "utils/api/axiosusers";
import { Tabtitle } from "components/title/Title";
import { useNavigate } from "react-router-dom";

const AddApiTelegrams = () => {
  Tabtitle("Create api telegram");
  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);
  const [dung, setDung] = React.useState(false);

  const initialValues = {
    fullName: "",
    token: "",
    notes: "",
    idToken: "",
  };
  const [state, setState] = React.useState({
    allowToken: "",
  });
  const handleCheckBox = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(0, "It's too short")
      .required("Name is required"),
    token: Yup.string().min(0, "It's too short").required("Token is required"),
    idToken: Yup.string()
      .min(0, "It's too short")
      .required("id tele is required"),
  });
  const onSubmit = async (values) => {
    setDung(true);

    const dataBudgets = {
      fullName: values.fullName,
      token: values.token,
      allowToken: state.allowToken ? 1 : 0,
      idToken: values.idToken,
      notes: values.notes,
    };

    try {
      const resbudgets = await axiosusers.post(
        `/agencys/create-apitelegrams`,
        dataBudgets
      );

      if (resbudgets.status === 200) {
        console.log(resbudgets);
        setDung(true);

        navigate("/app/order-agencys");
      }
    } catch (error) {
      console.log(error);
      seterrorMessage(error.response.data.message);
      setDung(false);
    }
  };

  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new api telegram
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={dung ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <Field
                sx={{ marginTop: `0.5rem` }}
                as={TextField}
                name="fullName"
                label="Name Bot Api"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.fullName && props.touched.fullName}
                helperText={<ErrorMessage name="fullName" />}
              />

              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="token"
                label="Token"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.token && props.touched.token}
                helperText={<ErrorMessage name="token" />}
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                name="idToken"
                label="Id telegram"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.idToken && props.touched.idToken}
                helperText={<ErrorMessage name="idToken" />}
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                multiline
                minRows="4"
                name="notes"
                label="Notes"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Grid container spacing={0}>
                <Grid item xs={6} sx={{ marginTop: `0.5rem` }}>
                  <label>Alow api:</label>
                  <Switch
                    name="allowToken"
                    color="primary"
                    onChange={handleCheckBox}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Grid>
              </Grid>
              {errorMessage && (
                <FilledAlerts message={errorMessage} success="error" />
              )}

              <Button
                type="submit"
                sx={{ margin: (theme) => theme.spacing(5, 0) }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disabled={dung}
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddApiTelegrams;
