import React from "react";
import BottomNavigations from "./BottomNavigations";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Administrators");
  return (
    <div>
      <BottomNavigations />
    </div>
  );
};

export default index;
