import { Tabtitle } from "components/title/Title";
import React from "react";
import Warhouses from "./Warhouses";

const index = () => {
  Tabtitle("Order Warhouses");
  return (
    <div>
      <Warhouses />
    </div>
  );
};

export default index;
