import React from "react";
import EditClients from "./EditClients";
import { useParams } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Update Clients");
  const { id } = useParams();
  return <div>{id && <EditClients id={id} />}</div>;
};

export default index;
