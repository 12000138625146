import React from "react";
import AddYoutube from "./AddYoutube";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Create Youtube");
  return (
    <div>
      <AddYoutube />
    </div>
  );
};

export default index;
