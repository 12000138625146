import { CircularProgress } from "@mui/material";
import { Tabtitle } from "components/title/Title";
import React from "react";
import useSWR from "swr";
import axiosusers from "utils/api/axiosusers";
import Blocks from "./Blocks";

const index = () => {
  Tabtitle("Blocks");
  const fetchDataSwr = async (url) => {
    const response = await axiosusers.get(url);
    return response.data;
  };

  const {
    data: clientsData,
    error: clientsError,
    isLoading: clientsLoading,
  } = useSWR(`/clients/clients-new`, fetchDataSwr);

  const { data: primaryChannelData } = useSWR(
    `/primarychannel/primarychannel`,
    fetchDataSwr
  );

  const { data: clientGamesData } = useSWR(
    `/clients/clients-games`,
    fetchDataSwr
  );
  return (
    <div>
      {clientsError && <div>failed to load</div>}
      {clientsLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {!clientsLoading && (
        <Blocks
          primarychannel={primaryChannelData}
          clients={clientsData.clientsAll}
          page={clientsData.page}
          clientgames={clientGamesData}
        />
      )}
    </div>
  );
};

export default index;
