import React from "react";
import EditPrimaryChannel from "./EditPrimaryChannel";
import { useParams } from "react-router-dom";
import { Tabtitle } from "components/title/Title";

const index = () => {
  Tabtitle("Update Primary Channel");
  const { id } = useParams();
  return <div>{id && <EditPrimaryChannel id={id} />} </div>;
};

export default index;
