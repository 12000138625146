import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styles from "./style.module.css";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import axiosusers from "utils/api/axiosusers";

import useUserId from "hooks/useUserId";
import { FormAutocomplete } from "hooks/FormAutocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
const AddOrders = (props) => {
  const { clientsApi, gamesApi, blacklistsApi } = props;

  const navigate = useNavigate();
  const [errorMessage, seterrorMessage] = React.useState(false);

  const initialValues = {
    salePrice: "",
    Game: null,
    Client: null,
    information: "Login:\nAccount:\nPass:\nServer:\nCharacter Name:",
    notes: "",
    bonus: "",
  };

  const { userId } = useUserId();
  const [trueD, setTrueD] = React.useState(false);
  const [salePrice, setSalePrice] = useState("");
  const [blacklists, setBlacklists] = useState([]);
  const [fieldValueGame, setFieldValueGame] = useState([]);
  const [fieldValueWallet, setFieldValueWallet] = useState([]);
  const [fieldValueClient, setFieldValueClient] = useState([]);
  const [fieldValueTransaction, setFieldValueTransaction] = useState([]);
  const [jsonResuls, setJsonResuls] = useState([]);
  const [jsonWallets, setJsonWallets] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [avatar, setAvatar] = useState([]);
  const [jsonBudgets, setJsonBudgets] = useState([]);
  const [jsonClients, setJsonClients] = useState([]);
  const [jsonTransactions, setJsonTransactions] = useState([]);

  const [input, setInput] = useState({
    type: "",
    currency: "",
    status: "",
  });
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    let filteredData = gamesApi === undefined ? [] : gamesApi;
    if (input.status === "game") {
      filteredData = gamesApi.filter(
        (transaction) => transaction.status === "game"
      );
    } else if (input.status === "giftCard") {
      filteredData = gamesApi.filter(
        (transaction) => transaction.status === "giftCard"
      );
    } else if (input.status === "softWare") {
      filteredData = gamesApi.filter(
        (transaction) => transaction.status === "softWare"
      );
    } else if (input.status === "account") {
      filteredData = gamesApi.filter(
        (transaction) => transaction.status === "account"
      );
    }

    setJsonResuls(filteredData);
    setJsonClients(clientsApi === undefined ? [] : clientsApi);

    setBlacklists(blacklists);
  }, [input, clientsApi, gamesApi, blacklistsApi]);

  const fetchData = async () => {
    Promise.all([
      axiosusers.get(`/wallets/wallets-orders-gameId/${fieldValueGame?.id}`),
      axiosusers.get(`/budgets/budgets-gameId/${fieldValueGame?.id}`),
    ])
      .then((results) => {
        const [wallets, budgets] = results.map((res) => res.data);

        setJsonWallets(wallets);
        const updatedBudgets = budgets.map((budget) => ({
          ...budget,
          usedQuantity: 0,
        }));
        setJsonBudgets(updatedBudgets);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    if (fieldValueGame?.id !== undefined) {
      fetchData();
    }
  }, [fieldValueGame]);
  const getProductDataclient = async () => {
    const transactionsResponse = await axiosusers.get(
      `/transactions/transactions-clientId/${fieldValueClient.id}`
    );

    setJsonTransactions(transactionsResponse.data);
  };
  const getTripleAApiToken = async () => {
    await axiosusers.get(`/tripleA/api-token`);
  };
  useEffect(() => {
    if (fieldValueClient?.id !== undefined) {
      getProductDataclient();
    }
    getTripleAApiToken();
  }, [fieldValueClient]);
  const validationSchema = Yup.object().shape({
    salePrice: Yup.number()
      .min(0.00000001, "Sale price must be greater than zero")
      .required("Sale price is required"),
    Game: Yup.object().required("Game is required"),
    Client: Yup.object().required("Client is required"),
  });

  let basePrice = 0;
  fieldValueWallet.forEach((item) => {
    item.quantity = item.quantity || 0;
    basePrice += Number(item.quantity) * Number(item.price);
  });

  const handleChangeInputWallet = (id, priceWallet, positive, event) => {
    fieldValueWallet.map((w) => {
      if (id === w.id) {
        w[event.target.name] = event.target.value;
      }
      return w;
    });

    let enterTheQuantity = event.target.value;
    if (enterTheQuantity === "") {
      enterTheQuantity = 0;
    }
    enterTheQuantity = parseFloat(enterTheQuantity);

    if (!isNaN(enterTheQuantity) && positive === false) {
      const filteredRows = jsonBudgets.filter((row) => row.quantity != 0);
      const sortedRows = filteredRows.sort(
        (a, b) => Number(a.price) - Number(b.price)
      );

      const updatedRows = sortedRows.map((item) => {
        if (item.walletId === id) {
          const { quantity, price } = item;

          const remainQuantity =
            (Number(quantity) * Number(price)) / Number(priceWallet);
          let usedQuantity = 0;
          if (enterTheQuantity >= remainQuantity) {
            usedQuantity = Number(quantity);
            enterTheQuantity -= remainQuantity;
          } else if (enterTheQuantity > 0) {
            usedQuantity = Number(
              (enterTheQuantity * priceWallet) / Number(price)
            );
            enterTheQuantity = 0;
          }

          return { ...item, usedQuantity, remainQuantity };
        } else {
          return item;
        }
      });

      setJsonBudgets(updatedRows);
    }

    if (!isNaN(enterTheQuantity) && positive === true) {
      const maxPrice = jsonBudgets.reduce(
        (min, row) => {
          if (row.walletId === id) {
            const price = parseInt(row.price);

            return price < min.price ? { price, id: row.id } : min;
          }
          return min;
        },
        { price: Infinity, id: null }
      );

      const updatedRows = jsonBudgets.map((item) => {
        if (item.id == maxPrice.id) {
          item.usedQuantity =
            (Number(enterTheQuantity) * Number(priceWallet)) /
            Number(item.price);
        }
        return item;
      });
      setJsonBudgets(updatedRows);
    }
  };

  const handleChangeInputBudget = (id, event) => {
    const jsonBudget = jsonBudgets.map((w) => {
      if (id === w.id) {
        w[event.target.name] = event.target.value;
        w.usedQuantity = event.target.value;
      }
      return w;
    });
    setJsonBudgets(jsonBudget);
  };

  if (!fieldValueGame) {
    setTimeout(function () {
      setFieldValueWallet([]);
    }, 2);
  }

  const setChangeWallet = async (value) => {
    value.map((item) => {
      if (item.quantity === null) {
        item.usedQuantity = 0;
      }
    });

    const updatedRows = jsonBudgets.map((item) => {
      const foundItem = value.find((v) => v.id === item.walletId);
      if (!foundItem) {
        item.usedQuantity = 0;
      }
      return item;
    });
    setJsonBudgets(updatedRows);
  };

  const setChangeTransaction = async (values) => {
    const sortedValues = values.sort((a, b) => a.remainAmount - b.remainAmount);
    let remainQuantity = 0;
    let updatedRows = sortedValues.map((item, index) => {
      let usedAmount = 0;
      if (index === 0) {
        // id1: usedAmount=salePrice-item.remainAmount
        usedAmount = salePrice - item.remainAmount;
        if (usedAmount > 0) {
          usedAmount = item.remainAmount;
          remainQuantity = salePrice - item.remainAmount;
        } else {
          usedAmount = salePrice;
          remainQuantity = 0;
        }
      } else {
        // id2 & id3: usedAmount=usedAmount(cũ)-item.remainAmount
        if (remainQuantity >= item.remainAmount) {
          usedAmount = item.remainAmount;
          remainQuantity = remainQuantity - item.remainAmount;
        } else if (remainQuantity < item.remainAmount && remainQuantity > 0) {
          usedAmount = remainQuantity;
          remainQuantity = 0;
        } else {
          usedAmount = 0;
          remainQuantity = 0;
        }
      }

      return { ...item, usedAmount };
    });
    setFieldValueTransaction(updatedRows);
  };

  const ChangeSalePrice = async (values) => {
    const salePrice = values;
    if (salePrice !== "") {
      const sortedValues = fieldValueTransaction.sort(
        (a, b) => a.remainAmount - b.remainAmount
      );
      let remainQuantity = 0;
      let updatedRows = sortedValues.map((item, index) => {
        let usedAmount = 0;
        if (index === 0) {
          // id1: usedAmount=salePrice-item.remainAmount
          usedAmount = salePrice - item.remainAmount;
          if (usedAmount > 0) {
            usedAmount = item.remainAmount;
            remainQuantity = salePrice - item.remainAmount;
          } else {
            usedAmount = salePrice;
            remainQuantity = 0;
          }
        } else {
          // id2 & id3: usedAmount=usedAmount(cũ)-item.remainAmount
          if (remainQuantity >= item.remainAmount) {
            usedAmount = item.remainAmount;
            remainQuantity = remainQuantity - item.remainAmount;
          } else if (remainQuantity < item.remainAmount && remainQuantity > 0) {
            usedAmount = remainQuantity;
            remainQuantity = 0;
          } else {
            usedAmount = 0;
            remainQuantity = 0;
          }
        }

        return { ...item, usedAmount };
      });
      setFieldValueTransaction(updatedRows);
    }
    if (salePrice == "") {
      setFieldValueTransaction([]);
    }
    setSalePrice(values);
  };

  const viewAvatar = (e) => {
    const filesArray = Array.from(e.target.files).map((file) =>
      URL.createObjectURL(file)
    );
    setSelectedFiles((prevImages) => prevImages.concat(filesArray));
    Array.from(e.target.files).map(
      (file) => URL.revokeObjectURL(file) // avoid memory leak
    );

    // Di chuyển hàm generateRandomString ra khỏi vòng lặp và đặt ở mức cao hơn trong phạm vi của function hoặc module
    function generateRandomString(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let randomString = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
      }
      return randomString;
    }

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const random12Chars = generateRandomString(12);
      setAvatar((prevState) => [...prevState, { id: random12Chars, file }]);
    }
  };
  const handleRemovePhoto = (photo, index) => {
    const updatedAvatar = [...avatar];
    updatedAvatar.splice(index, 1);
    setAvatar(updatedAvatar);
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const renderPhotos = (photos) => {
    return photos.map((photo, index) => (
      <div key={photo} style={{ position: "relative", display: "flex" }}>
        <img src={photo} alt="" className={styles.photoimg} />
        <IconButton
          color="primary"
          onClick={() => handleRemovePhoto(photo, index)} // Truyền ID và index vào hàm handleRemovePhoto
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    ));
  };

  let totalUsedAmount = 0;
  let totalRemainAmount = 0;

  fieldValueTransaction.map((item) => {
    totalUsedAmount += Number(item.usedAmount);
    totalRemainAmount += Number(item.remainAmount);
  });
  console.log(fieldValueWallet);
  const onSubmit = async (values) => {
    setTrueD(true);
    let totalBudget = 0;
    fieldValueWallet.map((itemA) => {
      jsonBudgets.map((item) => {
        if (itemA.id == item.walletId && itemA.positive == 1) {
          totalBudget += Number(item.usedQuantity) * Number(item.price);
        }
        if (itemA.id == item.walletId && itemA.positive == 0) {
          totalBudget += Number(item.usedQuantity) * Number(item.price);
        }
      });
    });

    let errorMessage = "";
    fieldValueWallet.map((item) => {
      if (item.quantity === "0" || item.quantity === 0) {
        errorMessage =
          "The selected Budgets are not map with the base price of products, please review again!!!.";
      }
    });

    if (salePrice < basePrice) {
      errorMessage =
        "Please review the Sale Price, it is less than the total wallet packages you just entered.";
    } else if (Math.round(basePrice) !== Math.round(totalBudget)) {
      errorMessage =
        "The selected budgets do not match the base price of products, please review again!!.";
    } else if (totalBudget === 0) {
      errorMessage = "The budget quantity is required.";
    }

    const isCaptionInSaiStatus = blacklists.some((item) =>
      values.information.includes(item.name)
    );
    if (isCaptionInSaiStatus) {
      errorMessage = "This account is on the blacklist.";
    }
    avatar.map((item) => {
      if (item.file.size > 2 * 1024 * 1024) {
        errorMessage = "size must be less than 2MB";
      }
    });
    if (errorMessage !== "") {
      setTrueD(false);
      return seterrorMessage(errorMessage);
    }

    let paymentStatus = "";
    if (totalRemainAmount == 0) {
      paymentStatus = "unPaid";
    } else if (salePrice <= totalRemainAmount) {
      paymentStatus = "paid";
    } else {
      paymentStatus = "partPaid";
    }
    const dataBudgets = {
      clientId: fieldValueClient.id,
      gameId: fieldValueGame.id,
      userId: userId?.id,
      agencyId: 0,
      basePrice,
      salePrice,
      paidAmount: totalUsedAmount,
      bonusAmount: Number(values.bonus),
      paymentStatus: paymentStatus,
      status: "inProgress",
      info: values.information,
      notes: values.notes,
      infoapi: "",
      apitele: 1,
      jsonBudgets: jsonBudgets.filter((row) => row.usedQuantity != 0),
      fieldValueWallet,
      fieldValueTransaction,
      avatar,
    };

    try {
      const resbudgets = await axiosusers.post(
        `/order/create-orders`,
        dataBudgets
      );

      if (resbudgets.status === 200) {
        console.log(resbudgets);
        navigate("/app/orders", { replace: true });

        let namePhoto = "";

        avatar
          .sort((a, b) => a.size - b.size)
          .map(async (image) => {
            const formData = new FormData();
            formData.append("file", image.file);
            formData.append("orderId", resbudgets.data.orderId);
            try {
              const response = await axiosusers.post(
                `/uploads/ordersphotos`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              if (response.status === 200) {
                namePhoto += `\nhttps://d1wbec5n.website/img/${response.data.image}`;
              }
            } catch (error) {
              console.log(error);
            }
            if (namePhoto !== "") {
              namePhoto = `\nPhotos: ${namePhoto}`;
            }
          });

        setTimeout(
          async function () {
            console.log(namePhoto);
            const dataMessages = {
              message: `${resbudgets.data.text}${namePhoto}`,
            };
            await axiosusers.post(`/telegrams/api-orders`, dataMessages);
          },
          avatar.length > 0 ? 3300 : 1300
        );
        if (resbudgets.data.AgencyId !== 0) {
          setTimeout(
            async function () {
              const dataMessage = {
                message:
                  fieldValueGame.id === 1000
                    ? `${resbudgets.data.numberOrder}. ${resbudgets.data.infoPost}${namePhoto}`
                    : `${resbudgets.data.numberOrder}. ${resbudgets.data.text}${namePhoto}`,
                agencyId: resbudgets.data.AgencyId,
              };
              await axiosusers.post(
                `/telegrams/api-orders-agencyId`,
                dataMessage
              );
            },
            avatar.length > 0 ? 3400 : 1400
          );
        }
      }
    } catch (error) {
      console.log(error);
      setTrueD(false);
      return seterrorMessage(error?.response?.data?.message);
    }
  };

  const number = new Intl.NumberFormat("en-US");

  return (
    <div>
      <Box sx={{ maxWidth: 600, margin: `auto` }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "30px",
            margin: (theme) => theme.spacing(0, 0, 3, 0),
          }}
        >
          Create new orders
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={trueD ? null : onSubmit}
        >
          {(props) => (
            <Form autoComplete="off">
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                style={{ marginTop: `0.5rem` }}
                error={Boolean(errorMessage.length > 0 && input.status == "1")}
              >
                <InputLabel>Product Select</InputLabel>
                <Select
                  value={input.status}
                  onChange={handleChange}
                  label="Product Select"
                  name="status"
                >
                  <MenuItem value="game">Games-Top up</MenuItem>
                  <MenuItem value="giftCard">Gift Card</MenuItem>
                  <MenuItem value="softWare">SoftWare</MenuItem>
                  <MenuItem value="account">Games Account</MenuItem>
                </Select>
                {errorMessage.length > 0 && input.status == "1" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>
              <FormAutocomplete
                onChangeSelect={(value) => setFieldValueGame(value)}
                style={{ marginTop: `0.5rem` }}
                label={
                  input.status
                    ? input.status
                        .replace(/([A-Z])/g, " $1")
                        .toLowerCase()
                        .replace(/^./, function (str) {
                          return str.toUpperCase();
                        })
                    : "Games-Top up"
                }
                name="Game"
                options={jsonResuls}
              />
              {fieldValueGame && fieldValueGame.length != 0 && (
                <Autocomplete
                  multiple
                  style={{ marginTop: `0.5rem` }}
                  onChange={(event, values) => {
                    setFieldValueWallet(values);
                    setChangeWallet(values);
                  }}
                  id="tags-outlined"
                  options={jsonWallets}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Wallet"
                      variant="outlined"
                      size="small"
                      placeholder="Select one or multiple"
                    />
                  )}
                />
              )}
              <Box style={{ marginTop: `0.5rem` }} />
              {fieldValueGame?.id !== undefined &&
                fieldValueWallet.map((item) => {
                  return (
                    <div key={item.id}>
                      <div className={styles.budget} />
                      <div className={styles.budget} />
                      <div className={styles.budget}>
                        <Typography className={styles.nameW}>
                          Wallet name: {item.name}
                        </Typography>
                        <TextField
                          onChange={(event) =>
                            handleChangeInputWallet(
                              item.id,
                              item.price,
                              item.positive,

                              event
                            )
                          }
                          type="text"
                          name="quantity"
                          label="Quantity"
                          placeholder="Quantity"
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </div>

                      <div className={styles.budget2}>
                        <div className={styles.column}>
                          <Typography>Budget name</Typography>
                        </div>
                        <div className={styles.columnQuantity}>
                          <Typography>Remain quantity</Typography>
                        </div>
                        <div className={styles.column}>
                          <Typography>Used Quantity</Typography>
                        </div>
                      </div>
                      {fieldValueGame?.id !== undefined &&
                        jsonBudgets
                          .sort((a, b) => a.orderNumber - b.orderNumber)
                          .map((budget) => {
                            if (item.id === budget.walletId) {
                              return (
                                <div className={styles.budget} key={budget.id}>
                                  <div className={styles.column}>
                                    <Typography className={styles.nameW2}>
                                      {budget.name}
                                    </Typography>
                                  </div>
                                  <div className={styles.columnQuantity}>
                                    <Typography className={styles.nameW2}>
                                      {budget.quantity}
                                    </Typography>
                                  </div>
                                  <div className={styles.column}>
                                    <TextField
                                      value={budget.usedQuantity}
                                      onChange={(event) =>
                                        handleChangeInputBudget(
                                          budget.id,
                                          event
                                        )
                                      }
                                      type="number"
                                      name="usedQuantity"
                                      label="Used quantity"
                                      variant="outlined"
                                      size="small"
                                    />
                                  </div>
                                </div>
                              );
                            }
                          })}
                    </div>
                  );
                })}
              {fieldValueGame &&
                fieldValueGame.length != 0 &&
                fieldValueWallet.length > 0 &&
                userId.role == "Admin" && (
                  <>
                    <Typography>
                      Base price:{" "}
                      {basePrice == "0"
                        ? "0.00"
                        : Math.round(basePrice * 100) / 100}
                    </Typography>
                    <br />
                    <div style={{ marginBottom: "10px" }} />
                  </>
                )}

              <Field
                as={TextField}
                type="text"
                value={props.values.salePrice}
                onChange={(e) => {
                  ChangeSalePrice(e.target.value);
                  props.setFieldValue("salePrice", e.target.value);
                }}
                name="salePrice"
                label="Sale Price"
                variant="outlined"
                size="small"
                fullWidth
                error={props.errors.salePrice && props.touched.salePrice}
                helperText={<ErrorMessage name="salePrice" />}
              />
              {salePrice !== "" && (
                <Typography variant="subtitle1" sx={{ marginLeft: "10px" }}>
                  {number.format(salePrice) + ` ₫`}
                </Typography>
              )}
              <FormAutocomplete
                getOptionLabel={(option) => option.fullName}
                onChangeSelect={(value) => setFieldValueClient(value)}
                className={styles.fontText}
                label="Client"
                name="Client"
                options={jsonClients}
              />
              {salePrice &&
                fieldValueClient &&
                fieldValueClient.length != 0 && (
                  <FormAutocomplete
                    placeholder="Select one or multiple"
                    onChange={(event, values) => {
                      setFieldValueTransaction(values);
                      setChangeTransaction(values);
                    }}
                    getOptionLabel={(option) => option.code}
                    multiple={true}
                    filterSelectedOptions={true}
                    className={styles.fontText}
                    name="Transaction"
                    label="Transaction"
                    options={jsonTransactions.sort(
                      (a, b) => a.remainAmount - b.remainAmount
                    )}
                  />
                )}
              {salePrice && fieldValueTransaction.length > 0 && (
                <div className={styles.budget}>
                  <div className={styles.column}>
                    <Typography>Transactions</Typography>
                  </div>
                  <div className={styles.column}>
                    <Typography>Remain amount</Typography>
                  </div>
                  <div className={styles.column}>
                    <Typography>Used amount</Typography>
                  </div>
                </div>
              )}
              {salePrice &&
                fieldValueTransaction.map((item) => {
                  return (
                    <div className={styles.budget} key={item.id}>
                      <div className={styles.column}>
                        <Typography>{item.code}</Typography>
                      </div>
                      <div className={styles.column}>
                        <Typography>
                          {number.format(item.remainAmount)} ₫
                        </Typography>
                      </div>
                      <div className={styles.column}>
                        <Typography>
                          {number.format(item.usedAmount)} ₫
                        </Typography>
                      </div>
                    </div>
                  );
                })}
              {salePrice && fieldValueTransaction.length > 0 && (
                <Typography className={styles.nameWText}>
                  Pair Amount: {number.format(totalUsedAmount)} ₫
                </Typography>
              )}
              <Field
                as={TextField}
                type="number"
                name="bonus"
                label="Bonus"
                variant="outlined"
                size="small"
                fullWidth
              />
              <Field
                as={TextField}
                sx={{ marginTop: `0.5rem` }}
                value={props.values.information}
                multiline
                minRows="5"
                name="information"
                label="Information"
                variant="outlined"
                size="small"
                fullWidth
                style={{ marginTop: `0.5rem` }}
              />
              <Field
                as={TextField}
                style={{ marginTop: `0.5rem` }}
                multiline
                minRows="4"
                name="notes"
                label="Notes"
                variant="outlined"
                size="small"
                fullWidth
              />
              <input
                style={{ marginTop: `0.5rem` }}
                accept="image/*"
                className={styles.input}
                onChange={viewAvatar}
                id="file"
                name="img"
                multiple
                type="file"
              />
              <label htmlFor="file" style={{ marginTop: `0.5rem` }}>
                <Button
                  component="span"
                  style={{ marginTop: `0.5rem` }}
                  color="primary"
                >
                  UPLOAD PHOTOS
                </Button>
              </label>
              <div className="result" style={{ display: "flex" }}>
                {renderPhotos(selectedFiles)}
              </div>

              {errorMessage.length > 0 && (
                <Alert severity="error" className={styles.errortext}>
                  {errorMessage}
                </Alert>
              )}
              {trueD && (
                <div className={styles.center}>
                  <CircularProgress />
                </div>
              )}
              <Button
                type="submit"
                disabled={trueD}
                sx={{ marginTop: `3rem` }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
              >
                Create
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default AddOrders;
